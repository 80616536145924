import './tech-feed-list.scss';
import { Box, Link, List, ListItem } from '@material-ui/core';
import affectsBodyImg from '../../../../assets/img/affects-body.jpg';
import videoCallsImg from '../../../../assets/img/video-calls.jpg';
import hearablesImg from '../../../../assets/img/hearables.jpg';

function TechFeedImage(props: any) {

    const photo = props.image;

    if ( photo ) {
        return (
            <Box className="image-box">
                <img className="venue-photo" src={photo} />
            </Box>
        );
    }

    return (
        <Box className="image-box" />
    );
}


function TechFeedList(props: any) {

    const feedList = [
        {
            _id: 1,
            title: "Curious about the ear tech of the future?",
            link: {
                name: "Tech news",
                url: "https://mumbli.com/hearables-the-wearables-of-the-future/"
            },
            image: hearablesImg
        },
        {
            _id: 2,
            title: "How to improve the quality of your video calls",
            link: {
                name: "Tips for you",
                url: "https://mumbli.com/video-calls-stopping-noises-affecting-work/"
            },
            image: videoCallsImg
        },
        {
            _id: 3,
            title: "5 ways that sound affects the human body",
            link: {
                name: "Tips for you",
                url: "https://mumbli.com/5-ways-that-sound-affects-the-human-body/"
            },
            image: affectsBodyImg
        }
    ];

    const listItems = feedList.map((item: any) => {
        return (
            <ListItem className="feed-list-item content-card">
                <a href={item.link.url}>
                    <Box className="feed-description">
                        <h3>{item.title}</h3>
                        <Link className="pure-link">
                            {item.link.name}
                        </Link>
                    </Box>

                    <TechFeedImage image={item.image} />
                </a>
            </ListItem>
        );
    });

    return (
        <List className="tech-feed-list">
            {listItems}
        </List>
    );
}

export default TechFeedList;