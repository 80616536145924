
import { QuizInterface } from "../../models/quiz.model";
import FeathersService from "./feathers.service";

export class RestQuizService {

    private quizRestService = FeathersService.service('quiz');

    constructor() {}

    find(): Promise<QuizInterface[]> {
        return this.quizRestService.find({}).then((res: any) => {
            return res;
        }).catch((err: any) => {
            console.log(err);
        });
    }
}
