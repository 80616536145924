import React, { useEffect } from 'react';
import * as feathersConfig from "../../feathers.config.json";
import { successLogin, updateProfileAsk } from '../const/const';
import swal from "sweetalert";

const feathers = require('@feathersjs/feathers');
const socketio = require('@feathersjs/socketio-client');
const io = require('socket.io-client');
const auth = require('@feathersjs/authentication-client');
var CryptoJS = require("crypto-js");
const socket = io(feathersConfig.ioUrl);
const FeathersService = feathers();

FeathersService.configure(socketio(socket));

FeathersService.configure(auth({
  storageKey: 'auth'
}));

const googleLoginCallback = (props: any) => {

  FeathersService.reAuthenticate().then((res: any) => {
    localStorage.removeItem('auth')
    let gAuth = res.accessToken;
    const secret: any = process.env.REACT_APP_SECRET_KEY;
    var newgAuth = CryptoJS.AES.encrypt(JSON.stringify(gAuth), secret).toString();
    var googleAuth = JSON.parse(CryptoJS.AES.decrypt(newgAuth, secret).toString(CryptoJS.enc.Utf8));
    localStorage.setItem("newAuth", newgAuth)
    localStorage.setItem("permissions", res.user.permissions[0])
    localStorage.setItem("userId", res.user._id)
    if (res.user.isProfileComplete !== true) {
      props.history.push('/auth/update-profile');
    }
    else {
      props.history.push('/profile')
    }
  }).catch((res: any) => {
    swal(res?.data?.message)
  });

  return (
    <div>
    </div>
  )
};

export default googleLoginCallback;