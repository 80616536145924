import { 
    Box, 
    Button,
    Slider
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './profile-infographic.scss';
import chartImg from '../../../../assets/img/chart.svg';
import { UserContext } from '../../../../context/user.context';
import { UserProfileDataService } from '../../../../services/user-profile-data.service';
import { UserProfileDataInterface, QuizValues } from '../../../../models/user-profile-data.model';

function SensitiveMeaningText(props: any) {

    const wellnesssValue: number = props.wellnessValue;
    
    if ( wellnesssValue === 1 ) {
        return (
            <p>As far as sound is concerned, it takes a lot to get you flustered. You’re not bothered by any specific sounds and couldn't get more zen even when the background noise around you is it max volume.</p>
        )
    }

    if ( wellnesssValue === 2 ) {
        return (
            <p>There are some sounds that you may notice now and again, or moments where you're aware that there's quite a lot of background noise around you. But overall, none of these are enough to shake your inner peace.</p>
        )
    }

    if ( wellnesssValue === 3 ) {
        return (
            <p>There are some sounds that you find quite annoying and you may feel a bit frustrated when the background noise affects your conversations or thinking. But overall, they're more of an annoyance than an actual pain.</p>
        )
    }

    if ( wellnesssValue === 4 ) {
        return (
            <p>As far as sound is concerned, you can be easily flustered. There's specific sounds that drive you up the wall, and you prefer that background noise doesn't go above a certain volume or else it gets to be a bit too much.</p>
        )
    }

    return null;
}

function SensitivityLevelText(props: any) {

    const wellnesssValue: number = props.wellnessValue;
    
    if ( wellnesssValue === 1 ) {
        return (
            <p>High</p>
        )
    }

    if ( wellnesssValue === 2 ) {
        return (
            <p>Moderate</p>
        )
    }

    if ( wellnesssValue === 3 ) {
        return (
            <p>Low</p>
        )
    }

    if ( wellnesssValue === 4 ) {
        return (
            <p>Not tolerant</p>
        )
    }

    return null;
}

function ProfileInfographic() {

    const [userData, setUserData] = React.useContext(UserContext);
    const userProfileDataService = new UserProfileDataService();
    const [userProfileData, setUserProfileData] = useState<UserProfileDataInterface>();
    const [sliderValue, setSliderValue] = useState<number[]>([75, 100]);
    const [wellnessValue, setWellnessValue] = useState<number>(0);

    useEffect(() => {
        if ( !userProfileData && userData ) {
            userProfileDataService.getUserProfileData(userData._id).then((userProfile: UserProfileDataInterface) => {

                setUserProfileData(userProfile);
                if ( userProfile.value ) {
                    // const wellness: QuizValues | undefined = userProfile.quiz.find((item: any) => item.type === 'wellness');
                    const wellness: QuizValues | undefined = userProfile.value.find((item: any) => item.type === 'wellness');
                    
                    if ( wellness ) {
                        // setWellnessValue(wellness.value);
                        switch(wellness.value) {
                            case 4:
                                setSliderValue([0, 25]);
                            break;
                            case 3:
                                setSliderValue([25, 50]);
                            break;
                            case 2:
                                setSliderValue([50, 75]);
                            break;
                            case 1:
                                setSliderValue([75, 100]);
                            break;
                        }
                    }
                }
            }).catch((err: any) => {
                console.log(err);
            });
        }
    });

    useMemo(() => {
        if ( userProfileData && userProfileData.value ) {

            const wellness: QuizValues | undefined = userProfileData.value.find((item: any) => item.type === 'wellness');
            console.log(wellness);
            if ( wellness ) {
                setWellnessValue(wellness.value);
            }
        }
    }, [userProfileData]);

    return(
        <Box className="profile-preferences">
            <h1>
                <NavLink to="/profile" className="icon-right-arrow"></NavLink>
                My Hearing Preferences
            </h1>
            <p>This is an initial summary of where you lie on the continuum of tolerance to background noise.</p>

            <h3>About your tolerance type</h3>
            <SensitiveMeaningText wellnessValue={wellnessValue} />

            <h3>Your Hearing Tolerance type is:</h3>
            <SensitivityLevelText wellnessValue={wellnessValue} />

            <Box className="preferences-slider">
                <Slider value={sliderValue} aria-labelledby="continuous-slider" />
                <div className="slider-label">
                    <span>Not tolerant</span>
                    <span>High tolerance</span>
                </div>
            </Box>

            <h3>See how others of the Mumbli Community scored</h3>

            <img src={chartImg} />

            <p>Click the button below to read more about the science behind the quiz & how it works.</p>

            <a href="https://mumbli.com/how-sensitive-are-you-to-sound/" className="btn-link">
                <Button className="btn-gradient">Read the science</Button>
            </a>
        </Box>
    )
}

export default ProfileInfographic;