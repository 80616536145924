
import { PlaceInterface } from "../models/place.model";
import { RestPlaceService } from "./rest/rest-place.service";

export class PlaceService {

    private restPlaceService = new RestPlaceService();

    constructor() {}

    getUser( userId: string ) {

    }

    getPlaceList(): Promise<PlaceInterface[]> {
        return this.restPlaceService.find();
    }

    createVenue( venueData: PlaceInterface ): Promise<PlaceInterface> {
        return this.restPlaceService.create(venueData);
    }
}
