import './venue-preferences.scss';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { 
    Box, 
    Button
} from '@material-ui/core';
import { UserContext } from '../../../../context/user.context';
import { UserPreferenceInterface } from '../../../../models/user.model';
import { UserService } from '../../../../services/user.service';
import { SnackbarContext } from '../../../../context/snackbar.context';

interface PreferenceType {
    [key: string]: string | null
};

function VenuesPreferences() {

    const userService = new UserService();

    const [userData, setUserData] = React.useContext(UserContext);
    const [snackbarData, setSnackbarData] = React.useContext(SnackbarContext);

    const initPreferences: PreferenceType = {
        work: null,
        meeting: null,
        socializing: null
    };

    const [userPreferences, setUserPreferences] = useState(initPreferences);

    const initUserPreferences = () => {
        const preferences: any = [];
        userData.preferences.forEach((preference: UserPreferenceInterface) => {
            preferences[preference.name] = preference.value;
        })

        setUserPreferences(preferences);
    }

    const updateUserData = () => {
    
        const userUpdatedData = {...userData};

        for( const [preferenceName, preferenceValue] of Object.entries(userPreferences) ) {
            const userPreference = userUpdatedData.preferences.find((item: UserPreferenceInterface) => item.name === preferenceName);
            userPreference.value = preferenceValue;
        }

        userService.updateUser(userUpdatedData).then((res: any) => {
            
            if ( res.type === 'FeathersError' ) {

                const snackbar = {
                    isOpen: true,
                    snackbarType: 'error',
                    snackbarText: res.message ?? 'Server error. Please try later!'
                };

                setSnackbarData(snackbar);
            } else {

                const snackbar = {
                    isOpen: true,
                    snackbarType: 'success',
                    snackbarText: 'Profile data was updated.'
                };

                setSnackbarData(snackbar);

                setUserData(res);
            }
        }).catch((err: any) => {
            console.log(err);
        });
        
        initUserPreferences();
    }

    const setPreference = (type: string, val: string): void => {
        const newPreferences: PreferenceType = {};
        for( const [preferenceName, preferenceValue] of Object.entries(userPreferences) ) {
            newPreferences[preferenceName] = preferenceName === type ? val : preferenceValue;
        }

        setUserPreferences(newPreferences);
    } 

    useEffect(() => {
        if ( userData?.preferences && userPreferences.work === null) {
            initUserPreferences();
        }
    });

    return (
        <Box className="content-card">
            <h1>My Atmosphere preferences</h1>
            <p>Click the edit button to select the atmospheres you prefer for each occasion listed below.</p>

            <h3>Some atmosphere definitions:</h3>
            <p>
                <b>Calm:</b> soft music & chatter, feels cosy, warm<br />
                <b>Buzzy:</b> lots of chatter, laughter, upbeat music<br />
                <b>Energetic:</b> booming music, dancing people<br />
            </p>

            <div className="content-block">
                <h3>For work</h3>
                <div className="action-block">
                    <Button variant="outlined" className={`${userPreferences?.work === "calm" ? "active" : ""}`} onClick={() => setPreference('work', 'calm')}>Calm</Button>
                    <Button variant="outlined" className={`${userPreferences?.work === "buzzy" ? "active" : ""}`} onClick={() => setPreference('work', 'buzzy')}>Buzzy</Button>
                    <Button variant="outlined" className={`${userPreferences?.work === "energetic" ? "active" : ""}`} onClick={() => setPreference('work', 'energetic')}>Energetic</Button>
                </div>
            </div>

            <div className="content-block">
                <h3>For meetings</h3>
                <div className="action-block">
                    <Button variant="outlined" className={`${userPreferences?.meeting === "calm" ? "active" : ""}`} onClick={() => setPreference('meeting', 'calm')}>Calm</Button>
                    <Button variant="outlined" className={`${userPreferences?.meeting === "buzzy" ? "active" : ""}`} onClick={() => setPreference('meeting', 'buzzy')}>Buzzy</Button>
                    <Button variant="outlined" className={`${userPreferences?.meeting === "energetic" ? "active" : ""}`} onClick={() => setPreference('meeting', 'energetic')}>Energetic</Button>
                </div>
            </div>

            <div className="content-block">
                <h3>For socializing</h3>
                <div className="action-block">
                    <Button variant="outlined" className={`${userPreferences.socializing === "calm" ? "active" : ""}`} onClick={() => setPreference('socializing', 'calm')}>Calm</Button>
                    <Button variant="outlined" className={`${userPreferences.socializing === "buzzy" ? "active" : ""}`} onClick={() => setPreference('socializing', 'buzzy')}>Buzzy</Button>
                    <Button variant="outlined" className={`${userPreferences.socializing === "energetic" ? "active" : ""}`} onClick={() => setPreference('socializing', 'energetic')}>Energetic</Button>
                </div>
            </div>

            <NavLink to="/venues">
                <Button className="btn-gradient">Save</Button>
            </NavLink>
        </Box>
    );

}

export default VenuesPreferences;