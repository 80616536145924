import { FormControl, FormHelperText, InputLabel, TextField, Button, Grid, Box, Container, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { signupSchema1 } from '../../../../models/signup.schema';
import GoogleLogin from 'react-google-login';
import { FcGoogle } from 'react-icons/fc';
import './signup.scss';

function SignupComponent(props: any) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { register, errors, handleSubmit, getValues } = useForm({
        resolver: yupResolver(signupSchema1)
    });

    const onSubmit = () => {
        localStorage.setItem("firstName", getValues("firstName"));
        localStorage.setItem("lastName", getValues("lastName"));
        localStorage.setItem("email", getValues("email"));
        localStorage.setItem("password", getValues("password"));
        props.history.push(`/auth/verifysignup`);
    };

    return (
        <>
            <Box p={3} className="auth-form-header">
                <span className="icon-mumbli-logo-2"></span>
                <Typography variant="h2" component="div">Sign Up</Typography>
            </Box>
            <Container maxWidth="xs" className="signup-content">
                <Box py={4}>
                    <Grid>
                        <form className="signup-form" onSubmit={handleSubmit(onSubmit)} >
                            <Box mb={4}>
                                <Typography variant="h4" component="h1" className="font-weight-700" color="primary">Create your Mumbli account.</Typography>
                            </Box>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="firstname">First Name</InputLabel>
                                        <TextField variant="outlined" id="firstname" aria-describedby="firstname-helper-text" name="firstName" inputRef={register} onChange={(data) => setFirstName(data.target.value)} />
                                        <FormHelperText id="firstname-helper-text"></FormHelperText>
                                        <p>{errors.firstName?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="lastname">Last Name</InputLabel>
                                        <TextField variant="outlined" id="lastName" aria-describedby="lastName-helper-text" name="lastName" inputRef={register} onChange={(data) => setlastName(data.target.value)} />
                                        <FormHelperText id="lastName-helper-text"></FormHelperText>
                                        <p>{errors.lastName?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="email">Email</InputLabel>
                                        <TextField variant="outlined" id="email" aria-describedby="email-helper-text" name="email" inputRef={register} onChange={(data) => setEmail(data.target.value)} />
                                        <FormHelperText id="email-helper-text"></FormHelperText>
                                        <p>{errors.email?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <TextField variant="outlined" id="password" aria-describedby="password-helper-text" type="password" name="password" inputRef={register} onChange={(data) => setPassword(data.target.value)} />
                                        <FormHelperText id="password-helper-text"></FormHelperText>
                                        <p>{errors.password?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                                        <TextField variant="outlined" id="confirm-password" aria-describedby="confirm-password-helper-text" type="password" name="confirmPassword" inputRef={register} />
                                        <FormHelperText id="confirm-password-helper-text"></FormHelperText>
                                        <p>{errors.confirmPassword?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <Button type="submit" variant="contained" className="btn-root rounded-pill btn-gradient w-100 text-white text-capitalize" size="large" disableElevation>Continue</Button>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <a href={process.env.REACT_APP_API_URL + "/oauth/google"}>
                                        <Button startIcon={<FcGoogle />} type="button" variant="outlined" color="primary" className="btn-root rounded-pill w-100 text-capitalize" size="large" disableElevation>Sign in with Google</Button>
                                    </a>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Link to="/auth/login" className="pure-link">
                                    Have an account? Login
                                </Link>
                            </Grid>
                        </form>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}

export default SignupComponent;