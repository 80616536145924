import React from "react"
import RawHTMLRenderer from "../../utils/RawHTMLRenderer"    


function CookiePolicy(){

    const rawHTML = `<!DOCTYPE html>
    <html>
    
    <head>
        <meta charset="UTF-8">
        <title>Cookie Policy (UK)</title>
        <style>
        /* CSS for top header spacing */
        .top-header-spacing {
            padding-top: 183px;
        }

        @media screen and (max-width: 1024px) {
            .top-header-spacing {
                padding-top: 164px;
            }
        }
    </style>
    </head>
    
    <body>
        <div style="margin-inline: 2rem;" class="top-header-spacing">
        <h1 style="font-size: 3rem;margin-top:2rem">Cookie Policy(UK)</h1>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;" style="font-size: 1.6rem; margin-top: 0.8rem;">This Cookie Policy was last updated on February 7, 2022 and applies to citizens and legal permanent residents of
            the United Kingdom.</p>
    
        <h2 style="font-size: 2rem; margin-top: 2.5rem">1. Introduction</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">Our website, <a href="https://mumbli.com">https://mumbli.com</a> (hereinafter: "the website") uses cookies and
            other related technologies (for convenience all technologies are referred to as "cookies"). Cookies are also
            placed by third parties we have engaged. In the document below we inform you about the use of cookies on our
            website.</p>
    
        <h2 style="font-size: 2rem; margin-top: 2.5rem">2. What are cookies?</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">A cookie is a small simple file that is sent along with pages of this website and stored by your browser on the
            hard drive of your computer or another device. The information stored therein may be returned to our servers or to
            the servers of the relevant third parties during a subsequent visit.</p>
    
        <h2 style="font-size: 2rem; margin-top: 2.5rem">3. What are scripts?</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">A script is a piece of programme code that is used to make our website function properly and interactively. This
            code is executed on our server or on your device.</p>
    
        <h2 style="font-size: 2rem; margin-top: 2.5rem">4. What is a web beacon?</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">A web beacon (or a pixel tag) is a small, invisible piece of text or image on a website that is used to monitor
            traffic on a website. In order to do this, various data about you is stored using web beacons.</p>
    
        <h2 style="font-size: 2rem; margin-top: 2.5rem">5. Cookies</h2>
        <h3 style="font-size: 1.8rem; margin-top: 1.5rem;">5.1 Technical or functional cookies</h3>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">Some cookies ensure that certain parts of the website work properly and that your user preferences remain known. By
            placing functional cookies, we make it easier for you to visit our website. This way, you do not need to repeatedly
            enter the same information when visiting our website and, for example, the items remain in your shopping cart until
            you have paid. We may place these cookies without your consent.</p>
    
        <h3 style="font-size: 1.8rem; margin-top: 1.5rem">5.2 Statistics cookies</h3>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">We use statistics cookies to optimize the website experience for our users. With these statistics cookies we get
            insights into the usage of our website. We ask your permission to place statistics cookies.</p>
    
        <h3 style="font-size: 1.8rem; margin-top: 1.5rem">5.3 Marketing/Tracking cookies</h3>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">Marketing/Tracking cookies are cookies or any other form of local storage, used to create user profiles to display
            advertising or to track the user on this website or across several websites for similar marketing purposes.</p>
    
        <h3 style="font-size: 1.8rem; margin-top: 1.5rem">5.4 Social media buttons</h3>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">On our website we have included buttons for Facebook, Twitter, LinkedIn, and Instagram to promote webpages (e.g.
            “like”, “pin”) or share (e.g. “tweet”) on social networks like Facebook, Twitter, LinkedIn, and Instagram. These
            buttons work using pieces of code coming from Facebook, Twitter, LinkedIn, and Instagram themselves. This code
            places cookies. These social media buttons also can store and process certain information, so a personalized
            advertisement can be shown to you.</p>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">Please read the privacy statement of these social networks (which can change regularly) to read what they do with
            your (personal) data which they process using these cookies. The data that is retrieved is anonymized as much as
            possible. Facebook, Twitter, LinkedIn, and Instagram are located in the United States.</p>
    
        <h2 style="font-size: 2rem; margin-top: 2.5rem">6. Placed cookies</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;"><strong>Google Analytics - Statistics</strong></p>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;"><em>Usage</em> - We use Google Analytics for website statistics. <a
                href="https://policies.google.com/privacy">Read more</a></p>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;"><em>Sharing data</em> - For more information, please read the <a
                href="https://policies.google.com/privacy">Google Analytics Privacy Statement</a></p>
            
            <strong >Statistics</strong>
            <table style="border-collapse: collapse; width: 60%; border: 1px solid black;margin-top:1rem">
        <tr>
            <th style="border: 1px solid black; padding: 8px;">Name</th>
            <th style="border: 1px solid black; padding: 8px;">Expiration</th>
            <th style="border: 1px solid black; padding: 8px;">Function</th>
        </tr>
        <tr>
            <td style="border: 1px solid black; padding: 8px;">_ga</td>
            <td style="border: 1px solid black; padding: 8px;">2 years</td>
            <td style="border: 1px solid black; padding: 8px;">Store and count pageviews</td>
        </tr>
        <tr>
            <td style="border: 1px solid black; padding: 8px;">_gid</td>
            <td style="border: 1px solid black; padding: 8px;">1 day</td>
            <td style="border: 1px solid black; padding: 8px;">Store and count pageviews</td>
        </tr>
        <tr>
            <td style="border: 1px solid black; padding: 8px;">_gat_gtag_UA_*</td>
            <td style="border: 1px solid black; padding: 8px;">1 minute</td>
            <td style="border: 1px solid black; padding: 8px;">Store a unique user ID</td>
        </tr>
        <tr>
            <td style="border: 1px solid black; padding: 8px;">_ga_*</td>
            <td style="border: 1px solid black; padding: 8px;">1 year</td>
            <td style="border: 1px solid black; padding: 8px;">Store and count pageviews</td>
        </tr>
    </table>
    
        <h2 style="font-size: 2rem; margin-top: 2.5rem">7. Consent</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">When you visit our website for the first time, we will show you a pop-up with an explanation about cookies. As soon
            as you click on "All good", you consent to us using all cookies and plug-ins as described in the pop-up and this
            Cookie Policy. You can disable the use of cookies via your browser, but please note that our website may no longer
            work properly.</p>
    
        <h3 style="font-size: 1.8rem; margin-top: 1.5rem">7.1 Manage your consent settings</h3>
        <ul>
            <li><strong>Functional</strong> - The technical storage or access is strictly necessary for the legitimate purpose
                of enabling the use of a specific service explicitly requested by the subscriber or user, or for the sole
                purpose of carrying out the transmission of a communication over an electronic communications network.</li>
            <li><strong>Statistics</strong> - The technical storage or access that is used exclusively for anonymous statistical
                purposes. Without a subpoena, voluntary compliance on the part of your Internet Service Provider, or additional
                records from a third party, information stored or retrieved for this purpose alone cannot usually be used to
                identify you.</li>
            <li><strong>Marketing/Tracking</strong> - The technical storage or access is required to create user profiles to send
                advertising, or to track the user on a website or across several websites for similar marketing purposes.</li>
        </ul>
    
        <h2 style="font-size: 2rem; margin-top: 2.5rem">8. Your rights with respect to personal data</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">You have the following rights with respect to your personal data:</p>
        <ul>
            <li style="font-size: 1.6rem;">You have the right to know why your personal data is needed, what will happen to it, and how long it will be
                retained for.</li>
            <li style="font-size: 1.6rem;>Right of access: You have the right to access your personal data that is known to us.</li>
            <li style="font-size: 1.6rem;>Right to rectification: you have the right to supplement, correct, have deleted or blocked your personal data
                whenever you wish.</li>
            <li style="font-size: 1.6rem;>If you give us your consent to process your data, you have the right to revoke that consent and to have your
                personal data deleted.</li>
            <li style="font-size: 1.6rem;>Right to transfer your data: you have the right to request all your personal data from the controller and transfer
                it in its entirety to another controller.</li>
            <li style="font-size: 1.6rem;>Right to object: you may object to the processing of your data. We comply with this, unless there are justified
                grounds for processing.</li>
        </ul>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">To exercise these rights, please contact us. Please refer to the contact details at the bottom of this Cookie Policy.
            If you have a complaint about how we handle your data, we would like to hear from you, but you also have the right to
            submit a complaint to the supervisory authority (the Information Commissioner's Office (ICO)).</p>
    
        <h2 style="font-size: 2rem; margin-top: 2.5rem">9. Enabling/disabling and deleting cookies</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">You can use your internet browser to automatically or manually delete cookies. You can also specify that certain
            cookies may not be placed. Another option is to change the settings of your internet browser so that you receive a
            message each time a cookie is placed. For more information about these options, please refer to the instructions in
            the Help section of your browser.</p>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">Please note that our website may not work properly if all cookies are disabled. If you do delete the cookies in your
            browser, they will be placed again after your consent when you visit our websites again.</p>
    
        <h2 style="font-size: 2rem; margin-top: 2.5rem">10. Contact details</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;margin-bottom: 0.8rem;">For questions and/or comments about our Cookie Policy and this statement, please contact us by using the following
            contact details:</p>
        <address>
            Mumbli<br>
            Accelerator<br>
            25 Kingsland Road<br>
            London E2 8AA<br>
            United Kingdom<br>
            Website: <a href="https://mumbli.com">https://mumbli.com</a><br>
            Email: <a href="mailto:hello@mumbli.com">hello@mumbli.com</a>
        </address>
    </body>
    
    </html>
    `;

    return(
        <div>
            <RawHTMLRenderer htmlString={rawHTML} />
        </div>
    );
};

export default CookiePolicy;    

    
    
    