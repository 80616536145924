import React from 'react';

interface RawHTMLRendererProps {
  htmlString: string;
}

const RawHTMLRenderer: React.FC<RawHTMLRendererProps> = ({ htmlString }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

export default RawHTMLRenderer;
