import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonGroup,
    Container,
    IconButton,
    Typography,
    Drawer
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { MdClose } from "react-icons/md";
import { MdKeyboardArrowRight } from 'react-icons/md';
import './profile-settings.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RWebShare } from "react-web-share";
import { browserName } from "react-device-detect";
import { SnackbarContext } from '../../../../context/snackbar.context';

function ProfileSettings() {
    useEffect(() => {
        checkShareCompatibility();
    }, [])

    const [shareCheck, setShareCheck] = useState(false);

    const [, setSnackbarData] = React.useContext(SnackbarContext);

    const [filterDrawer, setFilterDrawer] = useState<boolean>(false);

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setFilterDrawer(open);
    };

    const userId = localStorage.getItem("userId");

    const copyLink = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'App link copied to clipboard'
        };

        setSnackbarData(snackbar);
    }
    const shareProfile = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'App link shared'
        };

        setSnackbarData(snackbar);
    }

    const navigatorShare = async () => {
        if (navigator.share) {
            await navigator.share({
                title: "Sharing app",
                text: "Mumbli app link:",
                url: `${process.env.REACT_APP_SHARE_URL}`,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error in sharing', error));
        } else {
            console.log(`system does not support sharing files.`);
        }
    }


    const checkShareCompatibility = () => {
        const { platform } = window.navigator;
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];

        if (macosPlatforms.indexOf(platform) !== -1) {
            const os = 'Mac OS';
            if (browserName === 'Chrome') {
                setShareCheck(true);
            }
        }
    };
    return (
        <Box className="profile-settings top-header-spacing">
            {/* <Box position="absolute" right={8} top={204}>
                <NavLink to="/profile">
                    <IconButton color="primary" >
                        <MdClose size="30" />
                    </IconButton>
                </NavLink>
            </Box> */}
            <Container maxWidth="xs" className="position-relative">
            <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                <NavLink to="/profile">
                    <IconButton color="primary" component="span">
                        <MdClose fontSize="24" />
                    </IconButton>
                </NavLink>
            </Box>
                <Box textAlign="center" mt={1} mb={2}>
                    <Typography variant="h4" component="h1" color="primary" className="font-weight-700">Settings</Typography>
                </Box>

                <Box mb={2}>
                    <NavLink to="/profile/tribe">
                        <Button endIcon={<MdKeyboardArrowRight size="32" />} className="btn-root btn-gradient w-100 text-white">
                            <Box display="flex" alignItems="center">
                                <span className="icon-confetti mr-3"></span>
                                <Typography>Join the Mumbli Tribe</Typography>
                            </Box>
                        </Button>
                    </NavLink>
                </Box>

                <Box mb={2}>
                    <Button variant="outlined" color="primary" endIcon={<MdKeyboardArrowRight size="32" />} className="w-100">Account</Button>
                </Box>
                <Box mb={2}>
                    <Button variant="outlined" color="primary" onClick={toggleDrawer(true)} endIcon={<MdKeyboardArrowRight size="32" />} className="w-100">Share app</Button>
                </Box>
                <Box mb={2}>
                    <ButtonGroup orientation="vertical" className="d-block">
                        {/* <Button variant="outlined" color="primary" endIcon={<MdKeyboardArrowRight size="32" />} className="w-100">Whats new</Button > */}
                        <NavLink to='/contact-us'>
                            <Button variant="outlined" color="primary" endIcon={<MdKeyboardArrowRight size="32" />} className="w-100">Contact us</Button>
                        </NavLink>
                        <NavLink to='/cookie-policy'>
                            <Button variant="outlined" color="primary" endIcon={<MdKeyboardArrowRight size="32" />} className="w-100">Cookie Policy</Button>
                        </NavLink>
                        <NavLink to='/privacy-policy'>
                            <Button variant="outlined" color="primary" endIcon={<MdKeyboardArrowRight size="32" />} className="w-100">Privacy policy</Button>
                        </NavLink>
                    </ButtonGroup>
                </Box>

                <NavLink to="/logout">
                    <Button variant="outlined" color="secondary" endIcon={<MdKeyboardArrowRight size="32" />} className="w-100">Logout</Button>
                </NavLink>
            </Container>
            <Drawer className="share-drawer" variant="temporary" anchor="bottom" open={filterDrawer} onClose={toggleDrawer(false)}>
                <Box position="absolute" right={8} top={8}>
                    <IconButton color="primary" onClick={toggleDrawer(false)}>
                        <MdClose size="24" />
                    </IconButton>
                </Box>

                <Container maxWidth="xs">
                    <Box my={3} textAlign="center">
                        <Box className="drawer-header" mb={2}>
                            <Typography variant="h5" component="h2" className="font-weight-700">Share App</Typography>
                        </Box>

                        <Box mb={2} className="share-block">
                            <CopyToClipboard text={`${process.env.REACT_APP_SHARE_URL}`} onCopy={copyLink}>
                                <Button variant="outlined" color="primary" className="btn-root rounded-pill w-100" size="large" type="button">Copy Link</Button>
                            </CopyToClipboard>
                        </Box>
                        <Box className="share-block">
                            {!shareCheck ? <Button className="btn-root btn-gradient rounded-pill text-white w-100" size="large" type="button" onClick={navigatorShare}>Share</Button> :
                                <RWebShare
                                    data={{
                                        text: "Mumbli app link:",
                                        url: `${process.env.REACT_APP_SHARE_URL}`,
                                        title: "Sharing app",
                                    }}
                                    onClick={shareProfile}
                                >
                                    <Button className="btn-root btn-gradient rounded-pill text-white w-100" size="large" type="button">Share</Button>
                                </RWebShare>
                            }
                        </Box>
                    </Box>
                </Container>
            </Drawer>
        </Box>
    )
}

export default ProfileSettings;