import React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../context/auth.context';

export default function Logout() {

    localStorage.removeItem('newAuth');
    localStorage.removeItem('userId');
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("permissions")

    return <Redirect to='/auth/login' />
}