import { FormControl, FormHelperText, InputLabel, TextField, Select, MenuItem, Button, Grid, Hidden, Box, Typography, Container } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { SnackbarContext } from '../../../../context/snackbar.context';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { signupSchema2 } from '../../../../models/signup.schema';
import '../signup/signup.scss';
import { userVerify } from '../../../../axios/api';
import moment from 'moment';


function VerifySignup(props: any) {

    const { register, errors, handleSubmit, control, getValues } = useForm({
        resolver: yupResolver(signupSchema2)
    });

    const [, setSnackbarData] = React.useContext(SnackbarContext);

    const successOtp = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'Otp has been sent to your email'
        };

        setSnackbarData(snackbar);
    }

    const resError = (message: any) => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: message
        };

        setSnackbarData(snackbar);
    }

    const onSubmit = () => {
        const user =
        {
            "name": {
                "firstName": localStorage.getItem("firstName"),
                "lastName": localStorage.getItem("lastName")
            },
            "email": localStorage.getItem("email"),
            "password": localStorage.getItem("password"),
            "dob": moment(getValues("birthDate")).format("MM-DD-YYYY"),
            "postcode": getValues("postCode"),
            "earTech": Boolean(getValues("technology")),
            "permissions": ["user"]
        }
        userVerify(user, {
            onError: (res) => {
                // console.log(res.data.message)
                resError(res.data.message);
            },
            onSuccess: (res) => {
                successOtp()
                console.log(res)
                localStorage.removeItem("password");
                props.history.push('/auth/otp-verify');
            }
        })

    };


    return (
        <>
            <Box p={3} className="auth-form-header">
                <span className="icon-mumbli-logo-2"></span>
                <Typography variant="h2" component="div">Sign Up</Typography>
            </Box>
            <Container maxWidth="xs" className="signup-content">
                <Box py={4}>
                    <Grid>
                        <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
                            <Box mb={4}>
                                <Typography variant="h4" component="h1" className="font-weight-700" color="primary">Create your Mumbli account.</Typography>
                            </Box>

                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="birth-date">Date of birth</InputLabel>
                                        <TextField variant="outlined" id="birth-date" aria-describedby="birth-date-helper-text" type="date" name="birthDate" inputRef={register} />
                                        <FormHelperText id="birth-date-helper-text">MM/DD/YYYY</FormHelperText>
                                        <p>{errors.birthDate?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="technology" shrink={false}>Do you use ear tech</InputLabel>
                                        <Controller
                                            as={
                                                <Select variant="outlined" id="technology" aria-describedby="technology-helper-text">
                                                    <MenuItem value={1}>Yes</MenuItem>
                                                    <MenuItem value={0}>No</MenuItem>
                                                </Select>
                                            }
                                            name="technology"
                                            control={control}
                                            defaultValue={0}
                                        />
                                        <FormHelperText id="technology-helper-text">examples of ear tech: headphones, earphones, wireless buds, hearing devices etc.</FormHelperText>
                                        <p>{errors.technology?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="postcode">Post Code</InputLabel>
                                        <TextField variant="outlined" id="postCode" aria-describedby="postCode-helper-text" name="postCode" inputRef={register} />
                                        <FormHelperText id="postcode-helper-text">e.g. EC1A 1BB</FormHelperText>
                                        <p>{errors.postCode?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <Button type="submit" variant="contained" className="btn-root rounded-pill btn-gradient w-100 text-white text-capitalize" size="large" disableElevation>Create Account</Button>
                                </Box>
                            </Grid>


                            <Grid item xs={12}>
                                <Link to="/auth/login" className="pure-link">
                                    Have an account? Login
                                </Link>
                            </Grid>
                        </form>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}

export default VerifySignup;