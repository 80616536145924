import './profile.scss';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { 
    Box, 
    Button, 
    Grid, 
    Drawer,
    Hidden
} from '@material-ui/core';
import { UserContext } from '../../context/user.context';
import ProfileData from './components/profile-data/profile-data';
import ProfilePreferences from './components/profile-preferences/profile-preferences';
import { AuthService } from '../../services/auth.service';
import picHeadphones from '../../assets/img/headphones.svg';
import picAura from '../../assets/img/aura.svg';
import ProfileTabWrapper from './components/profile-tab-wrapper/profile-tab-wrapper';

function ProfilePicture(props: any) {
    
    const [userData, setUserData] = React.useContext(UserContext);
    
    return <span className="icon-user"></span>;
}

function ProfilePage() {

    const [userData, setUserData] = React.useContext(UserContext);
    const [profileSidebar, setProfileSidebar] = React.useState(false);

    const toggleProfileSidebar = () => {
        const status = !profileSidebar;
        setProfileSidebar(status);
    }

    return (
        <div className="drawer-profile-wrapper">
            <ProfileTabWrapper />
        </div>
    );
}

export default ProfilePage;

/*
            <Hidden smUp>
                <ProfileTabWrapper />
            </Hidden>

            <Hidden xsDown>
                <Hidden lgUp>
                    <Box className="xs-profile-header">
                        <div className="user-data">
                            <ProfilePicture />
                            <span>{userData?.firstName} {userData?.lastName}</span>
                        </div>
                        <Button className="btn-light" onClick={toggleProfileSidebar}>
                                Edit Profile
                                <span className="icon-edit"></span>
                        </Button>
                    </Box>
                </Hidden>

                <Grid container>
                    <Grid item xs={12} xl={6}>
                        <Box className="content-card">
                            <ProfilePreferences />
                        </Box>
                    </Grid>

                    <Grid item xs={12} xl={6}>
                        <Grid container className="full-height">
                            <Grid item xs={12}>
                                <Box className="content-card">
                                    <h1>My Aura</h1>
                                    <p>Unlock tailored venue recommendations that suit your uniqe experiences of sound.</p>
                                    <span className="icon">
                                        <img src={picAura} height="128" />
                                    </span>
                                    <NavLink to="/wellness">
                                        <Button className="btn-gradient">Unlock</Button>
                                    </NavLink>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box className="content-card">
                                    <h1>My Technology</h1>
                                    <p>Unlock tailored tech recommendations that suit your uniqe experiences of sound.</p>
                                    <span className="icon">
                                    <img src={picHeadphones} height="128" />
                                    </span>
                                    <NavLink to="/technology">
                                        <Button className="btn-gradient">Unlock</Button>
                                    </NavLink>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Hidden mdDown>
                    <Drawer variant="permanent" anchor="right" className="profile-sidebar">
                        <ProfilePicture />
                        <ProfileData />

                        <Button className="btn-gradient">Invite Friends</Button>
                    </Drawer>
                </Hidden>

                <Hidden lgUp>
                    <Drawer open={profileSidebar} transitionDuration={1000} anchor="right" className="xs-profile-sidebar">
                        <Box className="toggler-wrapper">
                            <Button onClick={toggleProfileSidebar} className="btn-light">
                                Close Profile Settings
                            </Button>
                        </Box>
                        <ProfilePicture />
                        <ProfileData />

                        <Button className="btn-gradient">Invite Friends</Button>
                    </Drawer>
                </Hidden>
            </Hidden>
*/