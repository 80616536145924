import './wellness-feed-list.scss';
import { Box, Button, Link, List, ListItem } from '@material-ui/core';
import React, { useState } from 'react';
import noiseImg from '../../../../assets/img/noise.jpg';
import sountTherapyImg from '../../../../assets/img/sound-therapy.jpg';
import whyItsTimeImg from '../../../../assets/img/why-its-time.jpg';

function WellnessFeedImage(props: any) {

    const photo = props.image;

    if ( photo ) {
        return (
            <Box className="image-box">
                <img className="venue-photo" src={photo} />
            </Box>
        );
    }

    return (
        <Box className="image-box" />
    );
}


function WellnessFeedList(props: any) {

    const feedList = [
        {
            _id: 1,
            title: "Do these sounds drive you crazy?",
            link: {
                name: "Tips for you",
                url: "https://mumbli.com/chewing-finger-tapping-page-flipping-do-these-sounds-drive-you-crazy/"
            },
            image: noiseImg
        },
        {
            _id: 1,
            title: "Ever heard of sound therapy?",
            link: {
                name: "Sound solutions",
                url: " https://mumbli.com/sound-therapy/"
            },
            image: sountTherapyImg
        },
        {
            _id: 1,
            title: "Looking for places you can actually hear your date?",
            link: {
                name: "Tips for you",
                url: "https://mumbli.com/top-7-locations-where-you-can-actually-hear-your-date/"
            },
            image: whyItsTimeImg
        }
    ];

    const listItems = feedList.map((item: any) => {
        return (
            <ListItem className="feed-list-item content-card">
                <a href={item.link.url}>
                    <Box className="feed-description">
                        <h3>{item.title}</h3>
                        <Link className="pure-link">
                            {item.link.name}
                        </Link>
                    </Box>

                    <WellnessFeedImage image={item.image} />
                </a>
            </ListItem>
        );
    });

    return (
        <List className="wellness-feed-list">
            {listItems}
        </List>
    );
}

export default WellnessFeedList;