import { UserInterface } from "../models/user.model";
import { AuthService } from "./auth.service";
import FeathersService from "./rest/feathers.service";

export class UserService {

    private userRestService = FeathersService.service('users');
    private authService = new AuthService();

    getUserProfile( userId: string ) {
        return this.userRestService.get(userId, {}).then((res: any) => {
            return res;
        }).catch((err: any) => {
            console.log(err);
            return err;
        });
    }

    createUser( userData: UserInterface ): Promise<UserInterface> {
        return this.userRestService.create(userData).then((res: any) => {
            return res;
        }).catch((err: any) => {
            console.log(err);
            return err;
        });
    }

    updateUser( userData: UserInterface ): Promise<UserInterface> {
        return this.userRestService.patch(userData._id, userData).then((res: any) => {
            return res;
        }).catch((err: any) => {
            console.log(err);
            return err;
        });
    }
}
