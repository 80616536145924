import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../components/privat-route/privat-route';
import VenueStore from '../../context/venue.context';
import VenueForm from './components/venue-form/venue-form';
import VenuesMapFilter from './components/venue-map-filter/venue-map-filter';
import VenuePreferences from './components/venue-preferences/venue-preferences';
import './venues.scss';

function VenuesPage() {

    let { path, url } = useRouteMatch();

    return (
        <VenueStore>
            <Switch>
                <PrivateRoute path={`${path}/add`} component={VenueForm} />
                <PrivateRoute path={`${path}/preferences`} component={VenuePreferences} />
                <PrivateRoute path={`${path}/`} component={VenuesMapFilter} />
            </Switch>
        </VenueStore>
    );
}

export default VenuesPage;