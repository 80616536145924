import { Box, Button, Container, Grid, Hidden, MobileStepper, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { QuizContext } from '../../../../context/quiz.context';
import { useForm } from "react-hook-form";
import { SnackbarContext } from '../../../../context/snackbar.context';
import { UserProfileContext } from '../../../../context/user-profile.context';
import { UserContext } from '../../../../context/user.context';
import { QuizInterface, QuizQuestionInterface, QuizQuestionMultiInterface } from '../../../../models/quiz.model';
import { QuizAnswers, QuizValues, UserProfileDataInterface } from '../../../../models/user-profile-data.model';
import { UserProfileDataService } from '../../../../services/user-profile-data.service';
import './wellness-quiz-consciousness.scss';
import { createQuiz, getQuizType } from '../../../../axios/api';
import swal from 'sweetalert';

function WellnessQuizConsciousness(props: any) {

    const { register, errors, handleSubmit, getValues } = useForm()

    const userProfileDataService = new UserProfileDataService();
    const [userData, setUserData] = React.useContext(UserContext);
    const [quizList, setQuizList] = React.useContext(QuizContext);

    const [userProfileData, setUserProfileData] = React.useContext(UserProfileContext);
    const [snackbarData, setSnackbarData] = React.useContext(SnackbarContext);

    const [activeStep, setActiveStep] = React.useState(0);

    const [wellnessQuizQuestions, setWellnessQuizQuestions] = useState([
        {
            name: "eatingSounds",
            label: "Eating sounds (e.g. chewing, slurping)",
            value: -1
        },
        {
            name: "fingerSounds",
            label: "Finger sounds (e.g. cracking, tapping)",
            value: -1
        },
        {
            name: "penSounds",
            label: "Pen clicking",
            value: -1
        },
        {
            name: "nailsOnChalkboardSounds",
            label: "Nails on a chalkboard",
            value: -1
        },
        {
            name: "vomitingSounds",
            label: "Vomiting sounds",
            value: -1
        },
        {
            name: "trackSounds",
            label: "A large truck or bus driving by",
            value: -1
        },
        {
            name: "trafficSounds",
            label: "Traffic noise",
            value: -1
        },
        {
            name: "carHornSounds",
            label: "Car horns",
            value: -1
        },
        {
            name: "musicSounds",
            label: "A live event that's louder than preferred",
            value: -1
        },
        {
            name: "ambulanceSounds",
            label: "Ambulance and police sirens",
            value: -1
        },

    ]);

    useEffect(() => {
        if (localStorage.getItem("newAuth")) {
            const data = {
                "quizAlias": "soundConsciousness"
            }
            getQuizType(data, {
                onSuccess: (res) => {
                    if (Object.keys(res.data).length !== 0) {
                        setWellnessQuizQuestions(res.data.answers);
                    }
                },
                onError: (res) => {
                    error();
                }
            })
        }
    }, [])

    // useMemo(() => {
    //     if (userProfileData?.quiz) {

    //         const wellnessQuiz = userProfileData.quiz.find((item: QuizAnswers) => {
    //             return item.type === 'wellness' && item.quizAlias === 'soundConsciousness';
    //         });

    //         if (wellnessQuiz) {
    //             const wellnessAnswers: QuizQuestionInterface[] = wellnessQuiz.answers.map((item: QuizQuestionInterface) => {
    //                 return {
    //                     name: item.name,
    //                     label: item.label,
    //                     value: typeof item.value === 'number' ? item.value : 0
    //                 }
    //             });

    //             const newWellnessQuizValues = [...wellnessQuizQuestions];
    //             newWellnessQuizValues.forEach((q: QuizQuestionInterface) => {

    //                 const val = wellnessAnswers.find(v => v.name === q.name);
    //                 console.log(val);
    //                 if (val) {
    //                     q.value = val.value;
    //                 }

    //             });

    //             setWellnessQuizQuestions(newWellnessQuizValues);
    //         }
    //     }
    // }, [userProfileData]);
    /*
        useEffect(() => {
            if ( quizList ) {
                console.log(quizList);
                const quiz: QuizInterface = quizList.find((item: QuizInterface) => item.alias === 'soundConsciousness');
    
                if ( quiz ) {
                    setWellnessQuizQuestions(quiz.questions);
                }
            }
        }, [quizList]);
    */

    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    const answerQuestion = (quizQuestion: QuizQuestionInterface, val: number) => {
        const newValues = [...wellnessQuizQuestions];
        const answer: QuizQuestionInterface = newValues.find((item) => item.name === quizQuestion.name) ?? { name: "", label: "", value: 0 };
        answer.value = val;
        setWellnessQuizQuestions(newValues);
    };

    const quizUpdate = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'Quiz updated successfully'
        };

        setSnackbarData(snackbar);
    }
    const error = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Error'
        };
        setSnackbarData(snackbar);
    }

    const checkValue = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Atleast answer one question to proceed'
        };

        setSnackbarData(snackbar);
    }
    const saveQuiz = () => {
        
        const userQuiz = {
            "type": "wellness",
            "quizAlias": "soundConsciousness",
            "answers": wellnessQuizQuestions
        }

        const unansweredQuestions = wellnessQuizQuestions.find((item) => item.value !== -1);
        if (unansweredQuestions != null) {
            for (var i = 0; i < wellnessQuizQuestions.length; i++) {
                if (wellnessQuizQuestions[i].value === -1) {
                    wellnessQuizQuestions[i].value = 0;
                }
            }
            createQuiz(userQuiz, {
                onSuccess: () => {
                    if (localStorage.getItem("newAuth")) {
                        quizUpdate();
                        props.history.push("/profile")
                    }
                },
                onError: () => {
                    error();
                }
            })
        }
        else {
            checkValue();
        }

    }
    // const unansweredQuestions = wellnessQuizQuestions.find((item) => item.value === 0);
    // if (unansweredQuestions) {
    //     const snackbar = {
    //         isOpen: true,
    //         snackbarType: 'warning',
    //         snackbarText: 'To proceed You need to answer all quiz questions first.'
    //     };

    //     setSnackbarData(snackbar);
    // }

    // else {

    //     const countedValues = wellnessQuizQuestions.reduce((val, item) => item.value + val, 0);
    //     let wellnessQuizCountedValues = 0;

    //     if (countedValues <= 15) {
    //         wellnessQuizCountedValues = 1;
    //     }

    //     if (countedValues >= 16 && countedValues <= 20) {
    //         wellnessQuizCountedValues = 2;
    //     }

    //     if (countedValues >= 21 && countedValues <= 30) {
    //         wellnessQuizCountedValues = 3;
    //     }

    //     if (countedValues > 30) {
    //         wellnessQuizCountedValues = 4;
    //     }

    // const updatedUserProfileData: any = {
    //     userId: userData._id,
    //     quiz: [{
    //         type: "wellness",
    //         quizAlias: 'soundConsciousness',
    //         answers: wellnessQuizQuestions
    //     }],
    //     value: [{
    //         type: "wellness",
    //         quizAlias: 'soundConsciousness',
    //         value: wellnessQuizCountedValues
    //     }]
    // };

    // if (userProfileData) {

    //     updatedUserProfileData._id = userProfileData._id;

    //     if (userProfileData.quiz) {
    //         const techQuizes = userProfileData.quiz.filter((item: QuizAnswers) => {
    //             return item.type === 'tech';
    //         });
    //         if (techQuizes) {
    //             updatedUserProfileData.quiz.push(...techQuizes);
    //         }

    //         const wellnessQuizes = userProfileData.quiz.filter((item: QuizAnswers) => {
    //             return item.type === 'wellness' && item.quizAlias !== 'soundConsciousness';
    //         });
    //         if (wellnessQuizes) {
    //             updatedUserProfileData.quiz.push(...wellnessQuizes);
    //         }
    //     }

    //     if (userProfileData.value) {
    //         const techQuizesValues = userProfileData.value.filter((item: QuizValues) => {
    //             return item.type === 'tech';
    //         });
    //         if (techQuizesValues) {
    //             updatedUserProfileData.value.push(...techQuizesValues);
    //         }

    //         const wellnessQuizesValues = userProfileData.value.filter((item: QuizValues) => {
    //             return item.type === 'wellness' && item.quizAlias !== 'soundConsciousness';
    //         });
    //         if (wellnessQuizesValues) {
    //             updatedUserProfileData.value.push(...wellnessQuizesValues);
    //         }
    //     }

    //     userProfileDataService.updateUserProfileData(updatedUserProfileData).then((res: any) => {

    //         if (res.type === 'FeathersError') {
    //             const snackbar = {
    //                 isOpen: true,
    //                 snackbarType: 'error',
    //                 snackbarText: res.message ?? 'Server error. Please try later!'
    //             };

    //             setSnackbarData(snackbar);
    //         } else {

    //             const snackbar = {
    //                 isOpen: true,
    //                 snackbarType: 'success',
    //                 snackbarText: 'Quiz answers were updated.'
    //             };

    //             setSnackbarData(snackbar);

    //             setUserProfileData(res);

    //             props.history.push('/profile');
    //         }
    //     }).catch((err: any) => {
    //         console.log(err);
    //     });
    // } else {

    //     userProfileDataService.createUserProfileData(updatedUserProfileData).then((res: any) => {

    //         if (res.type === 'FeathersError') {

    //             const snackbar = {
    //                 isOpen: true,
    //                 snackbarType: 'error',
    //                 snackbarText: res.message ?? 'Server error. Please try later!'
    //             };

    //             setSnackbarData(snackbar);
    //         } else {

    //             const snackbar = {
    //                 isOpen: true,
    //                 snackbarType: 'success',
    //                 snackbarText: 'Quiz answers were updated.'
    //             };

    //             setSnackbarData(snackbar);

    //             setUserProfileData(res);

    //             props.history.push('/profile');
    //         }
    //     }).catch((err: any) => {
    //         console.log(err);
    //     });
    // }

    // }


    function QuizQuestion(quizQuestion: QuizQuestionInterface) {
        return (
            <>

                <Box className="quiz-question" mt={3} key={quizQuestion.name}>
                    <Box mb={2}>
                        <Typography variant="h6" component="h2" color="primary" className="font-weight-700">{quizQuestion.label}</Typography>
                    </Box>

                    <Box className="answer-range-block" textAlign="center">
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Button variant="outlined" className={`btn-ans btn-ans-warning ${quizQuestion.value === 1 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 1)}></Button>
                                <Typography variant="body1" className="text-warning-main">I don't mind it</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="outlined" className={`btn-ans btn-ans-warning ${quizQuestion.value === 2 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 2)}></Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="outlined" className={`btn-ans btn-ans-secondary ${quizQuestion.value === 3 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 3)}></Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="outlined" className={`btn-ans btn-ans-secondary ${quizQuestion.value === 4 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 4)}></Button>
                                <Typography variant="body1" color="secondary">I can't stand it</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
            </>
        )
    }

    // const StepperContent = () => {

    //     // if (activeStep === 0) {
    //     return (
    //         <Box className="quiz-screen" mb={3}>
    //             <Box my={2}>
    //                 <Typography variant="h4" component="h1" color="primary" className="font-weight-700">How do these sounds make you feel?</Typography>
    //             </Box>
    //             <Box>
    //                 <Typography variant="body1" color="primary">
    //                     Designed by Dr Therese Cash, these questions will determine your level of sound consciousness by looking into how different sounds make you feel.
    //                 </Typography>
    //             </Box>

    //             <QuizQuestion {...wellnessQuizQuestions[0]} />
    //             <QuizQuestion {...wellnessQuizQuestions[1]} />
    //             <QuizQuestion {...wellnessQuizQuestions[2]} />
    //             <QuizQuestion {...wellnessQuizQuestions[3]} />
    //             <QuizQuestion {...wellnessQuizQuestions[4]} />
    //             <QuizQuestion {...wellnessQuizQuestions[5]} />
    //             <QuizQuestion {...wellnessQuizQuestions[6]} />
    //             <QuizQuestion {...wellnessQuizQuestions[7]} />
    //             <QuizQuestion {...wellnessQuizQuestions[8]} />
    //             <QuizQuestion {...wellnessQuizQuestions[9]} />
    //         </Box>
    //     );
    //     // }

    //     // if (activeStep === 1) {
    //     //     return (
    //     //         <Box className="quiz-screen">
    //     //             <QuizQuestion {...wellnessQuizQuestions[2]} />
    //     //             <QuizQuestion {...wellnessQuizQuestions[3]} />
    //     //             <QuizQuestion {...wellnessQuizQuestions[4]} />
    //     //         </Box>
    //     //     );
    //     // }

    //     // if (activeStep === 2) {
    //     //     return (
    //     //         <Box className="quiz-screen">
    //     //             <QuizQuestion {...wellnessQuizQuestions[5]} />
    //     //             <QuizQuestion {...wellnessQuizQuestions[6]} />
    //     //             <QuizQuestion {...wellnessQuizQuestions[7]} />
    //     //         </Box>
    //     //     );
    //     // }

    //     // if (activeStep === 3) {
    //     //     return (
    //     //         <Box className="quiz-screen">
    //     //             <QuizQuestion {...wellnessQuizQuestions[8]} />
    //     //             <QuizQuestion {...wellnessQuizQuestions[9]} />
    //     //         </Box>
    //     //     );
    //     // }

    //     return null;
    // }

    return (
        <Container maxWidth="xs" className="quiz">
            {/* <Grid container alignContent="center" justifyContent="center" className="quiz-container">
                <Grid item xs={12} md={8} className="wellness-quiz wellness-quiz-consciousness"> */}

            {/* <Hidden xsDown> */}
            <form className="signup-form" onSubmit={handleSubmit(saveQuiz)}>
                <Box my={2}>
                    <Typography variant="h4" component="h1" color="primary" className="font-weight-700">Find your hearing preference type</Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="body1" color="primary">
                        Hearing wellness is a term we use to describe how the world sounds to you & what your preferences for it are.
                        Take the quiz below to find your hearing preference type and how you compare to others who've taken it too!
                    </Typography>
                </Box>
                <Typography variant="body1" color="primary">
                    This quiz is based off of the work by Clinical Psycologist Dr. Therese Verkerke Cash,
                    designed to see how different people react to certain sounds.
                </Typography>
                <Box mb={3}>
                    {wellnessQuizQuestions.map((quizQuestion) => (
                        <QuizQuestion key={quizQuestion.name} {...quizQuestion} />
                    ))}
                </Box>
                {/* </Hidden> */}
                {/* <QuizQuestion {...wellnessQuizQuestions[0]} />
            <QuizQuestion {...wellnessQuizQuestions[1]} />
            <QuizQuestion {...wellnessQuizQuestions[2]} />
            <QuizQuestion {...wellnessQuizQuestions[3]} />
            <QuizQuestion {...wellnessQuizQuestions[4]} />
            <QuizQuestion {...wellnessQuizQuestions[5]} />
            <QuizQuestion {...wellnessQuizQuestions[6]} />
            <QuizQuestion {...wellnessQuizQuestions[7]} />
            <QuizQuestion {...wellnessQuizQuestions[8]} />
            <QuizQuestion {...wellnessQuizQuestions[9]} /> */}

                {/* <Hidden smUp>

                <MobileStepper
                    variant="progress"
                    steps={4}
                    position="static"
                    activeStep={activeStep}
                    className="quiz-stepper"
                    nextButton={
                        activeStep === 3 ?
                            <Button className="btn-gradient" onClick={saveQuiz}>Submit</Button> :
                            <span onClick={handleNext} className="icon-right-arrow"></span>
                    }
                    backButton={
                        activeStep === 0 ?
                            <NavLink to="/profile" className="icon-left-arrow"></NavLink> :
                            <span onClick={handleBack} className="icon-left-arrow"></span>
                    }
                />

                <StepperContent />

            </Hidden> */}
                {/* <StepperContent /> */}
                <Box pb={3} display="flex" justifyContent="end">
                    <Button type="submit" variant="contained" className="btn-root rounded-pill btn-gradient text-white" size="large" disableElevation>Submit</Button>
                </Box>
                {/* </Grid>
            </Grid> */}
            </form>
        </Container>
    );
}

export default WellnessQuizConsciousness;
