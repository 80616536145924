import './wellness-for-me.scss';
import React, { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { 
    Box, 
    Button
} from '@material-ui/core';
import auraImg from '../../../../assets/img/aura.svg';
import hearingAuraImg from '../../../../assets/img/hearing-aura.svg';
import { UserProfileContext } from '../../../../context/user-profile.context';

function WellnessForMe() {

    const [userProfileData] = React.useContext(UserProfileContext);
    const [wellnessQuiz, setWellnessQuiz] = useState<boolean | null>(null);

    useMemo(() => {
        if ( userProfileData?.quiz ) {
            
            const wellnessQuiz = userProfileData.quiz.find((item: any) => item.type === 'wellness');
            if ( wellnessQuiz ) {
                setWellnessQuiz(true);
            }
        }
    }, [userProfileData]);

    return (
        <Box className="wellness-for-me">
            <h1>
                Wellness tips for me
                <NavLink to="/profile/settings">
                    <span className="icon-settings"></span>
                </NavLink>
            </h1>

            <Box className="content">

                <Box className="image-box">
                    <img className="venue-photo" src={wellnessQuiz ? hearingAuraImg : auraImg} />
                </Box>

                <p>
                    What are your preferences for sound?<br />
                    <NavLink to="/profile/wellness-quiz">Answer a few questions</NavLink> to get personalized<br />
                    recommendations for venues.
                </p>

                <NavLink to="/profile/wellness-quiz">
                    <Button className="btn-gradient">Go to the questions</Button>
                </NavLink>
            </Box>
        </Box>
    );

}

export default WellnessForMe;