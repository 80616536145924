import React, { useState } from "react";
import { AuthInterface } from "../models/auth.model";

const initAuthState: string | null = localStorage.getItem('auth');

type AuthContextType = {
    authToken: string | null;
    setAuthData: (value: AuthInterface) => void;
}

export const AuthContext = React.createContext<any>(initAuthState);

const AuthStore = ({children}: any) => {
    const [authToken, setAuthData] = useState(initAuthState);
    
    return (
        <AuthContext.Provider value={[authToken, setAuthData]}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthStore;