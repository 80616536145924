import './snackbar-message.scss';

import { Snackbar, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { SnackbarContext } from '../../context/snackbar.context';

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
error
warning
info
success
*/
  
export default function SnackbarMessage() {

    const [snackbarData, setSnackbarData] = React.useContext(SnackbarContext);
  
    const handleClose = (event: any, reason: any) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        const updatedSnackbarData = {
            ...snackbarData,
            isOpen: false
        };

        setSnackbarData(updatedSnackbarData);
    };
  
    return (
        <Snackbar className="snackbar-block" open={snackbarData.isOpen} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={snackbarData.snackbarType}>
                {snackbarData.snackbarText}
            </Alert>
        </Snackbar>
    );
}