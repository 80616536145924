import { FormControl, FormHelperText, InputLabel, TextField, Button, Grid, Box, Container, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import './login.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { SnackbarContext } from '../../../../context/snackbar.context';
import * as Yup from 'yup'
import OtpInput from 'react-otp-input';
import { resetPassword, resetOtp } from '../../../../axios/api';

const ResetPassword = (props: any) => {

    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');

    const email = localStorage.getItem("email");

    const formSchema = Yup.object().shape({
        password: Yup.string().required("Please enter your password").matches(/^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*/])[a-zA-Z0-9!@#$%^&*/]{8,}$/, function () {
            return "Minimum 8 characters long and must contain one upper case character, one lowecase character, one special character and one number"
        }),
        cpassword: Yup.string().required("Confirm your password").oneOf([Yup.ref('password'), null], 'Password should match.')
    })


    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(formSchema)
    });

    const [otp, setOtp] = useState('');

    const [, setSnackbarData] = React.useContext(SnackbarContext);

    const notSamePassword = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Password does not match'
        };

        setSnackbarData(snackbar);
    }

    const blankPassword = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Please enter password'
        };

        setSnackbarData(snackbar);
    }

    const blankOtp = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Please enter OTP'
        };

        setSnackbarData(snackbar);
    }
    const wrongOtp = (message: any) => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: message
        };

        setSnackbarData(snackbar);
    }
    const changePassword = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'Password changed successfully'
        };

        setSnackbarData(snackbar);
    }
    const noOtp = (message: any) => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: message
        };

        setSnackbarData(snackbar);
    }
    const successOtp = (message: any) => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: message
        };

        setSnackbarData(snackbar);
    }

    const handleCheck = (value: any) => {
        const data = {
            "email": email,
            "password": value.password,
            "otp": parseInt(otp)
        }
        if (value.password !== value.cpassword) {
            notSamePassword();
        }
        else if (value.password === '') {
            blankPassword()
        }
        else if (!otp) {
            blankOtp();
        }
        else {
            resetPassword(data, {
                onError: (res) => {
                    wrongOtp(res.data.message);
                },
                onSuccess: (res) => {
                    changePassword()
                    props.history.push(`/auth/login`);
                    localStorage.removeItem("email");
                }
            })
        }
    }
    const resend = () => {
        const resendData = {
            "email": localStorage.getItem("email")
        }
        resetOtp(resendData, {
            onError: (res) => {
                noOtp(res.data.message);
            },
            onSuccess: (res) => {
                successOtp(res.data.success);
            }
        })
    }


    return (
        <>
            <Box p={3} className="auth-form-header">
                <span className="icon-mumbli-logo-2"></span>
                <Typography variant="h2" component="div">Reset Password</Typography>
            </Box>
            <Container maxWidth="xs" className="signup-content">
                <Box py={4}>
                    <Grid>
                        <form className="signup-form" onSubmit={handleSubmit(handleCheck)}>
                            <Box mb={4}>
                                <Typography variant="h4" component="h1" className="font-weight-700" color="primary">Reset Password</Typography>
                            </Box>
                            <Grid item xs={12}>
                                <Box mb={4} textAlign="center" display={{ xs: 'flex' }} alignItems={{ xs: 'center' }} flexDirection={{ xs: 'column' }}>
                                    <InputLabel>Please enter the 4 digit OTP that has been sent to your email address</InputLabel>
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        aria-describedby="otp"
                                        separator={<span>&nbsp;&nbsp;</span>}
                                        className="otp"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={4}>
                                    <FormControl fullWidth >
                                        <InputLabel htmlFor="password">Enter New Password</InputLabel>
                                        <TextField variant="outlined" id="password" aria-describedby="password-helper-text" type="password" name="password" inputRef={register} onChange={(data) => setPassword(data.target.value)} />
                                        <FormHelperText id="password-helper-text"></FormHelperText>
                                        <p>{errors.password?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={4}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="cpassword">Confirm New Password</InputLabel>
                                        <TextField variant="outlined" id="cpassword" aria-describedby="cpassword-helper-text" type="password" name="cpassword" inputRef={register} onChange={(data) => setCpassword(data.target.value)} />
                                        <FormHelperText id="cpassword-helper-text"></FormHelperText>
                                        <p>{errors.cpassword?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <Button type="submit" variant="contained" className="btn-root rounded-pill btn-gradient w-100 text-white text-capitalize" size="large" disableElevation>Save Password</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={4}>
                                    <Button variant="contained" className="btn-root rounded-pill w-100 text-capitalize" size="large" disableElevation onClick={resend}> Resend OTP</Button>
                                </Box>
                            </Grid>
                        </form>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default ResetPassword