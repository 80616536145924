import api from "./axios";

type TRequestOption = { onError: (err: any) => void, onSuccess: (res: any) => void }

export const forgotPassword = (req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.post(`forgot-password`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const login = (req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.post(`authentication`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const resetPassword = (req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.post(`reset-password`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const resetOtp = (req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.post(`resend-otp`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const otpVerify = (req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.post(`verify`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const userVerify = (req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.post(`users`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const updateProfile = (req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.post(`update-profile`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const me = (options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.get(`me`).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const createQuiz = (req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.post(`create-user-quiz`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}
export const getQuizType = (req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.post(`get-user-quiz-type`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}
export const addNewPlaces = (req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.post(`places`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const searchPlaces = (req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.post(`find-places`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const allPlaces = (obj: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    // console.log("API", `all-places?page=${obj.page}&limit=${obj.limit}`)
    api.get(`all-places?page=${obj.page}&limit=${obj.limit}`).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const updatePlaces = (place: any, req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.patch(`places/${place}`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const statusUpdate = (place: any, req: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.post(`status-update/${place}`, req).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

export const deleteVenues = (id: any, options: TRequestOption) => {
    const { onError, onSuccess } = options
    api.delete(`places/${id}`).then(res => {
        onSuccess(res)
    }).catch(error => {
        onError(error)
    })
}

