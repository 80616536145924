import { 
    Box, 
    Button,
    Card,
    Slider,
    Typography
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { UserProfileContext } from '../../../../context/user-profile.context';
import classNames from 'classnames';
import './wellness-dashboard.scss';
import { QuizService } from '../../../../services/quiz.service';

function RecommendationLink({quizAnswered}: any) {
console.log(quizAnswered);
    if ( quizAnswered === true) {
        return(
            <a href="/wellness/quiz" className="btn-link">
                {/* <Button className="btn-gradient">See recommendations</Button> */}
            </a>
        );
    }

    return null;
}

function WellnessDashboard() {

    const quizService = new QuizService();

    const [userProfileData, setUserProfileData] = React.useContext(UserProfileContext);

    const [wellnessQuiz, setWellnessQuiz] = useState<boolean>(false);
    const [soundConsciousnessValue, setSoundConsciousnessValue] = useState<number>(0);
    const [soundImportanceValue, setSoundImportanceValue] = useState<number>(0);

    useMemo(() => {
        quizService.getQuizList().then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
        if ( userProfileData?.value ) {
            console.log(userProfileData);
            const wellnessQuizConsciousness = userProfileData.value.find((item: any) => {
                return item.type === 'wellness' && item.quizAlias === 'soundConsciousness';
            });
            console.log(wellnessQuizConsciousness);
            if ( wellnessQuizConsciousness ) {
                setSoundConsciousnessValue(wellnessQuizConsciousness.value);
            }
            
            const soundQuizImportanceValue = userProfileData.value.find((item: any) => {
                return item.type === 'wellness' && item.quizAlias === 'soundImportance';
            });
            console.log(soundQuizImportanceValue);
            if ( soundQuizImportanceValue ) {
                setSoundImportanceValue(soundQuizImportanceValue.value);
            }



        }

    }, [userProfileData]);

    const getSliderValues = (quizValue: number) => {

        let sliderValues = [0, 0];

        if ( quizValue ) {
            switch(quizValue) {
                case 1:
                    sliderValues = [0, 25];
                break;
                case 2:
                    sliderValues = [25, 50];
                break;
                case 3:
                    sliderValues = [50, 75];
                break;
                case 4:
                    sliderValues = [75, 100];
                break;
            }
        }

        return sliderValues;
    }

    const sliderConsciousnessClasses = classNames({
        'preferences-slider': true
    });

    const sliderConsciousnessCardClasses = classNames({
        'consciousness-slider': true,
        'card-block': true,
        'active': soundConsciousnessValue ? true : false
    });

    const sliderImportanceClasses = classNames({
        'preferences-slider': true
    });
    const sliderImportanceCardClasses = classNames({
        'importance-slider': true,
        'card-block': true,
        'active': soundImportanceValue ? true : false
    });

    return(
        <Box className="wellness-dashboard">
            <Box className="page-header">
                <NavLink to="/profile" className="icon-right-arrow"></NavLink>
                <h1>
                    My wellness
                </h1>
            </Box>

            <Card className={sliderConsciousnessCardClasses}>
                <h5>Sound Consciousness</h5>
                <Typography>
                    This shows how readily I notice sounds in my environment. 
                </Typography>

                <Box className={sliderConsciousnessClasses}>
                    <Slider value={getSliderValues(soundConsciousnessValue)} aria-labelledby="continuous-slider" />
                    <div className="slider-label">
                        <span>Indifferent</span>
                        <span>Observant</span>
                    </div>
                </Box>

                <NavLink to="/profile/quiz-sound-consciousness">
                    Discover mine
                </NavLink>
            </Card>

            <Card className={sliderImportanceCardClasses}>
                <h5>Sound Importance</h5>
                <Typography>
                    This shows the importance I place on sound when making decisions e.g. choosing a bar. 
                </Typography>

                <Box className={sliderImportanceClasses}>
                    <Slider value={getSliderValues(soundImportanceValue)} aria-labelledby="continuous-slider" />
                    <div className="slider-label">
                        <span>Optional</span>
                        <span>Priority</span>
                    </div>
                </Box>

                <NavLink to="/profile/quiz-sound-importance">
                    Discover mine
                </NavLink>
            </Card>

            <RecommendationLink {...{quizAnswered: soundConsciousnessValue || soundImportanceValue ? true : false}} />
        </Box>
    )
}

export default WellnessDashboard;