
import { QuizInterface } from "../models/quiz.model";
import { RestQuizService } from "./rest/rest-quiz.service";

export class QuizService {

    private restQuizService = new RestQuizService();

    constructor() {}

    getQuizList(): Promise<QuizInterface[]> {
        return this.restQuizService.find().then((res: any) => {
            return res;
        }).catch((err: any) => {
            console.log(err);
            return err;
        });
    }
}
