import { Box, Button, Container, Grid, Hidden, MobileStepper, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { QuizContext } from '../../../../context/quiz.context';
import { SnackbarContext } from '../../../../context/snackbar.context';
import { UserProfileContext } from '../../../../context/user-profile.context';
import { UserContext } from '../../../../context/user.context';
import { QuizInterface, QuizQuestionInterface, QuizQuestionMultiInterface } from '../../../../models/quiz.model';
import { QuizAnswers, UserProfileDataInterface } from '../../../../models/user-profile-data.model';
import { UserProfileDataService } from '../../../../services/user-profile-data.service';
import './wellness-quiz-importance.scss';
import { createQuiz, getQuizType } from '../../../../axios/api';
import swal from 'sweetalert';

function WellnessQuizImportance(props: any) {


    const { handleSubmit } = useForm();
    const userProfileDataService = new UserProfileDataService();
    const [userData, setUserData] = React.useContext(UserContext);
    const [quizList, setQuizList] = React.useContext(QuizContext);

    const [userProfileData, setUserProfileData] = React.useContext(UserProfileContext);
    const [snackbarData, setSnackbarData] = React.useContext(SnackbarContext);

    const [activeStep, setActiveStep] = React.useState(0);

    const [wellnessQuizQuestions, setWellnessQuizQuestions] = useState([
        {
            name: "hardToSocialise",
            label: "I avoid venues that are hard to work or socialise in",
            value: -1
        },
        {
            name: "avoidLoudSounds",
            label: "I actively protect myself from harsh & loud sounds",
            value: -1
        },
        {
            name: "chooseEnvironment",
            label: "I tailor and choose my environments to have background sounds I prefer (includes preferring silence)",
            value: -1
        }
    ]);

    useEffect(() => {
        if (localStorage.getItem("newAuth")) {
            const data = {
                "quizAlias": "soundImportance"
            }
            getQuizType(data, {
                onSuccess: (res) => {
                    if (Object.keys(res.data).length !== 0) {
                        setWellnessQuizQuestions(res.data.answers);
                    }
                },
                onError: (res) => {
                    error();
                }
            })
        }
    }, [])

    // useMemo(() => {
    //     if (userProfileData?.quiz) {

    //         const wellnessQuiz = userProfileData.quiz.find((item: QuizAnswers) => {
    //             return item.type === 'wellness' && item.quizAlias === 'soundImportance';
    //         });

    //         if (wellnessQuiz) {
    //             const wellnessAnswers: QuizQuestionInterface[] = wellnessQuiz.answers.map((item: QuizQuestionInterface) => {
    //                 return {
    //                     name: item.name,
    //                     label: item.label,
    //                     value: typeof item.value === 'number' ? item.value : 0
    //                 }
    //             });

    //             const newWellnessQuizValues = [...wellnessQuizQuestions];
    //             newWellnessQuizValues.forEach((q: QuizQuestionInterface) => {

    //                 const val = wellnessAnswers.find(v => v.name === q.name);
    //                 console.log(val);
    //                 if (val) {
    //                     q.value = val.value;
    //                 }

    //             });

    //             setWellnessQuizQuestions(newWellnessQuizValues);
    //         }
    //     }
    // }, [userProfileData]);
    /*
        useEffect(() => {
            if ( quizList ) {
                console.log(quizList);
                const quiz: QuizInterface = quizList.find((item: QuizInterface) => item.alias === 'soundConsciousness');
    
                if ( quiz ) {
                    setWellnessQuizQuestions(quiz.questions);
                }
            }
        }, [quizList]);
    */

    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    const answerQuestion = (quizQuestion: QuizQuestionInterface, val: number) => {

        const newValues = [...wellnessQuizQuestions];

        const answer: QuizQuestionInterface = newValues.find((item) => item.name === quizQuestion.name) ?? { name: "", label: "", value: 0 };
        answer.value = val;

        setWellnessQuizQuestions(newValues);

    };

    const quizUpdate = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'Quiz updated successfully'
        };

        setSnackbarData(snackbar);
    }
    const error = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Error'
        };
        setSnackbarData(snackbar);
    }

    const checkValue = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Atleast answer one question to proceed'
        };

        setSnackbarData(snackbar);
    }

    const saveQuiz = () => {

        const userQuiz = {
            "type": "wellness",
            "quizAlias": "soundImportance",
            "answers": wellnessQuizQuestions
        }

        const unansweredQuestions = wellnessQuizQuestions.find((item) => item.value !== -1);
        if (unansweredQuestions != null) {
            for (var i = 0; i < wellnessQuizQuestions.length; i++) {
                if (wellnessQuizQuestions[i].value === -1) {
                    wellnessQuizQuestions[i].value = 0;
                }
            }
            createQuiz(userQuiz, {
                onSuccess: () => {
                    if (localStorage.getItem("newAuth")) {
                        quizUpdate();
                        props.history.push("/profile")
                    }
                },
                onError: (res) => {
                    error();
                }
            })
            console.log("Clicked");
        }
        else {
            checkValue();
        }

    }

    // else {

    //     const countedValues = wellnessQuizQuestions.reduce((val, item) => item.value + val, 0);
    //     let wellnessQuizCountedValues = 0;

    //     if (countedValues <= 4) {
    //         wellnessQuizCountedValues = 1;
    //     }

    //     if (countedValues >= 5 && countedValues <= 8) {
    //         wellnessQuizCountedValues = 2;
    //     }

    //     if (countedValues >= 9 && countedValues <= 13) {
    //         wellnessQuizCountedValues = 3;
    //     }

    //     if (countedValues > 13) {
    //         wellnessQuizCountedValues = 4;
    //     }

    // const updatedUserProfileData: any = {
    //     userId: userData._id,
    //     quiz: [{
    //         type: "wellness",
    //         quizAlias: 'soundImportance',
    //         answers: wellnessQuizQuestions
    //     }],
    //     value: [{
    //         type: "wellness",
    //         quizAlias: 'soundImportance',
    //         value: wellnessQuizCountedValues
    //     }]
    // };

    //     if (userProfileData) {
    //         updatedUserProfileData._id = userProfileData._id;

    //         if (userProfileData.quiz) {
    //             const techQuizes = userProfileData.quiz.filter((item: QuizAnswers) => {
    //                 return item.type === 'tech';
    //             });
    //             if (techQuizes) {
    //                 updatedUserProfileData.quiz.push(...techQuizes);
    //             }

    //             const wellnessQuizes = userProfileData.quiz.filter((item: QuizAnswers) => {
    //                 return item.type === 'wellness' && item.quizAlias !== 'soundImportance';
    //             });
    //             if (wellnessQuizes) {
    //                 updatedUserProfileData.quiz.push(...wellnessQuizes);
    //             }
    //         }

    //         if (userProfileData.value) {
    //             const techQuizesValues = userProfileData.value.filter((item: QuizAnswers) => {
    //                 return item.type === 'tech';
    //             });
    //             if (techQuizesValues) {
    //                 updatedUserProfileData.value.push(...techQuizesValues);
    //             }

    //             const wellnessQuizesValues = userProfileData.value.filter((item: QuizAnswers) => {
    //                 return item.type === 'wellness' && item.quizAlias !== 'soundImportance';
    //             });
    //             if (wellnessQuizesValues) {
    //                 updatedUserProfileData.value.push(...wellnessQuizesValues);
    //             }
    //         }

    //         userProfileDataService.updateUserProfileData(updatedUserProfileData).then((res: any) => {

    //             if (res.type === 'FeathersError') {
    //                 const snackbar = {
    //                     isOpen: true,
    //                     snackbarType: 'error',
    //                     snackbarText: res.message ?? 'Server error. Please try later!'
    //                 };

    //                 setSnackbarData(snackbar);
    //             } else {

    //                 const snackbar = {
    //                     isOpen: true,
    //                     snackbarType: 'success',
    //                     snackbarText: 'Quiz answers were updated.'
    //                 };

    //                 setSnackbarData(snackbar);

    //                 setUserProfileData(res);

    //                 props.history.push('/profile');
    //             }
    //         }).catch((err: any) => {
    //             console.log(err);
    //         });
    //     } else {

    //         userProfileDataService.createUserProfileData(updatedUserProfileData).then((res: any) => {

    //             if (res.type === 'FeathersError') {

    //                 const snackbar = {
    //                     isOpen: true,
    //                     snackbarType: 'error',
    //                     snackbarText: res.message ?? 'Server error. Please try later!'
    //                 };

    //                 setSnackbarData(snackbar);
    //             } else {

    //                 const snackbar = {
    //                     isOpen: true,
    //                     snackbarType: 'success',
    //                     snackbarText: 'Quiz answers were updated.'
    //                 };

    //                 setSnackbarData(snackbar);

    //                 setUserProfileData(res);

    //                 props.history.push('/profile');
    //             }
    //         }).catch((err: any) => {
    //             console.log(err);
    //         });
    //     }

    // }


    function QuizQuestion(quizQuestion: QuizQuestionInterface) {
        return (
            <Box className="quiz-question" mt={3} key={quizQuestion.name}>
                <Box mb={2}>
                    <Typography variant="h6" component="h2" color="primary" className="font-weight-700">{quizQuestion.label}</Typography>
                </Box>

                <Box className="answer-range-block" textAlign="center">
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Button variant="outlined" className={`btn-ans btn-ans-warning ${quizQuestion.value === 1 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 1)}></Button>
                            <Typography variant="body1" className="text-warning-main">Disagree</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="outlined" className={`btn-ans btn-ans-warning ${quizQuestion.value === 2 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 2)}></Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="outlined" className={`btn-ans btn-ans-secondary ${quizQuestion.value === 3 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 3)}></Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="outlined" className={`btn-ans btn-ans-secondary ${quizQuestion.value === 4 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 4)}></Button>
                            <Typography variant="body1" color="secondary">Agree</Typography>
                        </Grid>
                    </Grid>
                </Box>

                {/* <Box className="answer-range-block">
                    <Box>
                        <Button variant="outlined" className={`${quizQuestion.value === 1 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 1)}></Button>
                        <span>
                            Disagree
                        </span>
                    </Box>

                    <Button variant="outlined" className={`${quizQuestion.value === 2 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 2)}></Button>
                    <Button variant="outlined" className={`${quizQuestion.value === 3 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 3)}></Button>
                    <Button variant="outlined" className={`${quizQuestion.value === 4 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 4)}></Button>

                    <Box>
                        <Button variant="outlined" className={`${quizQuestion.value === 5 ? "active" : ""}`} onClick={() => answerQuestion(quizQuestion, 5)}></Button>
                        <span>
                            Agree
                        </span>
                    </Box>
                </Box> */}
            </Box>
        )
    }

    // const StepperContent = () => {

    //     // if (activeStep === 0) {
    //     return (
    //         <Box className="quiz-screen" mb={3}>
    //             <Box my={2}>
    //                 <Typography variant="h4" component="h1" color="primary" className="font-weight-700">How well do these statements describe you?</Typography>
    //             </Box>
    //             <Box>
    //                 <Typography variant="body1" color="primary">
    //                     These questions will determine your sound importance level by looking into how you’ve made sound related decisions in the past.
    //                 </Typography>
    //             </Box>

    //             {/* <h1>How well do these statements describe you?</h1>
    //             <Typography>
    //                 These questions will determine your sound importance level by looking into how you’ve made sound related decisions in the past.
    //             </Typography> */}

    //             <QuizQuestion {...wellnessQuizQuestions[0]} />
    //             <QuizQuestion {...wellnessQuizQuestions[1]} />
    //             <QuizQuestion {...wellnessQuizQuestions[2]} />
    //         </Box>
    //     );
    //     // }

    //     // if (activeStep === 1) {
    //     //     return (
    //     //         <Box className="quiz-screen">

    //     //             <QuizQuestion {...wellnessQuizQuestions[2]} />
    //     //         </Box>
    //     //     );
    //     // }

    //     return null;
    // }

    return (
        <Container maxWidth="xs" className="quiz">
            {/* // <Grid container alignContent="center" justifyContent="center" className="quiz-container">
        //     <Grid item xs={12} md={8} className="wellness-quiz wellness-quiz-importance"> */}

            {/* <Hidden xsDown> */}
            <form className="signup-form" onSubmit={handleSubmit(saveQuiz)}>
                <Box my={2}>
                    <Typography variant="h4" component="h1" color="primary" className="font-weight-700">Find your hearing preference type</Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="body1" color="primary">
                        Hearing wellness is a term we use to describe how the world sounds to you & what your preferences for it are.
                        Take the quiz below to find your hearing preference type and how you compare to others who've taken it too!
                    </Typography>
                </Box>
                <Typography variant="body1" color="primary">
                    This quiz is based off of the work by Clinical Psycologist Dr. Therese Verkerke Cash,
                    designed to see how different people react to certain sounds.
                </Typography>
                <Box mb={3}>
                    {wellnessQuizQuestions.map((quizQuestion) => (
                        <QuizQuestion key={quizQuestion.name} {...quizQuestion} />
                    ))}
                </Box>
                {/* </Hidden> */}

                {/* <Hidden smUp> */}

                {/* <MobileStepper
                        variant="progress"
                        steps={2}
                        position="static"
                        activeStep={activeStep}
                        className="quiz-stepper"
                        nextButton={
                            activeStep === 1 ?
                                <Button className="btn-gradient" onClick={saveQuiz}>Submit</Button> :
                                <span onClick={handleNext} className="icon-right-arrow"></span>
                        }
                        backButton={
                            activeStep === 0 ?
                                <NavLink to="/profile" className="icon-left-arrow"></NavLink> :
                                <span onClick={handleBack} className="icon-left-arrow"></span>
                        }
                    /> */}

                {/* <StepperContent /> */}
                <Box pb={3} display="flex" justifyContent="end">
                    <Button type="submit" variant="contained" className="btn-root rounded-pill btn-gradient text-white" size="large" disableElevation >Submit</Button>
                </Box>

                {/* </Hidden> */}
                {/* //     </Grid>
        // </Grid> */}
            </form>
        </Container>
    );
}

export default WellnessQuizImportance;
