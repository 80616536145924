
import React, { useMemo, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
    Box,
    Button,
    Card,
    CardContent,
    Slider,
    Typography
} from '@material-ui/core';
import { UserProfileContext } from '../../../../context/user-profile.context';
import classNames from 'classnames';
import { QuizService } from '../../../../services/quiz.service';
import "./tab-quiz-profile.scss";
import { getQuizType } from "../../../../axios/api";

function RecommendationLink({ quizAnswered }: any) {

    if (quizAnswered === true) {
        return (
            <a href="/venues" className="btn-link">
                {/* <Button className="btn-gradient">See recommendations</Button> */}
            </a>
        );
    }

    return null;
}

function TabQuizProfile() {

    const quizService = new QuizService();

    const [userProfileData, setUserProfileData] = React.useContext(UserProfileContext);

    const [wellnessQuiz, setWellnessQuiz] = useState<boolean>(false);
    const [soundConsciousnessValue, setSoundConsciousnessValue] = useState<number>(0);
    const [soundImportanceValue, setSoundImportanceValue] = useState<number>(0);

    // let array: number[] = [];
    // let newArray: number[] = [];
    let i=0;
    useEffect(() => {
        const array: number[] = [];
        const data = {
            "quizAlias": "soundConsciousness"
        }
        getQuizType(data, {
            onSuccess: (res) => {
                if (Object.keys(res.data).length !== 0) {
                    // console.log('res-----',res.data);
                    res.data.answers.map((item: { value: number; }) => {
                        if(item.value!==0){
                            array.push(1);
                        }
                    })
                    // console.log('array',array);
                    const valueSum = (res.data.answers.length + array.length)/res.data.answers.length;
                    if(valueSum === 2){
                        setSoundConsciousnessValue(valueSum + 2);
                    }else if(valueSum === 1.8 || valueSum === 1.9 ){
                        setSoundConsciousnessValue(Math.round(valueSum)+1);
                    }else {
                        setSoundConsciousnessValue(Math.round(valueSum));
                    }
                }
            },
            onError: (res) => {
                // error();
            }
        })            
    },[])

    useEffect(() => {
        const newArray: number[] = [];
        const data = {
            "quizAlias": "soundImportance"
        }
        getQuizType(data, {
            onSuccess: (res) => {
                if (Object.keys(res.data).length !== 0) {
                    // console.log('res-----',res.data);
                    res.data.answers.map((item: { value: number; }) => {
                        if(item.value!==0){
                            newArray.push(1);
                        }
                    })
                    // console.log('array',newArray);
                    const valueSum = (res.data.answers.length + newArray.length)/res.data.answers.length;
                    setSoundImportanceValue(Math.ceil(newArray.length+1));
                }
            },
            onError: (res) => {
                // error();
            }
        })            
    },[])

    useMemo(() => {
        quizService.getQuizList().then(res => {
            // console.log(res);
        }).catch(err => {
            console.log(err);
        });
        if (userProfileData?.value) {
            console.log(userProfileData);
            const wellnessQuizConsciousness = userProfileData.value.find((item: any) => {
                return item.type === 'wellness' && item.quizAlias === 'soundConsciousness';
            });
            console.log(wellnessQuizConsciousness);
            if (wellnessQuizConsciousness) {
                // setSoundConsciousnessValue(wellnessQuizConsciousness.value);
            }

            const soundQuizImportanceValue = userProfileData.value.find((item: any) => {
                return item.type === 'wellness' && item.quizAlias === 'soundImportance';
            });
            console.log(soundQuizImportanceValue);
            if (soundQuizImportanceValue) {
                // setSoundImportanceValue(soundQuizImportanceValue.value);
            }



        }

    }, [userProfileData]);

    const getSliderValues = (quizValue: number) => {

        let sliderValues = [0, 0];

        if (quizValue) {
            switch (quizValue) {
                case 1:
                    sliderValues = [0, 25];
                    break;
                case 2:
                    sliderValues = [25, 50];
                    break;
                case 3:
                    sliderValues = [50, 75];
                    break;
                case 4:
                    sliderValues = [75, 100];
                    break;
            }
        }

        return sliderValues;
    }

    const sliderConsciousnessClasses = classNames({
        'preferences-slider': true
    });

    const sliderConsciousnessCardClasses = classNames({
        'consciousness-slider': true,
        'card-block': true,
        'active': soundConsciousnessValue ? true : false
    });

    const sliderImportanceClasses = classNames({
        'preferences-slider': true
    });
    const sliderImportanceCardClasses = classNames({
        'importance-slider': true,
        'card-block': true,
        'active': soundImportanceValue ? true : false
    });

    return (
        <Box className="quiz-profile">
            <Box mb={2} textAlign="center">
                <Card className={sliderConsciousnessCardClasses}>
                    <CardContent>
                        <Typography variant="h5" className="font-weight-500">Sound Consciousness</Typography>
                        <Typography variant="body1">This shows how readily I notice sounds in my environment.</Typography>

                        <Box className={sliderConsciousnessClasses}>
                            <Slider value={getSliderValues(soundConsciousnessValue)} aria-labelledby="continuous-slider" />
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="body1" component="span" className="text-uppercase" color="textSecondary">Indifferent</Typography>
                                <Typography variant="body1" component="span" className="text-uppercase" color="textSecondary">Observant</Typography>
                            </Box>
                        </Box>

                        <NavLink to="/profile/quiz-sound-consciousness">
                            <Typography component="span" className="text-underline" color="secondary">Discover mine</Typography>
                        </NavLink>
                    </CardContent>
                </Card>
            </Box>
            <Box textAlign="center">
                <Card className={sliderImportanceCardClasses}>
                    <CardContent>
                        <Typography variant="h5" className="font-weight-500">Sound Importance</Typography>
                        <Typography variant="body1">This shows the importance I place on sound when making decisions e.g. choosing a bar.</Typography>

                        <Box className={sliderImportanceClasses}>
                            <Slider value={getSliderValues(soundImportanceValue)} aria-labelledby="continuous-slider" />
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="body1" component="span" className="text-uppercase" color="textSecondary">Optional</Typography>
                                <Typography variant="body1" component="span" className="text-uppercase" color="textSecondary">Priority</Typography>
                            </Box>
                        </Box>

                        <NavLink to="/profile/quiz-sound-importance">
                            <Typography component="span" className="text-underline" color="secondary">Discover mine</Typography>
                        </NavLink>
                    </CardContent>
                </Card>
            </Box>

            <RecommendationLink {...{ quizAnswered: soundConsciousnessValue || soundImportanceValue ? true : false }} />
        </Box>
    );
}

export default TabQuizProfile;