import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './app';
import { BrowserRouter } from 'react-router-dom';
import UserStore from './context/user.context';
import AuthStore from './context/auth.context';
import SnackbarStore from './context/snackbar.context';
import UserProfileStore from './context/user-profile.context';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider } from '@material-ui/core';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { inherits } from 'util';

const stripePromise = loadStripe("pk_live_u8lZf0yRF17Y355mQkWZdyuh");

const getBasename = () => {
    return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const theme = createTheme({
    typography: {
        fontFamily: 'Noyh',
        h1: {
            fontSize: '64px',
            lineHeight: 1.2,
        },
        h2: {
            fontSize: '48px',
            lineHeight: 1.2,
        },
        h3: {
            fontSize: '40px',
            lineHeight: 1.2,
        },
        h4: {
            fontSize: '32px',
            lineHeight: 1.2,
        },
        h5: {
            fontSize: '24px',
            lineHeight: 1.2,
        },
        h6: {
            fontSize: '20px',
            lineHeight: 1.2,
        },
        subtitle1: {
            fontSize: '20px',
            lineHeight: 1.4,
        },
        subtitle2: {
            fontSize: '18px',
            lineHeight: 1.4,
        },
        body1: {
            fontSize: '18px',
            lineHeight: 1.4,
        },
        body2: {
            fontSize: '16px',
            lineHeight: 1.4,
        },
        button: {
            fontSize: '16px',
            lineHeight: 1.4,
        },
        caption: {
            fontSize: '16px',
            lineHeight: 1.4,
        },
        overline: {
            fontSize: '14px',
            lineHeight: 1.4,
        },
    },
    palette: {
        primary: {
            light: '#4f779f',
            main: '#235687',
            dark: '#183c5e',
            contrastText: '#fff',
        },
        secondary: {
            light: '#c745d3',
            main: '#b917c9',
            dark: '#81108c',
            contrastText: '#fff',
        },
        warning: {
            light: '#eeaf55',
            main: '#ea9b2b',
            dark: '#a36c1e',
            contrastText: '#000',
        },
        text: {
            primary: '#235687',
        },
    },
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter basename={getBasename()}>
            <SnackbarStore>
                <AuthStore>
                    <UserStore>
                        <UserProfileStore>
                            <Elements stripe={stripePromise}>
                                <App />
                            </Elements>
                        </UserProfileStore>
                    </UserStore>
                </AuthStore>
            </SnackbarStore>
        </BrowserRouter>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
