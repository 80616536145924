import { LoginCredentialsInterface, LoginRequestInterface } from "../models/auth.model";
import FeathersService from "./rest/feathers.service";

export class AuthService {

    private feathersService = FeathersService;


    getAuthenticatedUser(): Promise<any>  {
        return this.feathersService.authenticate().then((res: any) => {
            return res.user;
        }).catch((error: any) => {
            console.log(error);
        });
    }

    reAuthenticate(): Promise<any>  {
        return this.feathersService.reAuthenticate().then((res: any) => {
            return res.user;
        }).catch((error: any) => {
            console.log(error);
        });
    }

    login( credentials: LoginCredentialsInterface ): Promise<any> {

        const requestLoginCredentials: LoginRequestInterface = {
            ...credentials,
            "strategy": 'local'
        };

        return this.feathersService.authenticate(requestLoginCredentials).then((response: any) => {
            return response;
        }).catch((error: any) => {
            console.log(error);
            return error;
        });
    }

    googleLogin( googleData: any ): Promise<any> {

        const g = {
            email: googleData.profileObj.email,
            familyName: googleData.profileObj.familyName,
            givenName: googleData.profileObj.givenName,
            googleId: googleData.profileObj.googleId,
            imageUrl: googleData.profileObj.imageUrl,
            name:googleData.profileObj.name,
            accessToken: googleData.accessToken,
            tokenId: googleData.tokenId,
            // ...googleData,
            strategy: "google"
        };

        console.log(g);
        
        return this.feathersService.authenticate(g).then((response: any) => {
            return response;
        }).catch((error: any) => {
            console.log(error);
            return error;
        })
    }
 
}

