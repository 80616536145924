import { FormControl, FormHelperText, InputLabel, TextField, Button, Grid, Typography, Box, Container } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useHistory } from 'react-router-dom';
import swal from "sweetalert"
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { forgotPassword } from '../../../../axios/api';
import { SnackbarContext } from '../../../../context/snackbar.context';
import './login.scss';

const ForgotPassword = (props: any) => {

    const forgetSchema = Yup.object().shape({
        email: Yup.string().required("Please enter email").email('Please enter correct email.')
    });

    const [email, setEmail] = useState('');
    const { register, errors, handleSubmit } = useForm({
        resolver: yupResolver(forgetSchema)
    });
    const history = useHistory();

    const [, setSnackbarData] = React.useContext(SnackbarContext);

    const successOtp = (message : any) => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: message
        };

        setSnackbarData(snackbar);
    }

    const emailUnregistered = (message : any) => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: message
        };

        setSnackbarData(snackbar);
    }

    const handleChange = () => {

        const postdata = {
            "email": email
        }
        forgotPassword(postdata, {
            onError: (res) => {
                emailUnregistered(res.data.message)
            },
            onSuccess: (res) => {
                successOtp(res.data.success);
                localStorage.setItem("email", email);
                props.history.push(`/auth/resetpassword`, postdata);
            }
        })
    }
    
    return (
        <>
            <Box p={3} className="auth-form-header">
                <span className="icon-mumbli-logo-2"></span>
                <Typography variant="h2" component="div">Forgot Password</Typography>
            </Box>
            <Container maxWidth="xs" className="signup-content">
                <Box py={4}>
                    <form className="signup-form">
                        <Box mb={4}>
                            <Typography variant="h4" component="h1" className="font-weight-700" color="primary">Forgot Password</Typography>
                        </Box>
                        <Grid>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="email">Email</InputLabel>
                                        <TextField variant="outlined" id="email" aria-describedby="email-helper-text" name="email" inputRef={register} onChange={(data) => setEmail(data.target.value)} />
                                        <FormHelperText id="email-helper-text"></FormHelperText>
                                        <p>{errors.email?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <Button variant="contained" className="btn-root rounded-pill btn-gradient w-100 text-white text-capitalize" size="large" disableElevation type="button" onClick={handleSubmit(handleChange)}>Submit</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <Button variant="contained" className="btn-root rounded-pill w-100 text-capitalize" size="large" disableElevation onClick={history.goBack}>Back</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Link to="/auth/login" className="pure-link">
                                    Have an account? Login
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Container>
        </>
    )
}

export default ForgotPassword