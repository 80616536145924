import './venue-form.scss';
import { Box, Button, Container, FormControl, FormControlLabel, FormGroup, RadioGroup, Radio, makeStyles, MobileStepper, TextField, Typography, Select, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlaceInterface } from '../../../../models/place.model';
import placeSchema from '../../../../models/place.schema';
import { PlaceService } from '../../../../services/place.service';
import { SnackbarContext } from '../../../../context/snackbar.context';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { VenueContext } from '../../../../context/venue.context';
import { GoogleService } from '../../../../services/google.service';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { addNewPlaces } from '../../../../axios/api';
import { NavLink } from 'react-router-dom';
import { AnyMxRecord } from 'node:dns';
import resolve from 'resolve';

const placeService = new PlaceService();
const googleService = new GoogleService();
const useStyles = makeStyles({
    root: {
        width: '100%',
        justifyContent: 'space-between',
        margin: '0 0 16px 0',
    },

});
function VenueForm(props: any) {
    const classes = useStyles();
    const [snackbarData, setSnackbarData] = React.useContext(SnackbarContext);
    const [activeStep, setActiveStep] = React.useState(0);
    const [googleVenue, setGoogleVenue] = React.useState('');
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [placeId, setPlaceId] = useState("");
    const [venueContextData, setVenueContextData] = React.useContext(VenueContext);
    const checking = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'Venue is successfully added'
        };

        setSnackbarData(snackbar);
    }
    const checkingError = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Invalid Data'
        };

        setSnackbarData(snackbar);
    }
    const error = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Please select all options'
        };
        setSnackbarData(snackbar);
    }
    useEffect(() => {
        clearAllLocalData();
    }, [])

    const clearAllLocalData = () => {
        localStorage.removeItem("status");
        localStorage.removeItem("venueTypeText");
        localStorage.removeItem("conversationTypeText");
        localStorage.removeItem("name");
        localStorage.removeItem("address");
        localStorage.removeItem("venueType");
        localStorage.removeItem("crowdedness");
        localStorage.removeItem("vibe");
        localStorage.removeItem("conversation");
        localStorage.removeItem("lat");
        localStorage.removeItem("lng");
    }
    const [venueData, setVenueData] = React.useState({
        "name": "",
        "address": "",
        "placeId": "",
        "status": "",
        "location": {
            "coordinates": [
                0,
                0,
            ]
        },
        "venueType": "",
        "venueTypeText": "",
        "vibe": "",
        "crowdedness": "",
        "conversation": "",
        "conversationText": ""
    });


    const handleCheckboxTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVenueData({ ...venueData, venueType: event.target.value });
        localStorage.setItem("venueType", event.target.value);
        // console.log("venueType ", event.target.value);
        localStorage.removeItem("venueTypeText");
    };

    const handleCrowdednessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVenueData({ ...venueData, crowdedness: event.target.value });
        localStorage.setItem("crowdedness", event.target.value);
        // console.log("crowdedness", event.target.value);
    };

    const handleAmbienceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVenueData({ ...venueData, vibe: event.target.value });
        localStorage.setItem("vibe", event.target.value);
        // console.log("vibe", event.target.value);
    };

    const handleConversationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVenueData({ ...venueData, conversation: event.target.value });
        localStorage.setItem("conversation", event.target.value);
        // console.log("conversation", event.target.value);
        localStorage.removeItem("conversationTypeText");
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const { formState: { errors } } = useForm({
        resolver: yupResolver(placeSchema)
    });


    const saveVenue = () => {
        const localname = localStorage.getItem("name");
        const localaddress = localStorage.getItem("address");
        const status = localStorage.getItem("status");
        const venueText = localStorage.getItem("venueTypeText");
        const conversationText = localStorage.getItem("conversationTypeText");
        const typeVenue = localStorage.getItem("venueType");
        const crowd = localStorage.getItem("crowdedness");
        const ambience = localStorage.getItem("vibe");
        const talk = localStorage.getItem("conversation");


        let venue;
        if (venueText !== null && conversationText !== null && status !== null) {
            venue =
            {
                "name": localaddress,
                "address": localaddress,
                "placeId": placeId,
                "status": status,
                "location": {
                    "coordinates": [
                        lng !== 0 ? lng : 0,
                        lat !== 0 ? lat : 0,
                    ]
                },
                "venueType": typeVenue,
                "venueTypeText": venueText,
                "vibe": ambience,
                "crowdedness": crowd,
                "conversation": talk,
                "conversationText": conversationText,
            };
            // console.log("Both available:", venueText, conversationText)
        }
        if (venueText !== null && conversationText === null && status !== null) {
            venue =
            {
                "name": localaddress,
                "address": localaddress,
                "placeId": placeId,
                "status": status,
                "location": {
                    "coordinates": [
                        lng !== 0 ? lng : 0,
                        lat !== 0 ? lat : 0,
                    ]
                },
                "venueType": typeVenue,
                "venueTypeText": venueText,
                "vibe": ambience,
                "crowdedness": crowd,
                "conversation": talk
            };
            // console.log("VenueText available:", venueText, conversationText)
        }
        if (venueText === null && conversationText !== null && status !== null) {
            venue =
            {
                "name": localaddress,
                "address": localaddress,
                "placeId": placeId,
                "status": status,
                "location": {
                    "coordinates": [
                        lng !== 0 ? lng : 0,
                        lat !== 0 ? lat : 0,
                    ]
                },
                "venueType": typeVenue,
                "vibe": ambience,
                "crowdedness": crowd,
                "conversation": talk,
                "conversationText": conversationText,
            };
            // console.log("ConversationText available:", venueText, conversationText)
        }
        if (venueText === null && conversationText === null && status !== null) {
            venue =
            {
                "name": localaddress,
                "address": localaddress,
                "placeId": placeId,
                "status": status,
                "location": {
                    "coordinates": [
                        lng !== 0 ? lng : 0,
                        lat !== 0 ? lat : 0,
                    ]
                },
                "venueType": typeVenue,
                "vibe": ambience,
                "crowdedness": crowd,
                "conversation": talk,
            };
            // console.log("None available:", venueText, conversationText)
        }
        if (venueText !== null && conversationText !== null && status !== null) {
            venue =
            {
                "name": localaddress,
                "address": localaddress,
                "placeId": placeId,
                "status": status,
                "location": {
                    "coordinates": [
                        lng !== 0 ? lng : 0,
                        lat !== 0 ? lat : 0,
                    ]
                },
                "venueType": typeVenue,
                "venueTypeText": venueText,
                "vibe": ambience,
                "crowdedness": crowd,
                "conversation": talk,
                "conversationText": conversationText,
            };
            // console.log("Both available:", venueText, conversationText)
        }
        if (venueText !== null && conversationText === null && status !== null) {
            venue =
            {
                "name": localaddress,
                "address": localaddress,
                "placeId": placeId,
                "status": status,
                "location": {
                    "coordinates": [
                        lng !== 0 ? lng : 0,
                        lat !== 0 ? lat : 0,
                    ]
                },
                "venueType": typeVenue,
                "venueTypeText": venueText,
                "vibe": ambience,
                "crowdedness": crowd,
                "conversation": talk
            };
            // console.log("VenueText available:", venueText, conversationText)
        }
        if (venueText === null && conversationText !== null && status !== null) {
            venue =
            {
                "name": localaddress,
                "address": localaddress,
                "placeId": placeId,
                "status": status,
                "location": {
                    "coordinates": [
                        lng !== 0 ? lng : 0,
                        lat !== 0 ? lat : 0,
                    ]
                },
                "venueType": typeVenue,
                "vibe": ambience,
                "crowdedness": crowd,
                "conversation": talk,
                "conversationText": conversationText,
            };
            // console.log("ConversationText available:", venueText, conversationText)
        }
        if (venueText === null && conversationText === null && status !== null) {
            venue =
            {
                "name": localaddress,
                "address": localaddress,
                "placeId": placeId,
                "status": status,
                "location": {
                    "coordinates": [
                        lng !== 0 ? lng : 0,
                        lat !== 0 ? lat : 0,
                    ]
                },
                "venueType": typeVenue,
                "vibe": ambience,
                "crowdedness": crowd,
                "conversation": talk,
            };
            // console.log("None available:", venueText, conversationText)
        }
        if (venueText !== null && conversationText !== null && status === null) {
            venue =
            {
                "name": localaddress,
                "address": localaddress,
                "placeId": placeId,
                "location": {
                    "coordinates": [
                        lng !== 0 ? lng : 0,
                        lat !== 0 ? lat : 0,
                    ]
                },
                "venueType": typeVenue,
                "venueTypeText": venueText,
                "vibe": ambience,
                "crowdedness": crowd,
                "conversation": talk,
                "conversationText": conversationText,
            };
            // console.log("Both available:", venueText, conversationText)
        }
        if (venueText !== null && conversationText === null && status === null) {
            venue =
            {
                "name": localaddress,
                "address": localaddress,
                "placeId": placeId,
                "location": {
                    "coordinates": [
                        lng !== 0 ? lng : 0,
                        lat !== 0 ? lat : 0,
                    ]
                },
                "venueType": typeVenue,
                "venueTypeText": venueText,
                "vibe": ambience,
                "crowdedness": crowd,
                "conversation": talk
            };
            // console.log("VenueText available:", venueText, conversationText)
        }
        if (venueText === null && conversationText !== null && status === null) {
            venue =
            {
                "name": localaddress,
                "address": localaddress,
                "placeId": placeId,
                "location": {
                    "coordinates": [
                        lng !== 0 ? lng : 0,
                        lat !== 0 ? lat : 0,
                    ]
                },
                "venueType": typeVenue,
                "vibe": ambience,
                "crowdedness": crowd,
                "conversation": talk,
                "conversationText": conversationText,
            };
            // console.log("ConversationText available:", venueText, conversationText)
        }
        if (venueText === null && conversationText === null && status === null) {
            venue =
            {
                "name": localaddress,
                "address": localaddress,
                "placeId": placeId,
                "location": {
                    "coordinates": [
                        lng !== 0 ? lng : 0,
                        lat !== 0 ? lat : 0,
                    ]
                },
                "venueType": typeVenue,
                "vibe": ambience,
                "crowdedness": crowd,
                "conversation": talk,
            };
            // console.log("None available:", venueText, conversationText)
        }
        //API CALL
        if (localname !== null && localaddress !== null && lng !== null && lat !== null && typeVenue !== null && ambience !== null && crowd !== null && talk !== null) {
            addNewPlaces(venue, {
                onSuccess: (res) => {
                    clearAllLocalData();
                    checking();
                    props.history.push("/profile")
                    // console.log("Success:", res)
                },
                onError: (res) => {
                    clearAllLocalData();
                    checkingError();
                    // console.log("Error:", res)
                }
            })
        }
        else {
            error();
        }
        // placeService.createVenue(venueData).then((res: any | PlaceInterface) => {

        //     if (res?.type === 'FeathersError') {

        //         const snackbar = {
        //             isOpen: true,
        //             snackbarType: 'error',
        //             snackbarText: res.message ?? 'Server error. Please try later!'
        //         };

        //         setSnackbarData(snackbar);
        //     } else {

        //         const activeVenue: PlaceInterface = res;

        //         setVenueContextData(activeVenue);

        //         const snackbar = {
        //             isOpen: true,
        //             snackbarType: 'success',
        //             snackbarText: 'Venue created successfully.'
        //         };

        //         setSnackbarData(snackbar);

        //         props.history.push('/venues');
        //     }
        // }).catch((err: any) => {
        //     console.log(err);
        //     const snackbar = {
        //         isOpen: true,
        //         snackbarType: 'error',
        //         snackbarText: 'Server error. Please try later!'
        //     };

        //     setSnackbarData(snackbar);
        // });

    }

    // const toDataURL = (imgSrc: string, callback: any) => {
    //     const img = new Image();
    //     img.crossOrigin = "Use-Credentials";

    //     const imageLoaded = (r: any) => {
    //         const canvas: any = document.createElement('CANVAS');
    //         const ctx = canvas.getContext('2d');
    //         ctx.drawImage(img, 0, 0);

    //         const dataURL: string = ctx.canvas.toDataURL('base64');

    //         callback(dataURL);
    //     };

    //     img.addEventListener("load", imageLoaded, false);
    //     img.src = imgSrc;

    //     if (img.complete && img.complete === undefined) {
    //         img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
    //         img.src = imgSrc;
    //     }

    // }

    // const setVenuePicture = async (placeId: string): Promise<string> => {

    //     return await googleService.getPlaceData(placeId).then((res: any): string => {

    //         if (res?.photos?.length > 0) {

    //             const imgSrc = res.photos[0].getUrl({
    //                 maxHeight: 400,
    //                 maxWidth: 400
    //             });

    //             return imgSrc;

    //         }

    //         return '';
    //     });

    // }
    const changeVenueName = async (placeGoogleData: any) => {

        // console.log("Places:", placeGoogleData);
        // console.log("Place ID:", placeGoogleData.value?.place_id);
        setGoogleVenue(placeGoogleData);

        setPlaceId(placeGoogleData.value?.place_id);
        // const imgSrc: string = await setVenuePicture(placeId);

        const data = placeGoogleData.value?.structured_formatting;
        const lablelAddress = placeGoogleData?.label;
        if (data) {
            // setVenueData({
            //     ...venueData,
            //     name: data.main_text,
            //     address: data.secondary_text,
            // });
            geocodeByAddress(data.secondary_text).then(results => getLatLng(results[0])).then(latLng => {
                // console.log('lat', latLng.lat);
                // console.log('lng', latLng.lng);
                setLat(latLng.lat);
                setLng(latLng.lng);
                // localStorage.setItem("lat", { latitude: latLng.lat });
                // localStorage.setItem("lng", { longitude: latLng.lng.toString() });
                // setVenueData({
                //     ...venueData,
                //     // location: {
                //     //     coordinates: [latLng.lat, latLng.lng]
                //     // },
                //     name: data.main_text,
                //     address: data.secondary_text,
                // });
            });

            // geocodeByAddress(data.main_text).then((results) => {
            //     console.log("Lat and Long:",results[0])
            //     getLatLng(results[0]).then(({ lat, lng }) => {
            //         setVenueData({
            //             ...venueData,
            //             location: {
            //                 coordinates: [lat.toString(), lng.toString()]
            //             },
            //             name: data.main_text,
            //             address: data.secondary_text,
            //         });
            //     });

            // });
            localStorage.setItem("name", data.main_text);
            // setVenueName(data.main_text);
            localStorage.setItem("address", lablelAddress);
        }

    }

    const VenueFormStep = () => {
        // const [venueName, setVenueName] = useState(localStorage.getItem("name") !== null ? localStorage.getItem("name") : '');
        // const [description, setDescription] = useState("");

        // const handleDescription = (e: any) => {
        //     setDescription(e.target.value);
        //     localStorage.setItem("description", e.target.value)
        //     console.log("desc", e.target.value)
        // }

        // const handleVenueName = (e: any) => {
        //     setVenueName(e.target.value);
        //     localStorage.setItem("name", e.target.value)
        //     console.log("name", e.target.value)
        // }
        const [status, setStatus] = useState();
        const [venueTypeText, setVenueTypeText] = useState("");
        const [conversationTypeText, setConversationTypeText] = useState("");

        const handleStatus = (e: any) => {
            if (e.target.value !== undefined) {
                setStatus(e.target.value);
                localStorage.setItem("status", e.target.value);
            }
            if (e.target.value === undefined) {
                localStorage.setItem("status", "");
            }
            // console.log("Status", e.target.value);
        }

        const handleVenueTypeText = (e: any) => {
            setVenueTypeText(e.target.value);
            if (e.target.value !== null) {
                localStorage.setItem("venueTypeText", e.target.value);
            }
            else {
                localStorage.setItem("venueTypeText", "");
            }
        }

        const handleConversationTypeText = (e: any) => {
            setConversationTypeText(e.target.value);
            localStorage.setItem("conversationTypeText", e.target.value);
        }


        if (activeStep === 0) {
            return (
                <>
                    <Box my={2}>
                        <Typography variant="h4" component="h1" color="primary" className="font-weight-700">Add venue</Typography>
                    </Box>
                    <Box mb={2}>
                        <Typography variant="body1" color="primary">
                            Know a venue that’s great for conversation? Loving the experience and ambience? Add it to our map so others can find it too!
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="h6" component="span" color="primary">
                            Venue Address
                        </Typography>
                        <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_C_KEY}
                            selectProps={{
                                value: googleVenue,
                                onChange: changeVenueName,
                            }}
                        />
                        <p className="error">{errors.name?.message}</p>
                    </Box>

                    {/* <Box mb={3}>
                        <FormControl fullWidth>
                            <Typography variant="h6" component="label" color="primary">Venue Name</Typography>
                            <TextField variant="outlined" id="venuename" aria-describedby="venuename-helper-text" type="text" value={venueName} defaultValue={venueName} placeholder="Enter Venue Name" onChange={e => handleVenueName(e)} />
                        </FormControl>
                    </Box> */}

                    <Box mb={3}>
                        {/* <Typography variant="h6" component="span" color="primary">
                            Description
                        </Typography> */}
                        {/* <FormControl fullWidth>
                            <TextField variant="outlined" id="description" aria-describedby="description-helper-text" type="text" value={description} placeholder="Description" onChange={e => handleDescription(e)} />
                        </FormControl> */}
                        {/* <FormControl fullWidth>
                            <Typography variant="h6" component="label" color="primary">Description</Typography>
                            <TextField variant="outlined" id="description" aria-describedby="description-helper-text" type="text" value={description} placeholder="Description" onChange={e => handleDescription(e)} />
                            <p >{errors.password?.message}</p>
                        </FormControl> */}
                        <FormControl fullWidth>
                            <Typography variant="h6" component="label" color="primary">Status</Typography>
                            <Select
                                value={status}
                                onChange={handleStatus}
                                displayEmpty
                            // variant="outlined"
                            // inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem>Select status</MenuItem>
                                <MenuItem value="">Unverified</MenuItem>
                                <MenuItem value="verified">Verified</MenuItem>
                                <MenuItem value="certified">Certified</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                </>

            )
        }

        if (activeStep === 1) {

            return (
                <>
                    <FormControl component="fieldset" className="d-block">
                        <RadioGroup aria-label="venueType" name="venueType" value={venueData.venueType} onChange={handleCheckboxTypeChange}>
                            <Box my={2}>
                                <Typography variant="h5" component="h2" color="primary" className="font-weight-700">Type of venue</Typography>
                                <Typography variant="body1" component="span" color="primary" >What kind of venue is this?</Typography>
                            </Box>

                            <FormGroup>

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="cafe" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Cafe</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block"></Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="restaurant" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Restaurant</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block"></Typography>
                                        </>
                                    }
                                    labelPlacement="start" />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="bar" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Bar or Pub</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block"></Typography>
                                        </>
                                    }
                                    labelPlacement="start" />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="other" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Other</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block"></Typography>
                                        </>
                                    }
                                    labelPlacement="start" />
                                {venueData.venueType === "other" &&
                                    <FormControl fullWidth>
                                        <TextField variant="outlined" id="venueTypeText" aria-describedby="venueTypeText-helper-text" type="text" name="venueTypeText" value={venueTypeText !== null ? venueTypeText : ""} placeholder="Mention any venue type" onChange={e => handleVenueTypeText(e)} />
                                        {/* <p className="error">{errors.description?.message}</p> */}
                                    </FormControl>}
                            </FormGroup>
                            {/* <FormGroup>
                            <FormControl>
                                <InputLabel id="demo-mutiple-checkbox-label">Other</InputLabel>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={venueData.venueType}
                                    onChange={handleSelectTypeChange}
                                    input={<Input />}
                                    renderValue={(selected) => (selected as string[]).join(', ')}
                                >
                                    {Object.keys(initialVenueTypeState).map((item: string) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox checked={venueData.venueType?.includes(item)} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormGroup> */}
                        </RadioGroup>
                    </FormControl>
                </>
            )
        }

        if (activeStep === 2) {
            return (
                <>
                    <FormControl component="fieldset" className="d-block">
                        <RadioGroup aria-label="crowdedness" name="crowdedness" value={venueData.crowdedness} onChange={handleCrowdednessChange}>
                            <Box my={2}>
                                <Typography variant="h5" component="h2" color="primary" className="font-weight-700">Crowdedness</Typography>
                                <Typography variant="body1" component="span" color="primary" >How many people are around?</Typography>
                            </Box>

                            <FormGroup>
                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="not_crowded" />
                                    } label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Not crowded</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Lots of seats available</Typography>
                                        </>
                                    } labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="not_too_crowded" />
                                    } label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Not too crowded</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Some seats available</Typography>
                                        </>
                                    } labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="crowded" />
                                    } label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Crowded</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Limited seating</Typography>
                                        </>
                                    } labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="very_crowded" />
                                    } label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Very crowded</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">No seats left</Typography>
                                        </>
                                    } labelPlacement="start"
                                />
                            </FormGroup>
                        </RadioGroup>
                    </FormControl >
                </>
            )
        }

        if (activeStep === 3) {
            return (
                <>
                    <FormControl component="fieldset" className="d-block">
                        <RadioGroup aria-label="vibe" name="vibe" value={venueData.vibe} onChange={handleAmbienceChange}>
                            <Box my={2}>
                                <Typography variant="h5" component="h2" color="primary" className="font-weight-700">Ambience</Typography>
                                <Typography variant="body1" component="span" color="primary" >What is it like inside the venue?</Typography>
                            </Box>

                            <FormGroup>
                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="calm" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Calm</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Soft music and chatter, it feels cosy, warm</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="buzzy" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Buzzy</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Upbeat music and louder chatter, it feels lively</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="energetic" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Energetic</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Loud music and/or voices, it feels a bit wild but fun</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="overwhelming" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Overwhelming</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">So much music and chatter that it feels more stressful than fun</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </RadioGroup>
                    </FormControl>
                </>
            )
        }

        if (activeStep === 4) {
            return (
                <>
                    <FormControl component="fieldset" className="d-block">
                        <RadioGroup aria-label="conversation" name="conversation" value={venueData.conversation} onChange={handleConversationChange}>
                            <Box my={2}>
                                <Typography variant="h5" component="h2" color="primary" className="font-weight-700">Conversation</Typography>
                                <Typography variant="body1" component="span" color="primary" >How easy is it to have a conversation?</Typography>
                            </Box>
                            <FormGroup>
                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="easy" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Easy</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Very smooth conversation</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="some_difficulty" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Some difficulty</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Occasionally have to repeat yourself</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="hard" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Hard</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Speaking with a raised voice, hard to hear, repeating yourself often</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="very_hard" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Very hard</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Have to shout, consistently need to repeat yourself</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="other" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Other</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Mention others</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />
                                {venueData.conversation === "other" &&
                                    <FormControl fullWidth>
                                        <TextField variant="outlined" id="conversationTypeText" aria-describedby="conversationTypeText-helper-text" type="text" name="conversationTypeText" value={conversationTypeText !== null ? conversationTypeText : ""} placeholder="Mention any conversation typr" onChange={e => handleConversationTypeText(e)} />
                                        {/* <p className="error">{errors.description?.message}</p> */}
                                    </FormControl>}
                            </FormGroup>
                        </RadioGroup>
                    </FormControl>
                </>
            )
        }

        return null;
    }

    return (
        <Box className="venue-form">
            <Container maxWidth="xs">
                <Box>
                    <MobileStepper
                        variant="progress"
                        steps={5}
                        position="static"
                        activeStep={activeStep}
                        className="venue-form-stepper"
                        nextButton
                        backButton
                    />
                </Box>
                <Box flexGrow={1}>
                    <form >
                        <VenueFormStep />
                    </form>
                </Box>
                <Box display="flex" justifyContent={activeStep === 0 ? 'flex-end' : 'space-between'} alignItems="center">
                    {activeStep === 0 ? null : (
                        <IconButton aria-label="delete" onClick={handleBack} color="primary">
                            <MdKeyboardArrowLeft size="32" />
                        </IconButton>
                    )}
                    {activeStep === 4 ? (
                        <Button type="button" variant="contained" className="btn-root rounded-pill btn-gradient text-white text-capitalize" size="large" disableElevation onClick={saveVenue}>Submit</Button>
                    ) : (
                        <IconButton aria-label="delete" onClick={handleNext} color="primary">
                            <MdKeyboardArrowRight size="32" />
                        </IconButton>
                    )}
                </Box>
            </Container>
        </Box>
    )
}

export default VenueForm;
