import * as Yup from "yup";

export const signupSchema1 = Yup.object().shape({
  firstName: Yup.string().required("Please enter your first name"),
  lastName: Yup.string().required("Please enter last name"),
  email: Yup.string()
    .required("Please enter your email")
    .email("Please enter valid email"),
  password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*/])[a-zA-Z0-9!@#$%^&*/]{8,}$/,
      function () {
        return "Minimum 8 characters long and must contain one upper case character, one lowecase character, one special character and one number";
      }
    ),
  confirmPassword: Yup.string()
    .required("Confirm your password")
    .oneOf([Yup.ref("password"), null], "Password should match."),
});

export const signupSchema2 = Yup.object().shape({
  birthDate: Yup.date().required("Please enter date of birth"),
  technology: Yup.number(),
  postCode: Yup.string().required("Please enter postcode"),
});
