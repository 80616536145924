import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../components/privat-route/privat-route';
import TechPreferences from './components/tech-preferences/tech-preferences';
import TechFeed from './components/tech-feed/tech-feed';
import TechQuiz from './components/tech-quiz/tech-quiz';
import React, { useEffect, useState } from 'react';
import { UserService } from '../../services/user.service';
import { UserProfileDataService } from '../../services/user-profile-data.service';
import { UserContext } from '../../context/user.context';
import { UserProfileDataInterface } from '../../models/user-profile-data.model';
import './technology.scss';

function TechnologyPage() {
    let {path} = useRouteMatch();
/*
    const userService = new UserService();
    const userProfileDataService = new UserProfileDataService();

    const [userData, setUserData] = React.useContext(UserContext);

    const [userProfileTechQuiz, setUserProfileTechQuiz] = useState<UserProfileDataInterface>();
    
    <PrivateRoute path={`${path}/preferences`} component={TechPreferences} />
            <PrivateRoute path={`${path}/quiz`} component={TechQuiz} />
*/
    return (
        <Switch>
            <PrivateRoute path={`${path}/`} component={TechFeed} />
        </Switch>
    );
}

export default TechnologyPage;
