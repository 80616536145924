import * as yup from "yup";

const placeSchema = yup.object().shape({
    address: yup.string(),
    description: yup.string(),
    // freeWifi: boolean;
    location: yup.array(),
    // materials: PlaceMaterials;
    name: yup.string().required(),
    // numberOfPlugs: number;
    // photo: PlacePhoto;
    // recordings: any[];
    state: yup.string(),
    venueType: yup.array(),
    crowdedness: yup.string(),
    ambience: yup.string()
});

export default placeSchema;