import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { UserContext } from "../../../../context/user.context";
import { QuizValues, UserProfileDataInterface } from "../../../../models/user-profile-data.model";
import { UserProfileDataService } from "../../../../services/user-profile-data.service";
import './my-tech-preferences.scss';

function TechUseText(props: any) {

    const value: number = props.value;

    if ( value === 1 ) {
        return (
            <p>
                Continuous use for personal entertainment e.g. music/podcasts while doing physical or stationary activities on your own
            </p>
        );
    }

    if ( value === 2 ) {
        return (
            <p>
                Continuous use for communication e.g. calling or speaking to friends, family, or colleagues
            </p>
        );
    }

    if ( value === 3 ) {
        return (
            <p>
                Infrequent use for personal entertainment e.g. music/podcasts while doing physical or stationary activities on your own
            </p>
        );
    }

    if ( value === 4 ) {
        return (
            <p>
                Infrequent use for communication e.g. calling or speaking to friends, family, or colleagues
            </p>
        );
    }

    return null;
}

function TechChooseText(props: any) {

    const value: number = props.value;

    if ( value === 1 ) {
        return (
            <p>
                Simple decision making when looking for tech used for personal entertainment e.g. deciding mainly based on price or compatibility with your hearing profile
            </p>
        );
    }

    if ( value === 2 ) {
        return (
            <p>
                Simple decision making when looking for tech used for communication e.g. deciding mainly based on price or compatibility with your hearing profile
            </p>
        );
    }

    if ( value === 3 ) {
        return (
            <p>
                Thorough decision making when looking for tech used for personal entertainment e.g. deciding mainly based on a set of device features or user reviews
            </p>
        );
    }

    if ( value === 4 ) {
        return (
            <p>
                Thorough decision making when looking for tech used for communication e.g. deciding mainly based on a set of device features or user reviews
            </p>
        );
    }

    return null;
}

function MyTechPreferences() {

    const [userData, setUserData] = React.useContext(UserContext);
    const userProfileDataService = new UserProfileDataService();
    const [techUseValue, setTechUseValue] = useState<number>(0);
    const [techChooseValue, setTechChooseValue] = useState<number>(0);

    useEffect(() => {
        if ( userData ) {
            
            userProfileDataService.getUserProfileData(userData._id).then((userProfile: UserProfileDataInterface) => {
                
                if ( userProfile.value ) {
                    const techUse: QuizValues | undefined = userProfile.value.find((item: any) => item.type === 'techUse');
                    if ( techUse ) {
                        setTechUseValue(techUse.value);
                    }

                    const techChoose: QuizValues | undefined  = userProfile.value.find((item: any) => item.type === 'techChoose');
                    if ( techChoose) {
                        setTechChooseValue(techChoose.value);
                    }
                }
            }).catch((err: any) => {
                console.log(err);
            });
        }
    });

    return (
        <Box className="my-tech-preferences">
            <h1>
                <NavLink to="/profile" className="icon-right-arrow"></NavLink>
                My ear tech preferences
            </h1>

            <p>
            This is an initial summary of how you choose & use ear tech.
            </p>

            <h3>What we mean by “ear tech”</h3>
            <p>
                Ear tech is a term we use to describe devices like headphones, earphones, earpods, or hearing devices. If it's a device that goes in or over your ears, it's a piece of ear tech.
            </p>

            <h3>Tech use type</h3>
            <TechUseText value={techUseValue} />
            
            <h3>Tech choosing type</h3>
            <TechChooseText value={techChooseValue} />
        </Box>
    );
}

export default MyTechPreferences;