import { UserProfileDataInterface } from "../models/user-profile-data.model";
import FeathersService from "./rest/feathers.service";

export class UserProfileDataService {

    private userProfileDataRestService = FeathersService.service('users/profile-data');

    constructor() {}

    getUserProfileData( userId: string ): Promise<UserProfileDataInterface> {
        return this.userProfileDataRestService.find({
            query: {
                userId: userId
            }
        }).then((res: any) => {
            return res[0];
        }).catch((err: any) => {
            console.log(err);
            return err;
        });
    }

    createUserProfileData( userProfileData: UserProfileDataInterface ): Promise<UserProfileDataInterface> {
        return this.userProfileDataRestService.create(userProfileData).then((res: any) => {
            return res;
        }).catch((err: any) => {
            console.log(err);
            return err;
        });
    }

    updateUserProfileData( userProfileData: UserProfileDataInterface ): Promise<UserProfileDataInterface> {
        return this.userProfileDataRestService.patch(userProfileData._id, userProfileData).then((res: any) => {
            return res;
        }).catch((err: any) => {
            console.log(err);
            return err;
        });
    }
}
