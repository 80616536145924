import { Loader } from '@googlemaps/js-api-loader'

export class RestGoogleService {

    loader: any;
    googleApi: any;

    constructor() {
        // this.loadGoogleApi();
        if ( !this.loader ) {
            this.loader = new Loader({
                apiKey: 'AIzaSyCA-h9M-1IGT4C_SDGd-XNbeNO6q3law2I',
                libraries: ['places']
            });
        }
    }

    async loadGoogleLoader() {
        return this.loader.load().then((google: any) => {
            return google;
        });
    }
    
    async getPlaceData(placeId: string, fields: string[]) {
        const map = new google.maps.Map(document.createElement('div'));

        this.googleApi = await this.loader.load();

        if ( fields.length === 0 ) {
            fields.push(...[
                'name',
                'address_component',
                'opening_hours',
                'geometry',
                'types',
                'icon',
                'photo'
            ]);
        }

        const placeService = new this.googleApi.maps.places.PlacesService(map);

        const params: any = {
            placeId,
            fields
        };

        return new Promise(function(resolve, reject){
            placeService.getDetails(params, (res: any, status: any) => {
                console.log(res);
                console.log(status);
    
                resolve(res);
            });
        });
        
    }

}