import moment from "moment";
import React, { useState } from "react";
import { UserInterface } from "../models/user.model";

const initUserState: UserInterface | null = null;

type UserContextType = {
    userData: UserInterface | null;
    setUserData: (value: UserInterface | null) => void;
}

export const UserContext = React.createContext<any>(initUserState);

const UserStore = ({children}: any) => {
    const [userData, setUserData] = useState(initUserState);
    
    return (
        <UserContext.Provider value={[userData, setUserData]}>
            {children}
        </UserContext.Provider>
    );
};

export default UserStore;