import './layout-nav.scss';
import { NavLink } from 'react-router-dom';
import { Box, Button, IconButton, Container, MenuItem, Menu, Hidden, Typography, makeStyles } from '@material-ui/core';
import { BsCaretDownFill } from 'react-icons/bs';
import { AuthContext } from '../../context/auth.context';
import React, { useEffect, useMemo, useState } from 'react';
import { UserProfileContext } from '../../context/user-profile.context';
import { UserContext } from '../../context/user.context';
import { FaRegUserCircle, FaMapMarkerAlt } from "react-icons/fa";
import { MdAddCircleOutline, MdAdminPanelSettings, MdMoreVert } from "react-icons/md";
import { IconContext } from 'react-icons/lib';


const selectStyle = makeStyles({
    dropDownStyle: {
        marginTop: "2.5rem",
    },
});
function LayoutNav() {
    const menuClass = selectStyle();
    const ITEM_HEIGHT = 50;
    const [authToken] = React.useContext(AuthContext);
    const [userData] = React.useContext(UserContext);
    const [userProfileData] = React.useContext(UserProfileContext);
    const [techQuiz, setTechQuiz] = useState<boolean | null>(null);
    const [wellnessQuiz, setWellnessQuiz] = useState<boolean | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        // console.log("Click", event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useMemo(() => {

        if (userProfileData?.quiz) {
            const techQuiz = userProfileData.quiz.find((item: any) => item.type === 'tech');
            if (techQuiz) {
                setTechQuiz(true);
            }

            const wellnessQuiz = userProfileData.quiz.find((item: any) => item.type === 'wellness');
            if (wellnessQuiz) {
                setWellnessQuiz(true);
            }
        }

    }, [userProfileData]);


    if (localStorage.getItem("newAuth")) {
        // if (localStorage.getItem("permissions") === "admin") {
        //     return (
        //         <>
        //             <Box p={3} className="auth-form-header">
        //                 <span className="icon-mumbli-logo-2"></span>
        //             </Box>
        //             <Container maxWidth="md">
        //                 <Box display="flex">
        //                     <Box flexGrow={1}>
        //                         <NavLink to="/profile" activeClassName="active text-secondary-main">
        //                             <Box component="div" textAlign="center" p={2}>
        //                                 <FaRegUserCircle size="32" className="mx-auto d-block" />
        //                                 <Box component="span" display="block" className="font-family-thin">Profile</Box>
        //                             </Box>
        //                         </NavLink>
        //                     </Box>

        //                     <Box flexGrow={1}>
        //                         <NavLink to={userData?.preferences?.length > 0 ? "/venues" : "/venues-for-me"} activeClassName="active text-secondary-main">
        //                             <Box component="div" textAlign="center" p={2}>
        //                                 <FaMapMarkerAlt size="32" className="mx-auto d-block" />
        //                                 <Box component="span" display="block" className="font-family-thin">Venues</Box>
        //                             </Box>
        //                         </NavLink>
        //                     </Box>
        //                 </Box>
        //             </Container>
        //         </>
        //     )
        // }
        // else {
        return (
            <>
                <Box p={3} className="auth-form-header post-auth-header">
                    <span className="icon-mumbli-logo-2"></span>
                </Box>
                <Container maxWidth={localStorage.getItem("permissions") === "user" ? 'md' : 'sm'} >
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <NavLink to="/profile" activeClassName="active text-secondary-main">
                                <Box component="div" textAlign="center" p={2}>
                                    <FaRegUserCircle size="32" className="mx-auto d-block" />
                                    <Box component="span" display="block" className="font-family-thin">Profile</Box>
                                </Box>
                            </NavLink>
                        </Box>
                        {localStorage.getItem("permissions") === "admin" ? (
                            <Box flexGrow={1}>
                                <NavLink to="/venues/add" activeClassName="active text-secondary-main">
                                    <Box component="div" textAlign="center" p={2}>
                                        <MdAddCircleOutline size="32" className="mx-auto d-block" />
                                        <Box component="span" display="block" className="font-family-thin">Add</Box>
                                    </Box>
                                </NavLink>
                            </Box>
                        ) : null}
                        <Box flexGrow={1}>
                            <NavLink to={userData?.preferences?.length > 0 ? "/venues" : "/venues-for-me"} activeClassName="active text-secondary-main">
                                <Box component="div" textAlign="center" p={2}>
                                    <FaMapMarkerAlt size="32" className="mx-auto d-block" />
                                    <Box component="span" display="block" className="font-family-thin">Venues</Box>
                                </Box>
                            </NavLink>
                        </Box>
                        {localStorage.getItem("permissions") === "admin" ? (
                            <Box flexGrow={1}>
                                <Box component="div" textAlign="center" p={2} >
                                    <Box component="span" display="block" className="font-family-thin active text-secondary-main">
                                        <NavLink to="#" isActive={(_, location) => {
                                            const adminLinks = ['/venues-management']
                                            return Boolean(adminLinks.find((v) => v === location.pathname))
                                        }}
                                            onClick={(e) => e.preventDefault()} activeClassName="active text-secondary-main">
                                            <MdAdminPanelSettings size="32" className="mx-auto d-block" />
                                            <Box onClick={handleClick}>
                                                Admin
                                                <BsCaretDownFill />
                                            </Box>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    "aria-labelledby": "long-button"
                                                }}
                                                className={menuClass.dropDownStyle}
                                                anchorEl={anchorEl}
                                                open={open}
                                                disableAutoFocusItem
                                                onClose={handleClose}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: "17ch"
                                                    }
                                                }}
                                            >
                                                <NavLink to="/venues-management" activeClassName="active text-secondary-main">
                                                    <MenuItem onClick={handleClose}>Venues Management</MenuItem>
                                                </NavLink>
                                                {/* <NavLink to="/user-management" activeClassName="active text-secondary-main">
                                                    <MenuItem>User Management</MenuItem>
                                                </NavLink> */}
                                            </Menu>
                                        </NavLink>
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                </Container>
            </>
        )
        // }
    }
    return (<Box />);
    // return (getComponent());
}

/*
<NavLink to={wellnessQuiz ? "/wellness" : "/wellness-for-me"} activeClassName="active">
    <span className="icon-peace-of-mind"></span>
    Wellness
</NavLink>
<NavLink to={techQuiz ? "/technology" : "/products-for-me"} activeClassName="active">
    <span className="icon-headphone"></span>
    Technology
</NavLink>
<NavLink to="/logout" activeClassName="active">
    <span className="icon-logout"></span>
    Logout
</NavLink>
*/

export default LayoutNav;