import { Box, Button, Container, Grid, InputLabel, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { SnackbarContext } from '../../../../context/snackbar.context';
import { resetOtp, otpVerify } from '../../../../axios/api';
import './signup.scss';

const OtpVerify = (props: any) => {

    const [otp, setOtp] = useState('');

    const [, setSnackbarData] = React.useContext(SnackbarContext);

    const verifyOtp = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'Account created successfully'
        };

        setSnackbarData(snackbar);
    }

    const otpError = (message: any) => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: message
        };

        setSnackbarData(snackbar);
    }

    const successOtp = (message: any) => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: message
        };

        setSnackbarData(snackbar);
    }

    const handleChange = () => {
        const data = {
            "email": localStorage.getItem("email"),
            "otp": parseInt(otp)
        }
        otpVerify(data, {
            onError: (res) => {
                otpError(res.data.message)
                // console.log(res)
            },
            onSuccess: (res) => {
                verifyOtp()
                localStorage.removeItem("firstName");
                localStorage.removeItem("lastName");
                localStorage.removeItem("email");
                localStorage.removeItem("password");
                props.history.push(`/auth/login`)
            }
        })
    }


    const resend = () => {
        const resendData = {
            "email": localStorage.getItem("email")
        }
        resetOtp(resendData, {
            onError: (res) => {
                otpError(res.data.message)
            },
            onSuccess: (res) => {
                successOtp(res.data.success)
            }
        })
    }

    return (
        <>
            <Box p={3} className="auth-form-header">
                <span className="icon-mumbli-logo-2"></span>
                <Typography variant="h2" component="div">Login</Typography>
            </Box>
            <Container maxWidth="xs" className="signup-content">
                <Box py={4}>
                    <Grid>
                        <form className="signup-form">
                            <Box mb={4}>
                                <Typography variant="h4" component="h1" className="font-weight-700" color="primary">Verify your Email address</Typography>
                            </Box>

                            <Grid item xs={12}>
                                <Box mb={4} textAlign="center" display={{ xs: 'flex' }} alignItems={{ xs: 'center' }} flexDirection={{ xs: 'column' }}>
                                    <InputLabel>Please enter the 4 digit OTP that has been sent to your email address</InputLabel>
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        aria-describedby="otp"
                                        className="otp"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <Button variant="contained" className="btn-root rounded-pill btn-gradient w-100 text-white text-capitalize" size="large" disableElevation type="button" onClick={handleChange}>Verify</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={4}>
                                    <Button variant="contained" className="btn-root rounded-pill w-100 text-capitalize" size="large" disableElevation type="button" onClick={resend}> Resend OTP</Button>
                                </Box>
                            </Grid>
                        </form>
                    </Grid>
                </Box>
            </Container >
        </>
    )
}
export default OtpVerify
