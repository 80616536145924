import { Box, Button, Checkbox, Grid, Hidden } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SnackbarContext } from '../../../../context/snackbar.context';
import { UserContext } from '../../../../context/user.context';
import { QuizQuestionInterface, QuizQuestionMultiInterface } from '../../../../models/quiz.model';
import { UserService } from '../../../../services/user.service';
import { UserProfileDataService } from '../../../../services/user-profile-data.service';
import './tech-quiz.scss';
import { QuizAnswers, QuizValues, UserProfileDataInterface } from '../../../../models/user-profile-data.model';
import { NavLink, useRouteMatch } from 'react-router-dom';

function TechQuiz(props: any) {

    let {path, url} = useRouteMatch();

    const userService = new UserService();
    const userProfileDataService = new UserProfileDataService();

    const [userData, setUserData] = React.useContext(UserContext);
    const [snackbarData, setSnackbarData] = React.useContext(SnackbarContext);

    const [userProfileTechQuiz, setUserProfileTechQuiz] = useState<UserProfileDataInterface>();

    const [techQuizValues, setTechQuizValues] = useState<QuizQuestionInterface[]>([
        {
            name: "techPeriodicity",
            label: "How often do you use ear technology? e.q. earphones, headphones, AirPods, hearing devices",
            value: 0
        },
        {
            name: "techReason",
            label: "What are the main reasons you use your ear tech?",
            value: 0
        },
        {
            name: "techFactors",
            label: "Which features are the most important factors in your choice of ear tech?",
            value: 0
        },
        {
            name: "techOutcome",
            label: "What is the main outcome that you would like to achieve when choosing and using a piece of ear tech?",
            value: 0
        }
    ]);

    useEffect(() => {
        if ( !userProfileTechQuiz && userData ) {
            userProfileDataService.getUserProfileData(userData._id).then((userProfile: UserProfileDataInterface) => {

                if ( userProfile ) {
                    setUserProfileTechQuiz(userProfile);
                    if ( userProfile.quiz ) {
                        const techQuiz = userProfile.quiz.find((item: any) => item.type === 'tech');
                        if ( techQuiz ) {
                            const techAnswers: QuizQuestionInterface[] = techQuiz.answers.map(item => {
                                return {
                                    name: item.name,
                                    label: item.label,
                                    value: typeof item.value === 'number' ? item.value : 0
                                }
                            });
                            
                            const newTechQuizValues = [...techQuizValues];
                            newTechQuizValues.forEach((q: QuizQuestionInterface) => {
                                
                                    const val = techAnswers.find(v => v.name === q.name);
                                    if ( val ) {
                                        q.value = val.value;
                                    }
                                
                            });

                            setTechQuizValues(newTechQuizValues);
                        }
                    }
                }
            }).catch((err: any) => {
                console.log(err);
            });
        }
    });

    const answerQuestion = (quizQuestion: any, val: number) => {
        
        const newValues = [...techQuizValues];

        const answer: QuizQuestionInterface = newValues.find((item) => item.name === quizQuestion.name) ?? {name:"", label: "", value: 0};
        answer.value = val;

        setTechQuizValues(newValues);
    };

    const saveQuiz = () => {

        const unansweredQuestions = techQuizValues.find((item) => item.value === 0);
        if ( unansweredQuestions ) {
            const snackbar = {
                isOpen: true,
                snackbarType: 'warning',
                snackbarText: 'To proceed You need to answer all quiz questions first.'
            };

            setSnackbarData(snackbar);
        } else {

            let techQuizComputedUseValues = 4;

            if ( 
                (techQuizValues[0].value === 1 && techQuizValues[1].value === 1) || 
                (techQuizValues[0].value === 1 && techQuizValues[1].value === 2) || 
                (techQuizValues[0].value === 2 && techQuizValues[1].value === 1) || 
                (techQuizValues[0].value === 2 && techQuizValues[1].value === 2)
            ) {
                techQuizComputedUseValues = 1;
            }

            if ( 
                (techQuizValues[0].value === 1 && techQuizValues[1].value === 3) || 
                (techQuizValues[0].value === 1 && techQuizValues[1].value === 4) || 
                (techQuizValues[0].value === 2 && techQuizValues[1].value === 3) || 
                (techQuizValues[0].value === 2 && techQuizValues[1].value === 4)
            ) {
                techQuizComputedUseValues = 2;
            }

            if ( 
                (techQuizValues[0].value === 3 && techQuizValues[1].value === 1) || 
                (techQuizValues[0].value === 3 && techQuizValues[1].value === 2) || 
                (techQuizValues[0].value === 4 && techQuizValues[1].value === 1) || 
                (techQuizValues[0].value === 4 && techQuizValues[1].value === 2)
            ) {
                techQuizComputedUseValues = 3;
            }

            let techQuizComputedChooseValues = 4;

            if ( 
                (techQuizValues[2].value === 1 && techQuizValues[3].value === 1) || 
                (techQuizValues[2].value === 1 && techQuizValues[3].value === 2) || 
                (techQuizValues[2].value === 2 && techQuizValues[3].value === 1) || 
                (techQuizValues[2].value === 2 && techQuizValues[3].value === 2)
            ) {
                console.log('1 value!!!');
                techQuizComputedChooseValues = 1;
            }

            if ( 
                (techQuizValues[2].value === 1 && techQuizValues[3].value === 3) || 
                (techQuizValues[2].value === 1 && techQuizValues[3].value === 4) || 
                (techQuizValues[2].value === 2 && techQuizValues[3].value === 3) || 
                (techQuizValues[2].value === 2 && techQuizValues[3].value === 4)
            ) {
                techQuizComputedChooseValues = 2;
            }

            if ( 
                (techQuizValues[2].value === 3 && techQuizValues[3].value === 1) || 
                (techQuizValues[2].value === 3 && techQuizValues[3].value === 2) || 
                (techQuizValues[2].value === 4 && techQuizValues[3].value === 1) || 
                (techQuizValues[2].value === 4 && techQuizValues[3].value === 2)
            ) {
                techQuizComputedChooseValues = 3;
            }

            const updatedUserProfileData: any = {
                userId: userData._id,
                quiz: [{
                    type: "tech",
                    quizAlias: 'techUse',
                    answers: techQuizValues
                }],
                value: [
                    {
                        type: "techUse",
                        quizAlias: 'techUse',
                        value: techQuizComputedUseValues
                    },
                    {
                        type: "techChoose",
                        quizAlias: 'techChoose',
                        value: techQuizComputedChooseValues
                    }
                ]
            }; // {...userData};
            // updatedUserProfileData.techQuiz = techQuizValues;

            if (userProfileTechQuiz) {

                updatedUserProfileData._id = userProfileTechQuiz._id;

                if ( userProfileTechQuiz.quiz ) {
                    /*
                    const wellnessQuiz = userProfileTechQuiz.quiz.find(item => item.type === 'wellness');
                    if ( wellnessQuiz ) {
                        updatedUserProfileData.quiz.push(wellnessQuiz);
                    }
                    */
                    const wellnessQuizes = userProfileTechQuiz.quiz.filter((item: QuizAnswers) => {
                        return item.type === 'wellness';
                    });
                    if ( wellnessQuizes ) {
                        updatedUserProfileData.quiz.push(...wellnessQuizes);
                    }
                }

                if ( userProfileTechQuiz.value ) {
                    /*
                    const wellnessQuizValues = userProfileTechQuiz.value.find(item => item.type === 'wellness');
                    if ( wellnessQuizValues ) {
                        updatedUserProfileData.quiz.push(wellnessQuizValues);
                    }
                    */
                    const wellnessQuizesValues = userProfileTechQuiz.value.filter((item: QuizValues) => {
                        return item.type === 'wellness';
                    });
                    if ( wellnessQuizesValues ) {
                        updatedUserProfileData.value.push(...wellnessQuizesValues);
                    }
                }

                userProfileDataService.updateUserProfileData(updatedUserProfileData).then((res: any) => {
                
                    if ( res.type === 'FeathersError' ) {

                        const snackbar = {
                            isOpen: true,
                            snackbarType: 'error',
                            snackbarText: res.message ?? 'Server error. Please try later!'
                        };

                        setSnackbarData(snackbar);
                    } else {

                        const snackbar = {
                            isOpen: true,
                            snackbarType: 'success',
                            snackbarText: 'Quiz answers were updated.'
                        };

                        setSnackbarData(snackbar);

                        setUserData(res);

                        window.location.href = '/technology';
                    }
                
                }).catch((err: any) => {
                    console.log(err);
                });
            } else {
                
                userProfileDataService.createUserProfileData(updatedUserProfileData).then((res: any) => {
                
                    if ( res.type === 'FeathersError' ) {

                        const snackbar = {
                            isOpen: true,
                            snackbarType: 'error',
                            snackbarText: res.message ?? 'Server error. Please try later!'
                        };

                        setSnackbarData(snackbar);
                    } else {

                        const snackbar = {
                            isOpen: true,
                            snackbarType: 'success',
                            snackbarText: 'Quiz answers were updated.'
                        };

                        setSnackbarData(snackbar);

                        setUserData(res);

                        window.location.href = '/technology';
                    }
                }).catch((err: any) => {
                    console.log(err);
                });
            }

        }
    }
    
    return (
        <Grid container alignContent="center" justifyContent="center">
            <Grid item xs={12} md={8} className="tech-quiz">
            
                <Hidden smUp>
                    <NavLink to="/profile/tech-preferences" className="icon-right-arrow"></NavLink>
                </Hidden>

                <Hidden smDown>
                    <h1>Your ear tech preferences</h1>
                    <p>
                        Hearing wellness is a term we use to describe devices like headphones, earphones, earpods or hearing devices. 
                        If it's a device that goes in or over your ears, it's a piece of ear tech.
                    </p>
                    <p>
                        Let us know more about how you use ear tech so that we can give you some tailored tipt to up your experience of sound through tech!
                    </p>
                </Hidden>
                
                {techQuizValues.map((techQuestion) => (
                    <Box className="quiz-question" key={techQuestion.name}>
                        <h3>{techQuestion.label}</h3>

                        { techQuestion.name === "techPeriodicity" &&
                            <Box className="answer-tech-block">
                                <Button variant="outlined" className={`${techQuestion.value === 1 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 1)}>
                                    Continuously through the day    
                                </Button>
                                <Button variant="outlined" className={`${techQuestion.value === 2 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 2)}>
                                    A few times a day
                                </Button>
                                <Button variant="outlined" className={`${techQuestion.value === 3 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 3)}>
                                    A few times a week
                                </Button>
                                <Button variant="outlined" className={`${techQuestion.value === 4 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 4)}>
                                    A few times a month
                                </Button>
                            </Box>
                        }

                        { techQuestion.name === "techReason" &&
                            <Box className="answer-tech-block">
                                <Button variant="outlined" className={`${techQuestion.value === 1 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 1)}>
                                    For active tasks: walking, exericse, chores
                                </Button>
                                <Button variant="outlined" className={`${techQuestion.value === 2 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 2)}>
                                    For stationary tasks: study, work, games
                                </Button>
                                <Button variant="outlined" className={`${techQuestion.value === 3 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 3)}>
                                    Socialising with friends, family & others
                                </Button>
                                <Button variant="outlined" className={`${techQuestion.value === 4 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 4)}>
                                    Communicating during work meetings
                                </Button>
                            </Box>
                        }

                        { techQuestion.name === "techFactors" &&
                            <Box className="answer-tech-block">
                                <Button variant="outlined" className={`${techQuestion.value === 1 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 1)}>
                                    Price
                                </Button>
                                <Button variant="outlined" className={`${techQuestion.value === 2 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 2)}>
                                    Compatibility with my hearing preferences
                                </Button>
                                <Button variant="outlined" className={`${techQuestion.value === 3 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 3)}>
                                    Device features: sound quality, durable
                                </Button>
                                <Button variant="outlined" className={`${techQuestion.value === 4 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 4)}>
                                    User reviews
                                </Button>
                            </Box>
                        }

                        { techQuestion.name === "techOutcome" &&
                            <Box className="answer-tech-block">
                                <Button variant="outlined" className={`${techQuestion.value === 1 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 1)}>
                                    Experience the sound I want to hear and focus on
                                </Button>
                                <Button variant="outlined" className={`${techQuestion.value === 2 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 2)}>
                                    Distance myself from external sounds
                                </Button>
                                <Button variant="outlined" className={`${techQuestion.value === 3 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 3)}>
                                    Improve my hearing in conversations
                                </Button>
                                <Button variant="outlined" className={`${techQuestion.value === 4 ? "active" : ""}`} onClick={() => answerQuestion(techQuestion, 4)}>
                                    Protect my ears by limiting exposure to loud noises
                                </Button>
                            </Box>
                        }

                    </Box>
                ))}

                <Button className="btn-gradient" onClick={saveQuiz}>See recomendations</Button>

            </Grid>
        </Grid>
    );
}

export default TechQuiz;
