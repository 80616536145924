import React, { useState } from "react";
import { QuizInterface } from "../models/quiz.model";

const initQuizList: QuizInterface[] = [];

export const QuizContext = React.createContext<any>(initQuizList);

const QuizStore = ({children}: any) => {
    const [quizList, setQuizList] = useState(initQuizList);
    
    return (
        <QuizContext.Provider value={[quizList, setQuizList]}>
            {children}
        </QuizContext.Provider>
    );
};

export default QuizStore;
