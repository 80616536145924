import './venue-management.scss';
import React, { useState, useEffect } from 'react'
import {
    Box,
    Button,
    makeStyles,
    Select,
    MenuItem,
    Modal,
    Container,
    Typography,
    CardHeader,
    FormControl,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    FormHelperText,
    Grid,
    InputLabel,
} from '@material-ui/core';
import BootstrapTable from 'react-bootstrap-table-next';
import Switch from "react-switch";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { SnackbarContext } from '../../context/snackbar.context';
import ReactPaginate from 'react-paginate';
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import { allPlaces, updatePlaces, statusUpdate, deleteVenues } from '../../axios/api';

const dataStyle = makeStyles({
    noDataStyle: {
        textAlign: "center",
    },
    // tableStyle: {
    //     padding: "2rem"
    // },
    pageStyle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center"
    },
    subPageStyle: {
        flex: "1",
        textAlign: "center",
        padding: "0.5rem"
    },
    eBtnStyle: {
        flex: "1",
        textAlign: "center",
        padding: "1.5rem"
    },
    dBtnStyle: {
        flex: "1",
        margin: "1rem"
    }
});

const initialState = {
    "_id": "",
    "name": "",
    "placeId": "",
    "address": "",
    "location": {
        "coordinates": [
            0,
            0
        ]
    },
    "venueType": "",
    "venueTypeText": null,
    "vibe": "",
    "crowdedness": "",
    "conversation": "",
    "conversationText": null,
    "status": ""
}

const style = {
    position: 'absolute' as 'absolute',
    // top: '50%',
    left: '0',
    right: '0',
    margin: '0 auto',
    my: 3,
    // height: "100%",
    // transform: 'translateY(-50%)',
    maxWidth: 575,
    bgcolor: 'background.paper',
    border: '0',
    boxShadow: 24,
    pt: 3,
    px: 3,
    pb: 3,
    overflowY: "auto"
};

function VenueManagement(props: any) {
    const recordClass = dataStyle();
    const [snackbarData, setSnackbarData] = React.useContext(SnackbarContext);
    const [totalPages, setTotalPages] = useState(1);
    const [venues, setVenues] = useState<Array<any>>([]);
    const [thisPage, setThisPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [dialogueOpen, setDialogueOpen] = React.useState(false);
    const [updatedRow, setUpdatedRow] = useState();
    const [newVenueData, setNewVenueData] = useState(initialState);
    const [googleVenue, setGoogleVenue] = React.useState('');
    const [selectedRow, setSelectedRow] = useState({
        "_id": ""
    });

    const updated = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'Venue updated successfully'
        };

        setSnackbarData(snackbar);
    }

    const toggleChangeActive = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'Venue is activated'
        };

        setSnackbarData(snackbar);
    }

    const toggleChangeNotActive = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Venue is deactivated'
        };

        setSnackbarData(snackbar);
    }
    const deleted = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Venue deleted successfully'
        };
        setSnackbarData(snackbar);
    }

    const dontUpdate = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Please fill all fields'
        };
        setSnackbarData(snackbar);
    }

    useEffect(() => {
        let obj = {
            limit: perPage,
            page: 1,
        }

        allPlaces(obj, {
            onSuccess: (res) => {
                // console.log("Success Response", res.data)
                setVenues(
                    res.data.data
                )
                setTotalPages(res.data.total);
            },
            onError: (res) => {
                console.log("Error Response", res)
            }
        })
    }, [perPage])

    useEffect(() => {
        if (newVenueData.venueType !== "other") {
            newVenueData.venueTypeText = null;
        }
    }, [newVenueData])

    useEffect(() => {
        if (newVenueData.conversation !== "other") {
            newVenueData.conversationText = null;
        }
    }, [newVenueData])
    const columns = [
        {
            dataField: 'photoRef',
            text: 'Image',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return <img src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photo_reference=${cell}&key=${process.env.REACT_APP_C_KEY}`} className="d-block object-cover" width="56" height="56" />;
            },
        },
        {
            dataField: 'name',
            text: 'Venue Address',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return <span>{cell}</span>;
            },
            // sort: true
        },
        {
            dataField: 'venueType',
            text: 'Type of Venue',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return <span>{changeCase(cell)}</span>;
            },
        },
        {
            dataField: 'crowdedness',
            text: 'Crowdness',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return <span>{changeCase(cell.replaceAll("_", " "))}</span>;
            },
        },
        {
            dataField: 'vibe',
            text: 'Ambience',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return <span>{changeCase(cell)}</span>;
            },
        },
        {
            dataField: 'conversation',
            text: 'Conversation',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return <span>{changeCase(cell.replaceAll("_", " "))}</span>;
            },
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cell: any, row: any, rowIndex: any) => {
                // console.log("hello", cell)
                return <span>{changeCase(cell)}</span>
                // return (
                //     <span>
                //         {cell === "" ? "Unverified" : changeCase(cell)}
                //     </span>
                // );
            },
        },
        {
            dataField: 'active',
            text: 'Venue',
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                // console.log("All", cell)
                return (
                    <Switch
                        className="d-block"
                        id="react-switch"
                        onChange={(checked, e) => { toggleActive(cell, row); checked = cell ? false : true }}
                        checked={testing(cell)}
                    />
                );
            },
        },
        {
            dataField: 'action',
            text: 'Action',
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {

                return (
                    <div className="d-flex">
                        {/* <div className={recordClass.eBtnStyle}>
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => settingSelectedVenue(row, rowIndex)}
                            >
                                <FaRegEdit />
                            </button>
                        </div>
                        <div className={recordClass.dBtnStyle}>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                    handleDialogueOpen(row)
                                }}
                            >
                                <FaTrashAlt />
                            </button>
                        </div> */}

                        <IconButton color="primary" component="span" onClick={() => settingSelectedVenue(row, rowIndex)}>
                            <FaRegEdit fontSize="16" />
                        </IconButton>
                        <IconButton color="secondary" component="span" onClick={() => { handleDialogueOpen(row) }}>
                            <FaTrashAlt fontSize="16" />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    // const defaultSorted: any = [{
    //     dataField: 'name',
    //     order: 'asc'
    // }];

    const changeCase = (string: any) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const settingSelectedVenue = (row: any, rowIndex: any) => {
        setOpen(true);
        setNewVenueData({
            "_id": row._id,
            "name": row.name,
            "placeId": row.placeId,
            "address": row.address,
            "location": {
                "coordinates": [
                    row.location.coordinates[0],
                    row.location.coordinates[1]
                ]
            },
            "venueType": row.venueType,
            "venueTypeText": row.venueTypeText,
            "vibe": row.vibe,
            "crowdedness": row.crowdedness,
            "conversation": row.conversation,
            "conversationText": row.conversationText,
            "status": row.status
        })
        // console.log("PlaceID", row.placeId)
        // console.log("_id", row._id)
    }
    const testing = (cell: boolean) => {
        return cell
    }

    //API status Update function
    const toggleActive = (val: any, row: any) => {
        const data = {
            "active": val ? false : true
        }
        statusUpdate(row._id, data, {
            onSuccess: (res) => {
                // find the index of venue using id
                // venue = Array of objects.
                const newVenues = [...venues]
                // console.log("Success Response", res)

                for (const v of newVenues) {
                    if (v._id === res.data._id) {
                        if (v.active === true) {
                            v.active = res.data.active;
                            toggleChangeNotActive();
                            break
                        } if (v.active !== true) {
                            v.active = res.data.active;
                            toggleChangeActive();
                            break
                        }
                    }
                }

                setVenues(newVenues)
                // console.log("Success Response", venues)
            },
            onError: (res) => {
                console.log("Error Response", res)
            }
        })
    };

    const pageCount = Math.ceil(totalPages / perPage);

    //API All venues function
    const changePage = ({ selected }: any) => {
        setThisPage(selected);
        let obj = {
            limit: perPage,
            page: selected + 1,
        }
        allPlaces(obj, {
            onSuccess: (res) => {
                // console.log("Success Response inside OnPageChange", res.data.data)
                setVenues(res.data.data);
            },
            onError: (res) => {
                console.log("Error Response inside OnPageChange", res)
            }
        })
    };

    //API update function
    const updateVenue = () => {
        // console.log('newvenuedata', newVenueData)
        if ((newVenueData.conversation === "other" && newVenueData.conversationText === null) || (newVenueData.venueType === "other" && newVenueData.venueTypeText === null)) {
            dontUpdate();
        } else {
            updatePlaces(newVenueData._id, newVenueData, {
                onSuccess: (res) => {
                    setOpen(false);
                    setUpdatedRow(res);
                    updated();
                    window.location.reload();
                    localStorage.removeItem("placeId");
                    // console.log("Success Response inside OnPageChange", res)
                },
                onError: (res) => {
                    console.log("Error Response inside OnPageChange", res)
                }
            });
        }
    }

    //API delete function
    const deletePlace = (id: any) => {
        // console.log("deleted")
        // window.location.reload();
        deleteVenues(id, {
            onSuccess: (res) => {
                deleted();
                window.location.reload();
                // console.log("Success Response", res)
            },
            onError: (res) => {
                console.log("Error Response", res)
            }
        })
    };
    const changeVenueName = async (placeGoogleData: any) => {
        setGoogleVenue(placeGoogleData);
        const data = placeGoogleData.value?.structured_formatting;
        localStorage.setItem("placeId", placeGoogleData.value?.place_id);
        let placeId: any = localStorage.getItem("placeId");
        // console.log("PlaceID", placeId)
        if (data) {
            geocodeByAddress(data.secondary_text).then(results => getLatLng(results[0])).then(latLng => {
                // console.log('res', latLng)
                setNewVenueData({
                    ...newVenueData,
                    address: placeGoogleData.label,
                    name: placeGoogleData.label,
                    placeId: placeId,
                    location: {
                        coordinates: [latLng.lng, latLng.lat]
                    }
                })
            });
        }
    }
    const handleStatus = (e: any) => {
        if (e.target.value !== undefined) {
            setNewVenueData({ ...newVenueData, status: e.target.value })
        }
    };

    const handleVenueName = (e: any) => {
        setNewVenueData({ ...newVenueData, name: e.target.value })

    };
    const handleVenueType = (e: any) => {
        if (e.target.value !== undefined) {
            setNewVenueData({ ...newVenueData, venueType: e.target.value })
        }
    };
    const handleCrowdedness = (e: any) => {
        if (e.target.value !== undefined) {
            setNewVenueData({ ...newVenueData, crowdedness: e.target.value })
        }
    };
    const handleVibe = (e: any) => {
        if (e.target.value !== undefined) {
            setNewVenueData({ ...newVenueData, vibe: e.target.value })
        }
    };
    const handleConversation = (e: any) => {
        if (e.target.value !== undefined) {
            setNewVenueData({ ...newVenueData, conversation: e.target.value })
        }
    };

    const handleVenueTypeText = (e: any) => {
        setNewVenueData({ ...newVenueData, venueTypeText: e.target.value })
    };
    const handleDialogueOpen = (row: any) => {
        setDialogueOpen(true);
        setSelectedRow(row);
    };

    const handleDialogueClose = () => {
        deletePlace(selectedRow._id);
        setDialogueOpen(false);
    };
    const handleDialogue = () => {
        setDialogueOpen(false);
    };
    const handleConversationText = (e: any) => {
        setNewVenueData({ ...newVenueData, conversationText: e.target.value })
    };

    const closeModal = () => {
        setOpen(false);
        localStorage.removeItem("placeId");
    };

    return (
        <>
            <Box className="venues-for-me top-header-spacing">
                <Box py={3}>
                    <Container maxWidth={false}>
                        <Box mb={2}>
                            <Typography variant="h4" component="h1" color="primary" className="font-weight-700">List of All Venues</Typography>
                        </Box>
                        <BootstrapTable
                            bootstrap4={true}
                            keyField="_id"
                            remote={{ filter: false }}
                            wrapperClasses="table-responsive"
                            columns={columns}
                            data={venues}
                            hover={true}
                            striped={true}
                            bordered={false}
                            noDataIndication={() => (<div className={recordClass.noDataStyle}>No Data Found!</div>)}
                        />
                        <Box display="flex" justifyContent="space-between">
                            {/* <div className={recordClass.pageStyle}> */}
                            <Select
                                // id="demo-simple-select"
                                value={perPage}
                                onChange={(e: any) => setPerPage(e.target.value)}
                                displayEmpty
                            // variant="outlined"
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                            {/* </div> */}
                            {/* <div className={recordClass.subPageStyle}> */}
                            <ReactPaginate
                                previousLabel="< Previous"
                                breakLabel="..."
                                nextLabel="Next >"
                                containerClassName={'pagination justify-content-center my-0'}
                                pageClassName={'page-item can-click'}
                                pageLinkClassName={'page-it page-link can-click'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-it page-link can-click'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-it page-link can-click'}
                                breakClassName={'page-item'}
                                breakLinkClassName={'page-it page-link'}
                                activeClassName={'active'}
                                pageCount={pageCount}
                                onPageChange={changePage}

                            />
                            {/* </div> */}
                        </Box>
                    </Container>
                </Box>

                {/* modal start */}
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="custom-modal"
                >
                    <Box sx={style}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="h5" component="h2" className="font-weight-700" color="primary">Update Venue</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="h6" component="span" color="primary">
                                        Venue Name
                                    </Typography>
                                    <Box>
                                        <GooglePlacesAutocomplete
                                            apiKey={process.env.REACT_APP_C_KEY}
                                            selectProps={{
                                                value: googleVenue,
                                                onChange: changeVenueName,
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="h6" component="span" color="primary">
                                        Venue Address
                                    </Typography>
                                    <FormControl fullWidth>
                                        <TextField variant="outlined" id="address" aria-describedby="address-helper-text" type="text" value={newVenueData.address} name="name" placeholder=" Venue Address" onChange={e => handleVenueName(e)} />
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box>
                                    <FormControl fullWidth>
                                        <Typography variant="h6" component="span" color="primary">Type of Venue</Typography>
                                        <Select
                                            value={newVenueData.venueType}
                                            onChange={handleVenueType}
                                            displayEmpty
                                            variant="outlined"
                                            name="venueType"
                                        >
                                            <MenuItem >Select Venue Type</MenuItem>
                                            <MenuItem value="cafe">Cafe</MenuItem>
                                            <MenuItem value="restaurant">Restaurant</MenuItem>
                                            <MenuItem value="bar">Bar or Pub</MenuItem>
                                            <MenuItem value="other">Other</MenuItem>
                                        </Select>
                                        {newVenueData.venueType === "other" &&
                                            <FormControl fullWidth style={{ textAlign: "center", marginTop: "2rem" }}>
                                                <Typography variant="h6" component="label" color="primary">Other Venue Type</Typography>
                                                <TextField variant="outlined" id="venueTypeText" aria-describedby="venueTypeText-helper-text" type="text" name="venueTypeText" value={newVenueData.venueTypeText !== null ? newVenueData.venueTypeText : ""} autoFocus placeholder="Mention any venue type" onChange={e => handleVenueTypeText(e)} />
                                            </FormControl>}
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box>
                                    <FormControl fullWidth>
                                        <Typography variant="h6" component="label" color="primary">Crowdedness</Typography>
                                        <Select
                                            value={newVenueData.crowdedness}
                                            onChange={handleCrowdedness}
                                            displayEmpty
                                            variant="outlined"
                                            name="crowdedness"
                                        >
                                            <MenuItem >Select Crowdedness</MenuItem>
                                            <MenuItem value="not_crowded">Not crowded</MenuItem>
                                            <MenuItem value="not_too_crowded">Not too crowded</MenuItem>
                                            <MenuItem value="crowded">Crowded</MenuItem>
                                            <MenuItem value="very_crowded">Very Crowded</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box>
                                    <FormControl fullWidth>
                                        <Typography variant="h6" component="label" color="primary">Ambience</Typography>
                                        <Select
                                            value={newVenueData.vibe}
                                            onChange={handleVibe}
                                            displayEmpty
                                            variant="outlined"
                                            name="vibe"
                                        >
                                            <MenuItem >Select Ambience</MenuItem>
                                            <MenuItem value="calm">Calm</MenuItem>
                                            <MenuItem value="buzzy">Buzzy</MenuItem>
                                            <MenuItem value="energetic">Energetic</MenuItem>
                                            <MenuItem value="overwhelming">Overwhelming</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box>
                                    <FormControl fullWidth>
                                        <Typography variant="h6" component="label" color="primary">Conversation</Typography>
                                        <Select
                                            value={newVenueData.conversation}
                                            onChange={handleConversation}
                                            displayEmpty
                                            variant="outlined"
                                            name="conversation"
                                        >
                                            <MenuItem >Select Conversation</MenuItem>
                                            <MenuItem value="easy">Easy</MenuItem>
                                            <MenuItem value="some_difficulty">Some Difficulty</MenuItem>
                                            <MenuItem value="hard">Hard</MenuItem>
                                            <MenuItem value="very_hard">Very Hard</MenuItem>
                                            <MenuItem value="other">Other</MenuItem>
                                        </Select>
                                        {newVenueData.conversation === "other" &&
                                            <FormControl fullWidth style={{ textAlign: "center", marginTop: "2rem" }}>
                                                <Typography variant="h6" component="label" color="primary">Other Conversation Type</Typography>
                                                <TextField variant="outlined" id="conversationText" aria-describedby="conversationText-helper-text" type="text" name="conversationText" value={newVenueData.conversationText !== null ? newVenueData.conversationText : ""} autoFocus placeholder="Mention any venue type" onChange={e => handleConversationText(e)} />
                                            </FormControl>}
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box>
                                    <FormControl fullWidth>
                                        <Typography variant="h6" component="label" color="primary">Status</Typography>
                                        <Select
                                            value={newVenueData.status}
                                            onChange={handleStatus}
                                            displayEmpty
                                            variant="outlined"
                                            style={{ marginBottom: "1rem" }}
                                            name="status"
                                        >
                                            <MenuItem>Select status</MenuItem>
                                            <MenuItem value="">Unverified</MenuItem>
                                            <MenuItem value="verified">Verified</MenuItem>
                                            <MenuItem value="certified">Certified</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button type="button" onClick={updateVenue} >Update</Button>
                                    <Button type="button" onClick={closeModal}>Cancel</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* modal end */}

                {/* dialog start */}
                <Dialog
                    open={dialogueOpen}
                    onClose={() => handleDialogue()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>{"Delete Venue"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure? You want to delete</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn text-uppercase" onClick={handleDialogue} autoFocus>No</button>
                        <button className="btn btn-danger text-uppercase" onClick={handleDialogueClose}>Yes</button>
                    </DialogActions>
                </Dialog>
                {/* dialog end */}
            </Box>
        </>
    )
}

export default VenueManagement;