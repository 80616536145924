
import { PlaceInterface } from "../../models/place.model";
import FeathersService from "./feathers.service";

export class RestPlaceService {

    private placeRestService = FeathersService.service('places');

    constructor() {}

    getUser( userId: string ) {

    }

    async find(): Promise<PlaceInterface[]> {
        return await this.placeRestService.find({
            query: {state: 'active'}
        }).then((res: any) => {
            return res;
        }).catch((err: any) => {
            console.log(err);
        });
    }

    create(placeData: PlaceInterface): Promise<PlaceInterface> {
        return this.placeRestService.create(placeData);
    }
}
