import { Drawer } from '@material-ui/core';
import TechFeedList from '../tech-feed-list/tech-feed-list';
import './tech-feed.scss';

function TechFeed() {

    return (

        <div className="feed-drawer-wrapper">
    
                <Drawer 
                    variant="permanent" 
                    anchor="top"
                    className="wellness-feed-header"
                >
                    <h1>Your Tech Feed</h1>

                </Drawer>
                
                <TechFeedList />

        </div>
    );
}

export default TechFeed;
