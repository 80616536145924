import './venues-map-layout.scss';
import { Drawer, Button, Hidden, FormControl, Input, InputAdornment, InputLabel } from '@material-ui/core';
import VenuesGoogleMap from '../venues-google-map/venues-google-map';
import React, { useEffect, useMemo, useState } from 'react';
import VenueList from '../venue-list/venue-list';
import { PlaceService } from '../../../../services/place.service';
import { PlaceInterface } from '../../../../models/place.model';
import VenueMobile from '../venue-mobile/venue-mobile';
import { VenueContext } from '../../../../context/venue.context';
import PropTypes from 'prop-types';

const placeService = new PlaceService();

function VenuesMapLayout({openFilters, filterState}: any) {

    const [venueData, setVenueData] = React.useContext(VenueContext);
    const [venueListStatus, setVrnueListStatus] = useState(false);
    const [venuesFilter, setVrnuesFilter] = useState('calm');
    const [venuesSearch, setVrnuesSearch] = useState('');
    const [venueList, setVenueList] = useState<PlaceInterface[]>([]);
    const [venueListFiltered, setVenueListFiltered] = useState<PlaceInterface[]>([]);
    const [init, setInit] = useState<boolean>(false);

    useEffect(() => {
        if ( !init) {
            placeService.getPlaceList().then((result: PlaceInterface[]) => {
                
                setInit(true);

                if ( result ) {
                    if ( result.length > 0 && !venueData ) {
                        setVenueData(result[0]);
                    }
                    setVenueList(result);
                    setVenueListFiltered(result);
                }
            });
        }
    });

    const filterVenues = () => {

        let filteredVenueList: PlaceInterface[] = [...venueList];

        const applyFilterType = (filterType: string) => {
            const filters: any = [];

            for (const filter in filterState[filterType]) {
                if ( filterState[filterType][filter] === true ) {
                    filters.push(filter);
                }
            }

            if ( filters.length !== 0 ) {
                filteredVenueList = filteredVenueList.filter((venue: any) => {
                    return typeof venue[filterType] === 'string' && filters.includes(venue[filterType]);
                });
            }
        }

        for (const filterType in filterState) {
            applyFilterType(filterType);
        }

        setVenueListFiltered(filteredVenueList);
    }

    useMemo(() => {
        if ( init === true ) {
            filterVenues();
        }
    }, [filterState]);

    const toggleVenueListPanel = () => {
        setVrnueListStatus(false);
    }

    const findVenues = (e: any) => {
        
        const searchVal: string = e.target.value.toLowerCase().replace(" ", "");
        setVrnuesSearch(e.target.value);
        if (  searchVal.length > 0 ) {
            const pattern = new RegExp(searchVal);

            const venueListNewFilter: PlaceInterface[] = venueList.filter((venue) => {
                return pattern.test(venue.name.toLowerCase().replace(" ", ""));
            });

            setVenueListFiltered(venueListNewFilter);
        } else {
            setVenueListFiltered(venueList);
        }
    }

    return (
        <div className="venue-drawer-wrapper">

            <Hidden smUp>
                <Drawer 
                    variant="permanent" 
                    anchor="top"
                    className="venue-mobile-map-header"
                >
                    <FormControl className="search-box">
                        <InputLabel htmlFor="venue-search-input" ></InputLabel>
                        <Input id="venue-search-input" className="venue-search" startAdornment={
                            <InputAdornment position="start">
                                <span className="icon-search"></span>
                            </InputAdornment>
                        } endAdornment={
                            <InputAdornment position="end">
                                <span className="icon-filter" onClick={openFilters(true)}></span>
                            </InputAdornment>
                        } 
                        value={venuesSearch}
                        onChange={findVenues}
                        />
                    </FormControl>
                    
                </Drawer>

                <VenueMobile venueList={venueListFiltered} />
            </Hidden>


            <Hidden mdDown>
                <Drawer 
                    variant="permanent" 
                    anchor="left"
                    className="venue-sidebar"
                >
                    <h1>Certified Venues Near You</h1>
                    <p>
                        Click the buttons below to filter the venues.
                    </p>
                    <div className="action-block">
                        <Button variant="outlined" className={`${venuesFilter === "calm" ? "active" : ""}`} onClick={() => setVrnuesFilter('calm')}>
                            Calm
                        </Button>
                        <Button variant="outlined" className={`${venuesFilter === "buzzy" ? "active" : ""}`} onClick={() => setVrnuesFilter('buzzy')}>
                            Buzzy
                        </Button>
                        <Button variant="outlined" className={`${venuesFilter === "energetic" ? "active" : ""}`} onClick={() => setVrnuesFilter('energetic')}>
                            Energetic
                        </Button>
                        <Button variant="outlined" className={`${venuesFilter === "aura" ? "active" : ""}`} onClick={() => setVrnuesFilter('aura')}>
                            <span className="icon-padlock"></span>
                            My Aura
                        </Button>
                    </div>
                    <VenueList venueList={venueListFiltered} />
                </Drawer>
            </Hidden>

            <Hidden lgUp>
                <Drawer 
                    anchor="left"
                    open={venueListStatus}
                    className="venue-sidebar"
                    onClose={toggleVenueListPanel}
                >
                    <h1>Certified Venues Near You</h1>
                    <p>
                        Click the buttons below to filter the venues.
                    </p>
                    <div className="action-block">
                        <Button variant="outlined" className={`${venuesFilter === "calm" ? "active" : ""}`} onClick={() => setVrnuesFilter('calm')}>
                            Calm
                        </Button>
                        <Button variant="outlined" className={`${venuesFilter === "buzzy" ? "active" : ""}`} onClick={() => setVrnuesFilter('buzzy')}>
                            Buzzy
                        </Button>
                        <Button variant="outlined" className={`${venuesFilter === "energetic" ? "active" : ""}`} onClick={() => setVrnuesFilter('energetic')}>
                            Energetic
                        </Button>
                        <Button variant="outlined" className={`${venuesFilter === "aura" ? "active" : ""}`} onClick={() => setVrnuesFilter('aura')}>
                            <span className="icon-padlock"></span>
                            My Aura
                        </Button>
                    </div>
                    <VenueList venueList={venueListFiltered} />
                </Drawer>
            </Hidden>

            <VenuesGoogleMap venueList={venueListFiltered} />

        </div>
    );
}

VenuesMapLayout.propTypes = {
    openFilters: PropTypes.func,
    filterState: PropTypes.object
};

export default VenuesMapLayout;