import { RestGoogleService } from "./rest/rest-google.service";
// import { Loader,  } from '@googlemaps/js-api-loader';
// import { GoogleApiWrapper } from 'google-maps-react';

export class GoogleService {

    private restGoogleService = new RestGoogleService();
    private loader: any;
/*
    constructor() {
            if ( !this.loader ) {
                this.loader = new Loader({
                    apiKey: 'AIzaSyCA-h9M-1IGT4C_SDGd-XNbeNO6q3law2I',
                    libraries: ['places']
                });
            }
    }
*/
    getGoogleLoader() {
        return this.restGoogleService.loadGoogleLoader();
    }

    getPlaceData(place: string, fields: string[] = []) {
        
        return this.restGoogleService.getPlaceData(place, fields).then((res: any) => {
            return res;
        }).catch((err: any) => {
            console.log(err);
            return err;
        });
        
    }
}
