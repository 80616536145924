import { Switch, Route, useRouteMatch } from 'react-router-dom';
import LoginComponent from './components/login/login';
import SignupComponent from './components/signup/signup';
import ForgotPassword from './components/login/forgotpassword'
import ResetPassword from './components/login/resetpassword'
import OTPVerify from './components/signup/otp-verify'
import VerifySignup from './components/signup2/verifysignup';
import googleLoginCallback from './googleLoginCallback';
import UpdateProfile from './components/updateProfile'
import './auth.scss';
import { createTheme, ThemeProvider } from '@material-ui/core';

const theme = createTheme({
    typography: {
        // fontFamily: 'NoyhThin',
    },
});

function AuthPage() {

    let { path } = useRouteMatch();

    return (
        // <ThemeProvider theme={theme}>
        <Switch>
            <Route path={`${path}/login`} component={LoginComponent} />
            <Route path={`${path}/forgotpassword`} component={ForgotPassword} />
            <Route path={`${path}/resetpassword`} component={ResetPassword} />
            <Route path={`${path}/signup`} component={SignupComponent} />
            <Route path={`${path}/verifysignup`} component={VerifySignup} />
            <Route path={`${path}/otp-verify`} component={OTPVerify} />
            <Route path={`${path}/googlelogin-callback`} component={googleLoginCallback} />
            <Route path={`${path}/update-profile`} component={UpdateProfile} />

        </Switch>
        // </ThemeProvider>
    );
}

export default AuthPage;