import React, { useState } from "react";

const initSnackbarState = {
    isOpen: false,
    snackbarType: '',
    snackbarText: ''

}

export const SnackbarContext = React.createContext<any>(initSnackbarState);

const SnackbarStore = ({children}: any) => {
    const [snackbarData, setSnackbarData] = useState(initSnackbarState);
    
    return (
        <SnackbarContext.Provider value={[snackbarData, setSnackbarData]}>
            {children}
        </SnackbarContext.Provider>
    );
};

export default SnackbarStore;