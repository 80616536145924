import { Box, Button, Checkbox, Drawer, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { PlaceTypeFiter } from '../../../../models/place.model';
import VenuesMapLayout from '../venues-map-layout/venues-map-layout';
import './venue-map-filter.scss';

function VenuesMapFilter() {

    const [filterDrawer, setFilterDrawer] = useState<boolean>(false);

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
      ) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
    
        setFilterDrawer(open);
    };

    const initFilterValues = {
        venueStatus: {
            verified: false,
            certified: false
        },
        venueType: {
            cafe: false,
            restaurant: false,
            bar: false
        },
        crowdedness: {
            not_crowded: false,
            not_too_crowded: false,
            crowded: false,
            very_crowded: false
        },
        ambience: {
            calm: false,
            buzzy: false,
            energetic: false,
            overwhelming: false
        },
        conversation: {
            easy: false,
            some_difficulty: false,
            hard: false,
            very_hard: false
        }
    }

    const [filterState, setFilterTypeState] = React.useState(initFilterValues);

    const { verified, certified } = filterState.venueStatus;
    const { cafe, restaurant, bar } = filterState.venueType;
    const { not_crowded, not_too_crowded, crowded, very_crowded } = filterState.crowdedness;
    const { calm, buzzy, energetic, overwhelming } = filterState.ambience;
    const { easy, some_difficulty, hard, very_hard } = filterState.conversation;

    interface FilterTypes {

    }
    
    const handleTypeChange = (filterType: PlaceTypeFiter, event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterTypeState(
            {
                ...filterState,
                [filterType]: {
                    ...filterState[filterType],
                    [event.target.name]: event.target.checked
                }
            });
    };

    const resetFilters = () => {
        setFilterTypeState(initFilterValues);
    }

    return (
        <Box className="venue-filter-wrapper">
            <Drawer className="filter-drawer" variant="temporary" anchor="bottom" open={filterDrawer} onClose={toggleDrawer(false)}>
                <Box className="drawer-header">
                    <span className="icon-close" onClick={toggleDrawer(false)}></span>
                    <span className="header-line"></span>
                    <h3>Filters</h3>
                </Box>

                <FormControl component="fieldset" className="filter-group">
                    <FormLabel component="legend">Verified & Certified venues</FormLabel>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox name="verified" checked={verified} onChange={(e) => {handleTypeChange("venueStatus", e);}} />
                        } label={
                            <>
                                <h5>Verified</h5>
                                <p>
                                    Show venues verified for great conversation</p>
                            </>} labelPlacement="start"/>

                        <FormControlLabel control={
                            <Checkbox name="certified" checked={certified} onChange={(e) => {handleTypeChange("venueStatus", e);}} />
                        } label={
                            <>
                                <h5>Certified</h5>
                                <p>Show venues Certified for great conversation with live atmosphere data</p>
                            </>
                        } labelPlacement="start" />
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" className="filter-group">
                    <FormLabel component="legend">Type of venue</FormLabel>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox name="cafe" checked={cafe} onChange={(e) => {handleTypeChange("venueType", e);}} />
                        } label={<h5>Cafe</h5>} labelPlacement="start"/>

                        <FormControlLabel control={
                            <Checkbox name="restaurant" checked={restaurant} onChange={(e) => {handleTypeChange("venueType", e);}} />
                        } label={<h5>Restaurant</h5>} labelPlacement="start" />

                        <FormControlLabel control={
                            <Checkbox name="bar" checked={bar} onChange={(e) => {handleTypeChange("venueType", e);}} />
                        } label={<h5>Bar or Pub</h5>} labelPlacement="start" />
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" className="filter-group">
                    <FormLabel component="legend">
                        Crowdedness
                        <p>Only show venues with certain crowd levels</p>
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox name="not_crowded" checked={not_crowded} onChange={(e) => {handleTypeChange("crowdedness", e);}} />
                        } label={
                            <>
                                <h5>Not Crowded</h5>
                                <p>Lots of seats available</p>
                            </>
                        } labelPlacement="start"/>

                        <FormControlLabel control={
                            <Checkbox name="not_too_crowded" checked={not_too_crowded} onChange={(e) => {handleTypeChange("crowdedness", e);}} />
                        } label={
                            <>
                                <h5>Not too crowded</h5>
                                <p>Some seats available</p>
                            </>
                        } labelPlacement="start" />

                        <FormControlLabel control={
                            <Checkbox name="crowded" checked={crowded} onChange={(e) => {handleTypeChange("crowdedness", e);}} />
                        } label={
                            <>
                                <h5>Crowded</h5>
                                <p>Limited seating</p>
                            </>
                        } labelPlacement="start" />

                        <FormControlLabel control={
                            <Checkbox name="very_crowded" checked={very_crowded} onChange={(e) => {handleTypeChange("crowdedness", e);}} />
                        } label={
                            <>
                                <h5>Very crowded</h5>
                                <p>No seats left</p>
                            </>
                        } labelPlacement="start" />
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" className="filter-group">
                    <FormLabel component="legend">
                        Ambience
                        <p>Only show venues with certain ambience types</p>
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox name="calm" checked={calm} onChange={(e) => {handleTypeChange("ambience", e);}} />
                        } label={
                            <>
                                <h5>Calm</h5>
                                <p>Soft music and chatter, it feels cosy, warm</p>
                            </>
                        } labelPlacement="start"/>

                        <FormControlLabel control={
                            <Checkbox name="buzzy" checked={buzzy} onChange={(e) => {handleTypeChange("ambience", e);}} />
                        } label={
                            <>
                                <h5>Buzzy</h5>
                                <p>Upbeat music and louder chatter, it feels lively</p>
                            </>
                        } labelPlacement="start" />

                        <FormControlLabel control={
                            <Checkbox name="energetic" checked={energetic} onChange={(e) => {handleTypeChange("ambience", e);}} />
                        } label={
                            <>
                                <h5>Energetic</h5>
                                <p>Loud music and/or voices, it feels a bit wild but fun</p>
                            </>
                        } labelPlacement="start" />

                        <FormControlLabel control={
                            <Checkbox name="overwhelming" checked={overwhelming} onChange={(e) => {handleTypeChange("ambience", e);}} />
                        } label={
                            <>
                                <h5>Overwhelming</h5>
                                <p>Too much music and chatter. It feels more stressful than fun</p>
                            </>
                        } labelPlacement="start" />
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" className="filter-group">
                    <FormLabel component="legend">
                        Conversation
                        <p>See venues based on how easy it is to have a conversation</p>
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox name="easy" checked={easy} onChange={(e) => {handleTypeChange("conversation", e);}} />
                        } label={
                            <>
                                <h5>Easy</h5>
                                <p>Very smooth conversation</p>
                            </>
                        } labelPlacement="start"/>

                        <FormControlLabel control={
                            <Checkbox name="some_difficulty" checked={some_difficulty} onChange={(e) => {handleTypeChange("conversation", e);}} />
                        } label={
                            <>
                                <h5>Some difficulty</h5>
                                <p>Occasionally have to repeat yourself</p>
                            </>
                        } labelPlacement="start" />

                        <FormControlLabel control={
                            <Checkbox name="hard" checked={hard} onChange={(e) => {handleTypeChange("conversation", e);}} />
                        } label={
                            <>
                                <h5>Hard</h5>
                                <p>Speaking with a raised voice, hard to hear, repeating yourself often</p>
                            </>
                        } labelPlacement="start" />

                        <FormControlLabel control={
                            <Checkbox name="very_hard" checked={very_hard} onChange={(e) => {handleTypeChange("conversation", e);}} />
                        } label={
                            <>
                                <h5>Very hard</h5>
                                <p>Have to shout, consistently need to repeat yourself</p>
                            </>
                        } labelPlacement="start" />
                    </FormGroup>
                </FormControl>

                <Box className="action-block">
                    <Button variant="outlined" type="button" onClick={resetFilters}>Clear all</Button>
                    <Button className="btn-gradient" type="button" onClick={toggleDrawer(false)}>Apply Filters</Button>
                </Box>
                
            </Drawer>

            <VenuesMapLayout openFilters={toggleDrawer} filterState={filterState}></VenuesMapLayout>
        </Box>
    )
}

export default VenuesMapFilter;