import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, InputLabel, TextField, Select, MenuItem, Button, Grid, Box, Typography, Container } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import '../components/login/login.scss';
import moment from 'moment';
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { updateProfile } from "../../../axios/api";
import { SnackbarContext } from '../../../context/snackbar.context';

const updateSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter your first name"),
    lastName: Yup.string().required("Please enter last name"),
    birthDate: Yup.date().required("Please enter date of birth"),
    technology: Yup.number(),
    postCode: Yup.string().required("Please enter postcode")
});

function UpdateProfile(props: any) {
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const { register, errors, handleSubmit, control, getValues } = useForm({
        resolver: yupResolver(updateSchema)
    });

    var isUpdated = false;

    //CHECKING IF NEW PROFILE IS UPDATED OR NOT
    const checkGoogleKey = () => {
        window.onbeforeunload = function (e) {
            if (isUpdated == false) {
                localStorage.removeItem('newAuth')
            }
        }
    }

    const [, setSnackbarData] = React.useContext(SnackbarContext);

    const updatedProfile = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'Profile Updated Successfully'
        };

        setSnackbarData(snackbar);
    }
    const error = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Error'
        };

        setSnackbarData(snackbar);
    }

    const onSubmit = () => {
        const profile =
        {
            "name": {
                "firstName": firstName,
                "lastName": lastName,
            },
            "dob": moment(getValues("birthDate")).format("MM-DD-YYYY"),
            "postcode": getValues("postCode"),
            "earTech": Boolean(getValues("technology"))
        }
        updateProfile(profile, {
            onError: () => {
                error();
            },
            onSuccess: () => {
                updatedProfile();
                localStorage.getItem("newAuth");
                isUpdated = true;
                checkGoogleKey()
                props.history.push('/profile');
            }
        })
    };

    useEffect(() => {
        checkGoogleKey()
    });

    return (
        <>
            <Box p={3} className="auth-form-header">
                <span className="icon-mumbli-logo-2"></span>
                <Typography variant="h2" component="div">Complete Your Profile</Typography>
            </Box>
            <Container maxWidth="xs" className="signup-content">
                <Box py={4}>
                    <Grid>
                        <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
                            <Box mb={4}>
                                <Typography variant="h4" component="h1">Update your Mumbli account.</Typography>
                            </Box>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="firstname">First Name</InputLabel>
                                        <TextField variant="outlined" id="firstname" aria-describedby="firstname-helper-text" name="firstName" inputRef={register} onChange={(data) => setfirstName(data.target.value)} />
                                        <FormHelperText id="firstname-helper-text"></FormHelperText>
                                        <p>{errors.firstName?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="lastname">Last Name</InputLabel>
                                        <TextField variant="outlined" id="lastName" aria-describedby="lastName-helper-text" name="lastName" inputRef={register} onChange={(data) => setlastName(data.target.value)} />
                                        <FormHelperText id="lastName-helper-text"></FormHelperText>
                                        <p>{errors.lastName?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="birth-date">Date of birth</InputLabel>
                                        <TextField variant="outlined" id="birth-date" aria-describedby="birth-date-helper-text" type="date" name="birthDate" inputRef={register} />
                                        <FormHelperText id="birth-date-helper-text">MM/DD/YYYY</FormHelperText>
                                        <p>{errors.birthDate?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="technology" shrink={false}>Do you use ear tech</InputLabel>
                                        <Controller
                                            as=
                                            {
                                                <Select variant="outlined" id="technology" aria-describedby="technology-helper-text">
                                                    <MenuItem value={1}>Yes</MenuItem>
                                                    <MenuItem value={0}>No</MenuItem>
                                                </Select>
                                            }
                                            name="technology"
                                            control={control}
                                            defaultValue={0}
                                        />
                                        <FormHelperText id="technology-helper-text">examples of ear tech: headphones, earphones, wireless buds, hearing devices etc.</FormHelperText>
                                        <p>{errors.technology?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="postcode">Post Code</InputLabel>
                                        <TextField variant="outlined" id="postCode" aria-describedby="postCode-helper-text" name="postCode" inputRef={register} />
                                        <FormHelperText id="postcode-helper-text">e.g. EC1A 1BB</FormHelperText>
                                        <p>{errors.postCode?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <Button variant="contained" className="btn-root rounded-pill btn-gradient w-100 text-white text-capitalize" size="large" type="submit">Update Profile</Button>
                                </Box>
                            </Grid>
                        </form>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}
export default UpdateProfile;
