import './assets/styles/icomoon.scss';
import './app.scss';
import Layout from "./components/layout/layout";
import { useEffect, useMemo } from 'react';
import { UserContext } from './context/user.context';
import React from 'react';
import { AuthService } from './services/auth.service';
import { UserProfileDataService } from './services/user-profile-data.service';
import { UserProfileDataInterface } from './models/user-profile-data.model';
import { UserProfileContext } from './context/user-profile.context';

// require('./assets/script/heap.js');

function App() {

    const userProfileDataService = new UserProfileDataService();  
    const [userData, setUserData] = React.useContext(UserContext);
    const [, setUserProfileData] = React.useContext(UserProfileContext);

    useEffect(() => {
        const authService = new AuthService();

        if ( !userData ) {
            authService.getAuthenticatedUser().then((user: any) => {
                setUserData(user);
            }).catch((err: any) => {
                console.log(err);
            });
        }
    });

    useMemo(() => {
        if ( userData ) {
            userProfileDataService.getUserProfileData(userData._id).then((userProfile: UserProfileDataInterface) => {
                setUserProfileData(userProfile);
            }).catch((err: any) => {
                console.log(err);
            });
        }
    }, [userData]);

    return (
        <Layout />
    );
}

export default App;
