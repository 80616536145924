import { Box, Button, Drawer, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import WellnessFeedList from '../wellness-feed-list/wellness-feed-list';
import './wellness-feed.scss';

function WellnessFeed() {

    return (

        <div className="feed-drawer-wrapper">
    
                <Drawer 
                    variant="permanent" 
                    anchor="top"
                    className="wellness-feed-header"
                >
                    <h1>Your wellness feed</h1>

                </Drawer>
                
                <WellnessFeedList />

        </div>
    );
}

export default WellnessFeed;
