import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { SnackbarContext } from "../../../../context/snackbar.context";
import { UserContext } from "../../../../context/user.context";
import { UserProfileDataInterface } from "../../../../models/user-profile-data.model";
import { UserProfileDataService } from "../../../../services/user-profile-data.service";
import { UserService } from "../../../../services/user.service";
import './tech-preferences.scss';

function TechPreferences() {

    const userProfileDataService = new UserProfileDataService();
    const [userData, setUserData] = React.useContext(UserContext);

    useEffect(() => {
        
        if ( userData ) {
            userProfileDataService.getUserProfileData(userData._id).then((userProfile: UserProfileDataInterface) => {
                if ( userProfile ) {
                    if ( userProfile.quiz ) {
                        const techQuiz = userProfile.quiz.find((item: any) => item.type === 'tech');
                        if ( techQuiz ) {
                            window.location.href = '/profile/tech-quiz'
                        }
                    }
                }
            }).catch((err: any) => {
                console.log(err);
            });
        }
      
    });

    return (
        <Box className="tech-preferences">
            <h1>
                <NavLink to="/profile" className="icon-right-arrow"></NavLink>
                Your ear tech preferences
            </h1>

            <p>
                Ear tech is a term we use to describe devices like headphones, earphones, earpods, or hearing devices. If it's a device that goes in or over your ears, it's a piece of ear tech.
                <br /><br />
                Let us know more about how you use ear tech so that we can give you some tailored tips to up your experience of sound through tech!
            </p>

            <NavLink to="/profile/tech-quiz">
                <Button className="btn-gradient" type="button">
                    Get your tech feed
                </Button>
            </NavLink>
        </Box>
    );
}

export default TechPreferences;