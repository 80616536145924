import './venues-for-me.scss';
import React, { useState, useEffect } from 'react';
import {
    Box, Button, Container, Card, CardContent, CardMedia, Drawer, IconButton, Typography, FormControl,
    FormControlLabel,
    FormGroup,
    RadioGroup,
    Radio,
    makeStyles,
    TextField,
    Divider,
    InputBase,
    Paper,
    createStyles,
    Theme,
} from '@material-ui/core';
import { MdClose, MdKeyboardArrowRight, MdKeyboardArrowLeft, MdVerified } from 'react-icons/md';
import { BsDot } from 'react-icons/bs';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { searchPlaces } from '../../../../axios/api';
import { BsBookmark } from 'react-icons/bs';
import cardBg from '../../../../assets/img/why-its-time.jpg';
import certifiedIcon from '../../../../assets/img/certified-icon.svg';
import { BsSearch } from 'react-icons/bs';
import { IoFilterSharp } from 'react-icons/io5';

const distanceBetweenPoints = require("distance-between-geocoordinates");

const useStyles = makeStyles({
    root: {
        width: '100%',
        justifyContent: 'space-between',
        margin: '0 0 16px 0',
    },
    customBox: {
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 2,
        wordBreak: "break-all",
        overflow: "hidden"
    }
});

const cardStyles = makeStyles({
    statusSymbol: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 6,
        paddingBottom: 2,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 7,
        backgroundColor: 'white',
        width: '92px',
        borderRadius: 6
    },
    statusVerified: {
        marginTop: -4,
        paddingRight: 3
    },
    statusCertified: {
        marginTop: -3,
        height: '16px',
        width: '16px',
        paddingRight: 3
    },
    statusText: {
        fontSize: 14
    },
    cardStyle: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    vibeStyle: {
        fontSize: 16
    },
    bulletStyle: {
        marginTop: -15,
        marginBottom: -7,
        marginInline: -8
    }
});

const searchStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            maxWidth: 400,
            margin: '0 auto'
        },
        input: {
            // marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        divider: {
            height: 32,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);


function VenuesForMe(props: any) {
    const classes = useStyles();
    const searchClasses = searchStyles();
    const cardClasses = cardStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [filterDrawer, setFilterDrawer] = useState<boolean>(false);
    const [places, setPlaces] = useState<any[]>([]);
    const [userLocation, setUserLocation] = useState({
        lat: 0,
        lng: 0
    })
    const [location, setLocation] = useState({
        "sw": {
            "lon": 0,
            "lat": 0
        },
        "ne": {
            "lon": 0,
            "lat": 0
        }
    });
    const [venue, setVenue] = useState({
        address: '',
    });

    const [updateCenter, setUpdateCenter] = useState({
        lat: 0,
        lng: 0
    });

    const [venueData, setVenueData] = React.useState({
        "venueType": "",
        "venueTypeText": "",
        "crowdedness": "",
        "vibe": "",
        "conversation": "",
        "conversationText": ""
    });



    useEffect(() => {
        getLocation();
    }, [])

    useEffect(() => {
        if (location.sw.lat !== 0) {
            locationSearch(location.sw.lat, location.sw.lon, location.ne.lat, location.ne.lon);
            updateUserLocation();
        }
    }, [location.sw.lat])

    const getLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setUpdateCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });

            setUserLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });
            // console.log('use effect called...');
        }, (err) => {
            console.log(err);
        }
        );
    };

    const updateUserLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setUserLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });
        }, (err) => {
            console.log(err);
        }
        );
    };

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setFilterDrawer(open);
    };


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (address: any) => {
        setVenue({
            ...venue,
            address: address
        });
    }

    const handleSelect = (address: any) => {
        try {
            geocodeByAddress(address).then(results => getLatLng(results[0])).then(latLng => {
                setVenue({
                    address: address,
                });
                setUpdateCenter({
                    lat: latLng.lat,
                    lng: latLng.lng
                });
                findVenues(userLocation.lat, userLocation.lng);
            });
        }
        catch (err) {
            console.log(err);
        }
    }


    const onRecenter = (mapProps: any, map: any) => {
        google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
            var bounds = map.getBounds();
            var ne = bounds.getNorthEast(); // LatLng of the north-east corner
            var sw = bounds.getSouthWest();
            setLocation({
                "sw": {
                    "lon": map.getBounds().getSouthWest().lng(),
                    "lat": map.getBounds().getSouthWest().lat()
                },
                "ne": {
                    "lon": map.getBounds().getNorthEast().lng(),
                    "lat": map.getBounds().getNorthEast().lat()
                }
            });
            // console.log("Recenter", sw.lat())
        })
    }

    const getInitialBounds = (mapProps: any, map: any) => {
        google.maps.event.addListenerOnce(map, 'idle', function () {
            var bounds = map.getBounds();
            var ne = bounds.getNorthEast(); // LatLng of the north-east corner
            var sw = bounds.getSouthWest();
            setLocation({
                "sw": {
                    "lon": map.getBounds().getSouthWest().lng(),
                    "lat": map.getBounds().getSouthWest().lat()
                },
                "ne": {
                    "lon": map.getBounds().getNorthEast().lng(),
                    "lat": map.getBounds().getNorthEast().lat()
                }
            });
            setUpdateCenter({
                lat: map.getCenter().lat(),
                lng: map.getCenter().lng()
            });
            // console.log("Initials", sw.lat())
        })
    }

    // const onDragend = (mapProps: any, map: any) => {
    //     var bounds = map.getBounds();
    //     var ne = bounds.getNorthEast(); // LatLng of the north-east corner
    //     var sw = bounds.getSouthWest();
    //     setLocation({
    //         "sw": {
    //             "lon": sw.lng(),
    //             "lat": sw.lat()
    //         },
    //         "ne": {
    //             "lon": ne.lng(),
    //             "lat": ne.lat()
    //         }
    //     });
    //     locationSearch(sw.lat(), sw.lng(), ne.lat(), ne.lng());
    // }

    const onBoundsChanged = (mapProps: any, map: any) => {
        google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
            var bounds = map.getBounds();
            var ne = bounds.getNorthEast(); // LatLng of the north-east corner
            var sw = bounds.getSouthWest();
            setLocation({
                "sw": {
                    "lon": map.getBounds().getSouthWest().lng(),
                    "lat": map.getBounds().getSouthWest().lat()
                },
                "ne": {
                    "lon": map.getBounds().getNorthEast().lng(),
                    "lat": map.getBounds().getNorthEast().lat()
                }
            });
            // console.log("Bounds", sw.lat())
        });
    }


    const onZoomChanged = (mapProps: any, map: any) => {
        google.maps.event.addListener(map, 'zoom_changed', function () {
            var bounds = map.getBounds();
            var ne = bounds.getNorthEast(); // LatLng of the north-east corner
            var sw = bounds.getSouthWest();
            setLocation({
                "sw": {
                    "lon": map.getBounds().getSouthWest().lng(),
                    "lat": map.getBounds().getSouthWest().lat()
                },
                "ne": {
                    "lon": map.getBounds().getNorthEast().lng(),
                    "lat": map.getBounds().getNorthEast().lat()
                }
            });
            // console.log("Zoom", sw.lat())
        });
    }


    const mapClicked = (venueName: any) => {
        // window.open("https://maps.google.com?q=" + clickLat + "," + clickLng);
        window.open("https://maps.google.com?q=" + venueName);
    }

    const handleCheckboxTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVenueData({ ...venueData, venueType: event.target.value });
    };

    const handleCrowdednessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVenueData({ ...venueData, crowdedness: event.target.value });
    };

    const handleAmbienceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVenueData({ ...venueData, vibe: event.target.value });
    };

    const handleConversationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVenueData({ ...venueData, conversation: event.target.value });
    };



    const locationSearch = (swLat: any, swLng: any, neLat: any, neLng: any, vd = venueData) => {
        const data = {
            "location": {
                "sw": {
                    "lon": swLng,
                    "lat": swLat
                },
                "ne": {
                    "lon": neLng,
                    "lat": neLat
                }
            },
            "filtering": {
                "crowdedness": Boolean(vd.crowdedness) ? vd.crowdedness : undefined,
                "venueType": Boolean(vd.venueType) ? vd.venueType : undefined,
                "vibe": Boolean(vd.vibe) ? vd.vibe : undefined,
                "conversation": Boolean(vd.conversation) ? vd.conversation : undefined,
                "venueTypeText": Boolean(vd.venueTypeText) ? vd.venueTypeText : undefined,
                "conversationText": Boolean(vd.conversationText) ? vd.conversationText : undefined
            }
        }

        searchPlaces(data, {

            onSuccess: (res) => {
                if (res.data !== null) {
                    for (var i = 0; i < res.data.length; i++) {
                        var point1 = {
                            lat: res.data[i].location.coordinates[1],
                            lng: res.data[i].location.coordinates[0]
                        }
                        var distance = distanceBetweenPoints(point1, userLocation);
                        // console.log("distance", distance)
                        res.data[i].dist_from_center = distance.distance.toFixed(2) + " " + distance.unit + " away";
                        // console.log("First", res.data[0].dist_from_center)

                    }
                    setPlaces(
                        res.data
                    )
                }
            },
            onError: (res) => {
                console.log(res)
            }
        })
    }

    const findVenues = (intialLat: any, initialLng: any, vd = venueData) => {
        const data = {
            "location": {
                "sw": {
                    "lon": location.sw.lon,
                    "lat": location.sw.lat
                },
                "ne": {
                    "lon": location.ne.lon,
                    "lat": location.ne.lat
                }
            },
            "filtering": {
                "crowdedness": Boolean(vd.crowdedness) ? vd.crowdedness : undefined,
                "venueType": Boolean(vd.venueType) ? vd.venueType : undefined,
                "vibe": Boolean(vd.vibe) ? vd.vibe : undefined,
                "conversation": Boolean(vd.conversation) ? vd.conversation : undefined,
                "venueTypeText": Boolean(vd.venueTypeText) ? vd.venueTypeText : undefined,
                "conversationText": Boolean(vd.conversationText) ? vd.conversationText : undefined
            }
        }
        searchPlaces(data, {
            onSuccess: (res) => {
                console.log("Search Response", res.data[0])
                if (res.data !== null) {
                    for (var i = 0; i < res.data.length; i++) {
                        var point1 = {
                            lat: res.data[i].location.coordinates[1],
                            lng: res.data[i].location.coordinates[0]
                        }
                        var distance = distanceBetweenPoints(point1, userLocation);
                        res.data[i].dist_from_center = distance.distance.toFixed(2) + " " + distance.unit + " away";
                    }
                    setPlaces(
                        res.data
                    )
                }
            },
            onError: (res) => {
                console.log(res)
            }
        })
    }


    const VenueFormStep = () => {

        const handleVenueTypeText = (event: React.ChangeEvent<HTMLInputElement>) => {
            setVenueData({ ...venueData, venueTypeText: event.target.value });
        };

        const handleConversationTypeText = (event: React.ChangeEvent<HTMLInputElement>) => {
            setVenueData({ ...venueData, conversationText: event.target.value });
        };

        if (activeStep === 0) {

            return (
                <>
                    <FormControl component="fieldset" className="d-block">
                        <RadioGroup aria-label="venueType" name="venueType" value={venueData.venueType} onChange={handleCheckboxTypeChange}>
                            <Box my={2}>
                                <Typography variant="h5" component="h3" color="primary" className="font-weight-700">Type of venue</Typography>
                                <Typography variant="body1" component="span" color="primary" >What kind of venue is this?</Typography>
                            </Box>

                            <FormGroup className="text-left">

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="cafe" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Cafe</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block"></Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="restaurant" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Restaurant</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block"></Typography>
                                        </>
                                    }
                                    labelPlacement="start" />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="bar" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Bar or Pub</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block"></Typography>
                                        </>
                                    }
                                    labelPlacement="start" />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="other" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Other</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block"></Typography>
                                        </>
                                    }
                                    labelPlacement="start" />
                                {venueData.venueType === "other" &&
                                    <FormControl fullWidth>
                                        <TextField variant="outlined" id="venueTypeText" aria-describedby="venueTypeText-helper-text" type="text" autoFocus name="venueTypeText" value={venueData.venueTypeText !== null ? venueData.venueTypeText : ""} placeholder="Mention any venue type" onChange={handleVenueTypeText} />
                                    </FormControl>}
                            </FormGroup>
                        </RadioGroup>
                    </FormControl>
                </>
            )
        }

        if (activeStep === 1) {
            return (
                <>
                    <FormControl component="fieldset" className="d-block">
                        <RadioGroup aria-label="crowdedness" name="crowdedness" value={venueData.crowdedness} onChange={handleCrowdednessChange}>
                            <Box my={2}>
                                <Typography variant="h5" component="h2" color="primary" className="font-weight-700">Crowdedness</Typography>
                                <Typography variant="body1" component="span" color="primary" >How many people are around?</Typography>
                            </Box>

                            <FormGroup className="text-left">
                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="not_crowded" />
                                    } label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Not crowded</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Lots of seats available</Typography>
                                        </>
                                    } labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="not_too_crowded" />
                                    } label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Not too crowded</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Some seats available</Typography>
                                        </>
                                    } labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="crowded" />
                                    } label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Crowded</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Limited seating</Typography>
                                        </>
                                    } labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="very_crowded" />
                                    } label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Very crowded</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">No seats left</Typography>
                                        </>
                                    } labelPlacement="start"
                                />
                            </FormGroup>
                        </RadioGroup>
                    </FormControl >
                </>
            )
        }

        if (activeStep === 2) {
            return (
                <>
                    <FormControl component="fieldset" className="d-block">
                        <RadioGroup aria-label="vibe" name="vibe" value={venueData.vibe} onChange={handleAmbienceChange}>
                            <Box my={2}>
                                <Typography variant="h5" component="h2" color="primary" className="font-weight-700">Ambience</Typography>
                                <Typography variant="body1" component="span" color="primary" >What is it like inside the venue?</Typography>
                            </Box>

                            <FormGroup className="text-left">
                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="calm" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Calm</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Soft music and chatter, it feels cosy, warm</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="buzzy" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Buzzy</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Upbeat music and louder chatter, it feels lively</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="energetic" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Energetic</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Loud music and/or voices, it feels a bit wild but fun</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="overwhelming" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Overwhelming</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">So much music and chatter that it feels more stressful than fun</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </RadioGroup>
                    </FormControl>
                </>
            )
        }

        if (activeStep === 3) {
            return (
                <>
                    <FormControl component="fieldset" className="d-block">
                        <RadioGroup aria-label="conversation" name="conversation" value={venueData.conversation} onChange={handleConversationChange}>
                            <Box my={2}>
                                <Typography variant="h5" component="h2" color="primary" className="font-weight-700">Conversation</Typography>
                                <Typography variant="body1" component="span" color="primary" >How easy is it to have a conversation?</Typography>
                            </Box>
                            <FormGroup className="text-left">
                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="easy" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Easy</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Very smooth conversation</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="some_difficulty" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Some difficulty</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Occasionally have to repeat yourself</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="hard" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Hard</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Speaking with a raised voice, hard to hear, repeating yourself often</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />

                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="very_hard" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Very hard</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Have to shout, consistently need to repeat yourself</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    className={classes.root}
                                    control={
                                        <Radio color="primary" value="other" />
                                    }
                                    label={
                                        <>
                                            <Typography color="primary" component="span" variant="h6">Other</Typography>
                                            <Typography color="primary" component="span" variant="body1" className="d-block">Mention others</Typography>
                                        </>
                                    }
                                    labelPlacement="start"
                                />
                                {venueData.conversation === "other" &&
                                    <FormControl fullWidth>
                                        <TextField variant="outlined" id="conversationText" aria-describedby="conversationText-helper-text" type="text" autoFocus name="conversationText" value={venueData.conversationText !== null ? venueData.conversationText : ""} placeholder="Mention any conversation type" onChange={handleConversationTypeText} />
                                    </FormControl>}
                            </FormGroup>
                        </RadioGroup>
                    </FormControl>
                </>
            )
        }

        return null;
    }
    const filter = () => {
        // console.log("Filter State", venueData)
        findVenues(userLocation.lat, userLocation.lng);
        setFilterDrawer(false);
    }

    const clearAll = () => {
        // console.log("clear all filter")
        const vd = {
            "venueType": "",
            "venueTypeText": "",
            "crowdedness": "",
            "vibe": "",
            "conversation": "",
            "conversationText": ""
        };
        setVenueData(vd)
        // console.log("Clear State", vd)
        findVenues(userLocation.lat, userLocation.lng, vd);
        setFilterDrawer(false);

    }

    const changeCase = (string: any) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <Box className="venues-for-me top-header-spacing">
            <Drawer
                variant="persistent"
                anchor="left"
                open={true}
            >
                <Box textAlign="center" mb={2}>
                    <Typography variant="h4" component="h1">List of venues</Typography>
                </Box>
                <Box className="card-list">
                    {places.map((place, index) => (
                        <Card className="card" elevation={0} key={place._id}>

                            <CardMedia
                                className="img"
                                image={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=1000&photo_reference=${place.photoRef}&key=${process.env.REACT_APP_C_KEY}`}
                            // title="Location_Image"
                            >
                                {place.status !== "" && (<Box component="div" className={cardClasses.statusSymbol}>
                                    {place.status === 'verified' && (<MdVerified size={17} color='orange' className={cardClasses.statusVerified} />)}
                                    {place.status === 'certified' && (<img src={certifiedIcon} className={cardClasses.statusCertified} alt="icon" />)}
                                    <h5 className={cardClasses.statusText}>{place.status.toUpperCase()}</h5>
                                </Box>)}
                            </CardMedia>
                            <CardContent className={cardClasses.cardStyle}>
                                <Box component="div">
                                    <Typography className={classes.customBox} component="h2" variant="h6">{places[index].name}</Typography>
                                </Box>
                                <Box mb={0}>
                                    <Typography className={cardClasses.vibeStyle} color="primary">{changeCase(places[index].vibe)} <BsDot size={27} color='primary' className={cardClasses.bulletStyle} /> {changeCase(places[index].crowdedness)}</Typography>
                                </Box>
                                <Box mb={1}>
                                    <Typography variant="body1" color="primary">{places[index].dist_from_center}</Typography>
                                </Box>
                                <Button type="button" color="primary" variant="contained" className="btn-root rounded-pill text-capitalize" disableElevation onClick={() => mapClicked(place.address)}>Open in Google Maps</Button>
                            </CardContent>
                            {/* <Box alignSelf="flex-start">
                                <IconButton color="primary">
                                    <BsBookmark fontSize="16" />
                                </IconButton>
                            </Box> */}
                        </Card>
                    ))}
                </Box>
            </Drawer>
            <main>
                <Map
                    google={props.google}
                    initialCenter={{
                        lat: 0,
                        lng: 0
                    }}
                    center={updateCenter}
                    zoom={10}
                    minZoom={10}
                    maxZoom={15}
                    onBoundsChanged={onBoundsChanged}
                    // onDragend={onDragend}
                    onReady={getInitialBounds}
                    onZoomChanged={onZoomChanged}
                    onRecenter={onRecenter}
                >

                    <PlacesAutocomplete
                        value={venue.address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <Box textAlign="center" mx={1} mt={8}>

                                <Paper component="form" className={`rounded-pill bg-white position-relative ${searchClasses.root}`}>
                                    <IconButton color="primary" className={searchClasses.iconButton} aria-label="search">
                                        <BsSearch size="20" />
                                    </IconButton>
                                    <InputBase
                                        className={searchClasses.input}
                                        inputProps={{ 'aria-label': 'Search Venues ...' }}
                                        {...getInputProps({
                                            placeholder: 'Search Venues ...',
                                        })}
                                    />
                                    <Divider className={searchClasses.divider} orientation="vertical" />
                                    <IconButton color={(venueData.crowdedness === '' && venueData.venueType === '' && venueData.vibe === '' && venueData.conversation === '') ? 'primary' : 'secondary'} className={searchClasses.iconButton} aria-label="filter" onClick={toggleDrawer(true)}>
                                        <IoFilterSharp size="20" />
                                    </IconButton>
                                </Paper>

                                <Box className="autocomplete-dropdown-container position-relative MuiPaper-elevation1">
                                    {loading && <Box p={2} textAlign="center">Loading...</Box>}
                                    {suggestions.map(suggestion => {
                                        // console.log(suggestions, 'suggestions');
                                        return (
                                            <Box px={2} py={1} {...getSuggestionItemProps(suggestion)}>
                                                <span>{suggestion.description}</span>
                                            </Box>
                                        );
                                    })}
                                </Box>

                            </Box>
                        )}
                    </PlacesAutocomplete>

                    <Marker
                        icon={"https://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
                        title={"Your Location"}
                        position={{
                            lat: userLocation.lat,
                            lng: userLocation.lng
                        }}
                    />
                    {places.map(place => {
                        return (
                            <Marker key={place._id}
                                title={place.name}
                                position={{
                                    lat: place.location.coordinates[1],
                                    lng: place.location.coordinates[0]
                                }} />
                        );
                    })}

                </Map>
            </main>

            <Drawer
                className="share-drawer"
                variant="temporary"
                anchor="bottom"
                open={filterDrawer}
                onClose={toggleDrawer(false)}
            >
                <Box position="absolute" right={8} top={8}>
                    <IconButton color="primary" onClick={toggleDrawer(false)}>
                        <MdClose size="24" />
                    </IconButton>
                </Box>

                <Container maxWidth="xs">
                    <Box my={3} textAlign="center">
                        <Box className="drawer-header">
                            <Typography variant="h4" component="h2" className="font-weight-700">Filter</Typography>
                        </Box>
                        <Container maxWidth="xs">
                            <Box flexGrow={1}>
                                <form >
                                    <VenueFormStep />
                                </form>
                            </Box>
                            <Box display="flex" justifyContent={activeStep === 0 ? 'flex-end' : 'space-between'} alignItems="center">
                                {activeStep === 0 ? null : (
                                    <IconButton aria-label="delete" onClick={handleBack} color="primary">
                                        <MdKeyboardArrowLeft size="32" />
                                    </IconButton>
                                )}
                                <>
                                    <Box>
                                        <Button type="button" variant="contained" className="btn-root rounded-pill btn-gradient text-white text-capitalize" size="large" disableElevation onClick={clearAll}>Clear</Button>
                                        <Button type="button" variant="contained" className="btn-root rounded-pill btn-gradient text-white text-capitalize ml-3" size="large" disableElevation onClick={filter}>Apply</Button>
                                    </Box>
                                </>

                                {activeStep === 3 ? null : (
                                    <IconButton aria-label="delete" onClick={handleNext} color="primary">
                                        <MdKeyboardArrowRight size="32" />
                                    </IconButton>
                                )}
                            </Box>
                        </Container>
                    </Box>
                </Container >
            </Drawer >
        </Box >
    );
};
export default GoogleApiWrapper({
    apiKey: `${process.env.REACT_APP_C_KEY}`
})(VenuesForMe)