import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../components/privat-route/privat-route';
import WellnessFeed from './components/wellness-feed/wellness-feed';
import WellnessQuiz from './components/wellness-quiz/wellness-quiz';
import './wellness.scss';

function WellnessPage() {

    let { path, url } = useRouteMatch();

    if (false) {
        return (<WellnessQuiz />);
    }

    return (
        <Switch>
            <PrivateRoute path={`${path}/`} component={WellnessFeed} />
        </Switch>
    );

}

export default WellnessPage;
