import { FormControl, InputLabel, TextField, Button, Grid, Container, Typography, Box } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { FcGoogle } from 'react-icons/fc';
import './login.scss';
import { login } from '../../../../axios/api';
import { SnackbarContext } from '../../../../context/snackbar.context';
import * as CryptoJS from 'crypto-js';

const loginFormSchema = Yup.object().shape({
    email: Yup.string().required("Please enter your email").email('Please enter correct email.'),
    password: Yup.string().required("Please enter your password"),
});


function LoginComponent(props: any) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(loginFormSchema)
    });

    const [, setSnackbarData] = React.useContext(SnackbarContext);

    const successLogin = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'Successfully Logged In'
        };

        setSnackbarData(snackbar);
    }
    const verifyEmail = (message: any) => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'warning',
            snackbarText: message
        };

        setSnackbarData(snackbar);
    }

    const loginError = (message: any) => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: message
        };
        setSnackbarData(snackbar);
    }

    const handleLogin = () => {
        const credentials =
        {
            "email": email,
            "password": password,
            "strategy": "local"
        };
        //API CALL
        login(credentials, {
            onSuccess: (res) => {
                let auth_token = res.data.accessToken;
                const secret: any = process.env.REACT_APP_SECRET_KEY;
                var newAuth = CryptoJS.AES.encrypt(JSON.stringify(auth_token), secret).toString();
                localStorage.setItem("newAuth", newAuth);
                localStorage.setItem("email", email);
                localStorage.setItem("permissions", res.data.user.permissions[0]);
                successLogin();
                props.history.push('/profile');
                localStorage.setItem("userId", res.data.user._id);
                localStorage.removeItem("email");
            },
            onError: (res) => {
                if (res.data.message === "Verify your email first") {
                    const verified = res.data.data.isEmailVerified;
                    if (verified === false) {
                        verifyEmail(res.data.message)
                        props.history.push('/auth/otp-verify')
                    }
                }
                else {
                    loginError(res.data.message)
                }
            }
        })
    }

    return (
        <>
            <Box p={3} className="auth-form-header">
                <span className="icon-mumbli-logo-2"></span>
                <Typography variant="h2" component="div">Login</Typography>
            </Box>
            <Container maxWidth="xs" className="signup-content">
                <Box py={4}>
                    <form className="signup-form" onSubmit={handleSubmit(handleLogin)}>
                        <Box mb={4}>
                            <Typography variant="h4" component="h1" className="font-weight-700" color="primary">Login to your Mumbli account.</Typography>
                        </Box>

                        <Grid>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>Email</InputLabel>
                                        <TextField variant="outlined" id="email" aria-describedby="email-helper-text" type="text" name="email" inputRef={register} onChange={(e) => setEmail(e.target.value)} />
                                        <p>{errors.email?.message}</p>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid>
                                <Box mb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <TextField variant="outlined" id="password" aria-describedby="password-helper-text" type="password" name="password" inputRef={register} onChange={(e) => setPassword(e.target.value)} />
                                        <p >{errors.password?.message}</p>
                                    </FormControl>
                                </Box>

                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <Link to="forgotpassword" className="pure-link">Forgot Password</Link>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <Button type="submit" variant="contained" className="btn-root rounded-pill btn-gradient w-100 text-white text-capitalize" size="large" disableElevation>Login</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={3}>
                                    <a href={process.env.REACT_APP_API_URL + "/oauth/google"}>
                                        <Button startIcon={<FcGoogle />} type="button" variant="outlined" color="primary" className="btn-root rounded-pill w-100 text-capitalize" size="large" disableElevation>Sign in with Google</Button>
                                    </a>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Link to="/auth/signup" className="pure-link">
                                    Don’t have an account? Sign up
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Container>
        </>
    );
}

export default LoginComponent;