import React from "react";
import { SnackbarContext } from "../../context/snackbar.context";
import * as feathersConfig from "../../feathers.config.json";

const feathers = require('@feathersjs/feathers');
const socketio = require('@feathersjs/socketio-client');
const io = require('socket.io-client');
const auth = require('@feathersjs/authentication-client');

const socket = io(feathersConfig.ioUrl);
const FeathersService = feathers();


FeathersService.configure(socketio(socket));

FeathersService.configure(auth({
  storageKey: 'auth'
}));
/*
FeathersService.hooks({
    error(hook: any) {
        const [snackbarData, setSnackbarData] = React.useContext(SnackbarContext);
        console.log('HOOK !!!');
        console.log(hook);
    }
})
*/
export default FeathersService;
