import React, { useState } from "react";
import { UserProfileDataInterface } from "../models/user-profile-data.model";

const initUserProfileState: UserProfileDataInterface | null = null;

export const UserProfileContext = React.createContext<any>(initUserProfileState);

const UserProfileStore = ({children}: any) => {
    const [userProfileData, setUserProfileData] = useState(initUserProfileState);
    
    return (
        <UserProfileContext.Provider value={[userProfileData, setUserProfileData]}>
            {children}
        </UserProfileContext.Provider>
    );
};

export default UserProfileStore;