import './venues-google-map.scss';
import { PlaceInterface } from '../../../../models/place.model';
import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { VenueContext } from '../../../../context/venue.context';
import { GoogleService } from '../../../../services/google.service';
import markerIcon from '../../../../assets/img/placeholder.svg';

const googleService = new GoogleService();

function CustomMarker(props: {venue: PlaceInterface, lat: number, lng: number}) {

    const [venueData, setVenueData] = React.useContext(VenueContext);

    const venue = props.venue;
    const classNames: string = "icon-placeholder" + (venue._id == venueData?._id ? " active" : ""); 

    if ( venue.location && venue.location.length > 0 && venue.state === 'active' ) {
        return (
            <div className={classNames} onClick={() => setVenueData(venue)}></div>
        )
    }

    return null;
}

function VenuesGoogleMap(props: any) {
console.log(props);
    const [googleMapLoader, setGoogleMapLoader] = React.useState<any>(googleService.getGoogleLoader());
    const [googleMap, setGoogleMap] = React.useState<any>(null);
    const [googleMapApi, setGoogleMapApi] = React.useState<any>(null);
    const [venueData, setVenueData] = React.useContext(VenueContext);
    const venueList: PlaceInterface[] = props.venueList;
    const mapRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        if ( googleMap !== null && venueData && venueData.location && venueData.location.length > 0 ) {
            if ( googleMap?.center ) {
                googleMap.setCenter({ lat: parseFloat(venueData.location[0]), lng: parseFloat(venueData.location[1]) });
            }
        }
    }, [venueData]);

    useEffect(() => {
        if ( venueList && venueData ) {
            if ( !venueList.includes(venueData) ) {
                setVenueData(venueList[0]);
            }
        }
    }, [venueList]);


    useEffect(() => {
        googleMapLoader.then((google: any) => {

            setGoogleMapApi(google);
            
            const mapElement = document.getElementById('venue-google-map');

            const gmap = new google.maps.Map(mapElement, {
                center: { lat: 0, lng: 0 },
                zoom: 16
            });

            setGoogleMap(gmap);
        });
    }, []); 

    useEffect(() => {
        if (venueList.length > 0 && googleMap !== null) {
            venueList.map((venue: PlaceInterface) => {

                const markerPosition = {
                    lat: venue.location ? parseFloat(venue.location[0]) : 0,
                    lng: venue.location ? parseFloat(venue.location[1]) : 0
                };

                const marker = new googleMapApi.maps.Marker({
                    position: markerPosition,
                    setIcon: {
                        url: markerIcon, /*
                        size: new googleMapApi.maps.Size(50, 70),
                        origin: new googleMapApi.maps.Point(0, 0),
                        anchor: new googleMapApi.maps.Point(0, 32),

                        // fillColor: '#0f4c81' */
                    }
                });

                marker.addListener('click', (e: any) => {
                    setVenueData(venue);
                });

                marker.setMap(googleMap);
            });
        }
    }, [venueList, googleMap])

    return (
        <Box className="google-map-wrapper">
            <div id="venue-google-map" ref={mapRef}></div>
        </Box>
    );
}

export default VenuesGoogleMap;
