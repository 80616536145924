import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute(props: any) {
    
    const { component: Component, ...rest } = props;

    if ( localStorage.getItem("newAuth") ) {
        return (
            <Route {...rest} render={(props) => (<Component {...props}/>)} />
        );
    }

    return <Redirect to='/auth/login' />
}