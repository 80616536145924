import React from "react"
import RawHTMLRenderer from "../../utils/RawHTMLRenderer"    


function PrivacyPolicy(){

    const rawHTML = `<!DOCTYPE html>
    <html>
    
    <head>
        <meta charset="UTF-8">
        <title>Privacy Statement (UK)</title>
        <style>
        /* CSS for top header spacing */
        .top-header-spacing {
            padding-top: 183px;
        }

        @media screen and (max-width: 1024px) {
            .top-header-spacing {
                padding-top: 164px;
            }
        }
    </style>
    </head>
    
    <body>
        <div style="margin-inline: 2rem;" class="top-header-spacing">
        <h1 style="font-size: 3rem;margin-top:2rem">Privacy Statement (UK)</h1>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;" style="font-size: 1.6rem; margin-top: 0.8rem;">This privacy statement was last updated on February 7, 2022 and applies to citizens and legal permanent residents of the United Kingdom.</p>
    
        
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">In this privacy statement, we explain what we do with the data we obtain about you via <a href="https://mumbli.com">https://mumbli.com</a> We recommend you carefully read this statement. In our processing we comply with the requirements of privacy legislation. That means, among other things, that: </p>
    
        <ul>
            <li style="font-size: 1.6rem;">we clearly state the purposes for which we process personal data. We do this by means of this privacy statement.</li>
            <li style="font-size: 1.6rem;">we aim to limit our collection of personal data to only the personal data required for legitimate purposes.</li>
            <li style="font-size: 1.6rem;">we first request your explicit consent to process your personal data in cases requiring your consent.</li>
            <li style="font-size: 1.6rem;">we take appropriate security measures to protect your personal data and also require this from parties that process personal data on our behalf.</li>
            <li style="font-size: 1.6rem;">we respect your right to access your personal data or have it corrected or deleted, at your request.</li>
        </ul>
        
    
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">If you have any questions, or want to know exactly what data we keep of you, please contact us.</p>
    
        <h2 style="font-size: 2rem; margin-top: 2.5rem">1. Purpose, data and retention period</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">We may collect or receive personal information for a number of purposes connected with our business operations which may include the following:</p>
    
        <h3 style="font-size: 1.8rem; margin-top: 1.5rem;">1.1 Contact - Through phone, mail, email and/or webforms</h3>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">For this purpose we use the following data:</p>
        <ul>
            <li style="font-size: 1.6rem;">Name, Address and City</li>
            <li style="font-size: 1.6rem;">Email address</li>
        </ul>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">The basis on which we may process these data is:</p>
        <ul>
            <li style="font-size: 1.6rem;"><a href="https://cookiedatabase.org/legal-bases/#consent">Upon the provision of consent.</a></li>
        </ul>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">Retention period</p>
        <ul>
            <li style="font-size: 1.6rem;">We retain this data until the service is terminated.</a></li>
        </ul>

        <h3 style="font-size: 1.8rem; margin-top: 1.5rem;">1.2 Newsletters</h3>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">For this purpose we use the following data:</p>
        <ul>
            <li style="font-size: 1.6rem;">Name, Address and City</li>
            <li style="font-size: 1.6rem;">Email address</li>
        </ul>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">The basis on which we may process these data is:</p>
        <ul>
            <li style="font-size: 1.6rem;"><a href="https://cookiedatabase.org/legal-bases/#consent">Upon the provision of consent.</a></li>
        </ul>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">Retention period</p>
        <ul>
            <li style="font-size: 1.6rem;">We retain this data until the service is terminated.</a></li>
        </ul>

        <h2 style="font-size: 2rem; margin-top: 2.5rem">2. Cookies</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">Our website uses cookies. For more information about cookies, please refer to our <a href="https://www.mumbli.com/cookie-policy-uk">Cookie Policy.</a></p>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">We have concluded a data processing agreement with Google.</p>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">Google may not use the data for any other Google services.</p>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">The inclusion of full IP addresses is blocked by us.</p>

        <h2 style="font-size: 2rem; margin-top: 2.5rem">3. Security</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">We are committed to the security of personal data. We take appropriate security measures to limit abuse of and unauthorised access to personal data. This ensures that only the necessary persons have access to your data, that access to the data is protected, and that our security measures are regularly reviewed.</p>

        <h2 style="font-size: 2rem; margin-top: 2.5rem">4. Third-party websites</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">This privacy statement does not apply to third-party websites connected by links on our website. We cannot guarantee that these third parties handle your personal data in a reliable or secure manner. We recommend you read the privacy statements of these websites prior to making use of these websites.</p>

        <h2 style="font-size: 2rem; margin-top: 2.5rem">5. Amendments to this privacy statement</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">We reserve the right to make amendments to this privacy statement. It is recommended that you consult this privacy statement regularly in order to be aware of any changes. In addition, we will actively inform you wherever possible.</p>

        <h2 style="font-size: 2rem; margin-top: 2.5rem">6. Accessing and modifying your data</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">If you have any questions or want to know which personal data we have about you, please contact us. You can contact us by using the information below. You have the following rights:</p>
        
        <ul>
            <li style="font-size: 1.6rem;">You have the right to know why your personal data is needed, what will happen to it, and how long it will be retained for.</li>
            <li style="font-size: 1.6rem;">Right of access: You have the right to access your personal data that is known to us.</li>
            <li style="font-size: 1.6rem;">Right to rectification: you have the right to supplement, correct, have deleted or blocked your personal data whenever you wish.</li>
            <li style="font-size: 1.6rem;">If you give us your consent to process your data, you have the right to revoke that consent and to have your personal data deleted.</li>
            <li style="font-size: 1.6rem;">Right to transfer your data: you have the right to request all your personal data from the controller and transfer it in its entirety to another controller.</li>
            <li style="font-size: 1.6rem;">Right to object: you may object to the processing of your data. We comply with this, unless there are justified grounds for processing.</li>
        </ul>
        
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">Please make sure to always clearly state who you are, so that we can be certain that we do not modify or delete any data of the wrong person.</p>
        
        <h2 style="font-size: 2rem; margin-top: 2.5rem">7. Submitting a complaint</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;margin-bottom: 0.8rem;">If you are not satisfied with the way in which we handle (a complaint about) the processing of your personal data, you have the right to submit a complaint to the Information Commissioner's Office:</p>
        <address style="font-size: 1.6rem;">
            Wycliffe House<br>
            Water Lane<br>
            25 Kingsland Road<br>
            Wilmslow<br>
            Cheshire<br>
            SK9 5AF<br>
        </address>

        <h2 style="font-size: 2rem; margin-top: 2.5rem">8. Children</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;">Our website is not designed to attract children and it is not our intent to collect personal data from children under the age of consent in their country of residence. We therefore request that children under the age of consent do not submit any personal data to us.</p>
        
        <h2 style="font-size: 2rem; margin-top: 2.5rem">9. Contact details</h2>
        <p style="font-size: 1.6rem; margin-top: 0.8rem;margin-bottom: 0.8rem;">For questions and/or comments about our Cookie Policy and this statement, please contact us by using the following
            contact details:</p>
        <address>
            Mumbli<br>
            Accelerator<br>
            25 Kingsland Road<br>
            London E2 8AA<br>
            United Kingdom<br>
            Website: <a href="https://mumbli.com">https://mumbli.com</a><br>
            Email: <a href="mailto:hello@mumbli.com">hello@mumbli.com</a>
        </address>
    </body>
    
    </html>
    `;

    return(
        <div>
            <RawHTMLRenderer htmlString={rawHTML} />
        </div>
    );
};

export default PrivacyPolicy;    

    
    
    