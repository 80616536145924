import './venue-mobile.scss';
import { Button, Box } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';
import { PlaceService } from '../../../../services/place.service';
import { PlaceInterface } from '../../../../models/place.model';
import { VenueContext } from '../../../../context/venue.context';
import { LatLngTuple } from 'leaflet';
import certifiedIcon from '../../../../assets/img/certified-icon.svg';

const placeService = new PlaceService();

function GoogleLink(venue: PlaceInterface) {

    if ( venue.location?.length === 2 && venue.name.length > 0 ) {
        
        let baseLink: string = '';
        if ( venue.googlePlaceId ) {
            baseLink = `https://maps.google.com/maps?ll=${venue.location}&z=16&t=m&hl=en-US&gl=US&mapclient=apiv3&cid=${venue.googlePlaceId}`;
            // `https://google.com/maps/search/?api=1&query=${venue.location}&query_place_id=${venue.googlePlaceId}`;
            
        } else {
            // baseLink = `https://google.com/maps/search/?api=1&query=${venue.name}/${venue.location}`;
            // baseLink = `https://google.com/maps/@?api=1&map-action=map&query=loc:${venue.location} (${venue.name})`;
            baseLink = `https://maps.google.com/maps/search/${venue.name}/${venue.location}`;
            // baseLink = `https://google.com/maps/place/${venue.name}/${venue.location}`;
        }

        // baseLink = `https://google.com/maps/search?api=1&query=${venue.location},${venue.location}`;
    // baseLink = `https://google.com/maps/search/${venue.name}/${venue.location}`;
               
        // const venueName: string = venue.name.replace(/\s+/, "+");

        // baseLink = `https://google.com/maps/search/?q=${venueName}(geo:${venue.location})`;
        /* const baseLink: string = `https://google.com/maps/search/?query=${venueName},${venue.location}`;
        
        const venueCoordinates: string = [
            venue.location,
            "16z"
        ].join(",");

        const navLink: string = [
            baseLink,
            venueName,
            venueCoordinates
        ].join("/");
*/
        return (
            <a href={baseLink} target="_blank" className="venu-google-link">
                <Button className="venue-item-btn">Open in google maps</Button>
            </a>
        )
    }

    return null;
}

function VenueMobile(props: any) {

    const [venueData, setVenueData] = React.useContext(VenueContext);

    const [venueIndex, setVrnueIndex] = useState(0);

    const [venueListStatus, setVrnueListStatus] = useState(false);
    const [venuesFilter, setVrnuesFilter] = useState('calm');
    const [venueList, setVenueList] = useState<PlaceInterface[]>([]);
    const slickRef = React.useRef<Slider>(null);

    useEffect(() => {
        setVenueList(props.venueList);
    }, [props.venueList]);

    useMemo(() => {
        if ( venueList && venueData ) {

            let venueIndex: number = venueList.findIndex(val => val._id == venueData._id);

            if ( venueIndex === -1 ) {
                venueIndex = 0;
            }

            slickRef.current?.slickGoTo(venueIndex);
        }
    }, [venueData, venueList]);

    const toggleVenueListPanel = () => {
        setVrnueListStatus(false);
    }

    const sliderSettings = {
        dots: false,
        centerMode: true,
        centerPadding: '12px',
        infinite: true,
        speed: 500,
        slideToShow: 1,
        slideToScroll: 1,
        afterChange: (newVenueIndex: any) => {
            setVenueData(venueList[newVenueIndex]);
        }
    }

    const VenueStatus = (venue: PlaceInterface) => {
        if ( venue.venueStatus === 'verified' ) {
            return (
                <div className="venue-status">
                    <span className='icon-verified'></span>
                    {venue.venueStatus}
                </div>
            );
        }

        if ( venue.venueStatus === 'certified' ) {
            return (
                <div className="venue-status">
                    <img className="icon" src={certifiedIcon} />
                    {venue.venueStatus}
                </div>
            );
        }

        return null;
    }

    const VenueDataSource = (venue: PlaceInterface) => {
        if ( !venue.venueStatus ) {
            return (
            <div>
                <span>Informed by user experience</span>
            </div>
            );
        }

        if ( venue.venueStatus === 'verified' ) {
            return (
            <div>
                <span>Informed by data</span>
            </div>
            );
        }

        if ( venue.venueStatus === 'certified' ) {
            return (
            <div>
                <span>Informed by continuous data analysis</span>
            </div>
            );
        }

        return (<div></div>);
    }

    const VenueCrowdedness = (venue: PlaceInterface) => {

        if ( venue.crowdedness === 'not_crowded' ) {
            return  (
                <span>
                    Not Crowded
                </span>
            );
        }

        if ( venue.crowdedness === 'not_too_crowded' ) {
            return  (
                <span>
                    Not Too Crowded
                </span>
            );
        }

        if ( venue.crowdedness === 'crowded' ) {
            return  (
                <span>
                    Crowded
                </span>
            );
        }

        if ( venue.crowdedness === 'very_crowded' ) {
            return  (
                <span>
                    Very Crowded
                </span>
            );
        }

        return null;
    }

    const VenuePhoto = (venue: PlaceInterface) => {

        if ( venue.photo?.photo ) {
            return  (
                <img className="venue-photo" src={venue.photo?.photo} />
            );
        }

        if ( venue.photo?.photoLink ) {
            return  (
                <img className="venue-photo" src={venue.photo?.photoLink} />
            );
        }

        return null;
    }


    const VenueListItems = venueList.map((venue: PlaceInterface, key: number) => {
        return (
            <div className="venue-item" key={key}>
                
                <Box className="image-box">
                    <VenueStatus {...venue} />
                    <VenuePhoto {...venue} />
                </Box>
                <Box className="venue-description">
                    <VenueDataSource {...venue} />
                    <div className="venue-name">
                        <span>
                            {venue.name}
                        </span>
                        <span>
                            {venue.venueType}
                        </span>
                    </div>
                    <div className="venue-parameters">
                        <span>
                            {venue.ambience}
                        </span>
                        <VenueCrowdedness {...venue} />
                    </div>
                    <GoogleLink {...venue} />
                </Box>
            </div>
        )
    });

    return (
        <Box className="venue-mobile">
            <Box className="slider-wrapper">
                <Slider {...sliderSettings} ref={slickRef}>
                    {VenueListItems}
                </Slider>
            </Box>

            
        </Box>
    )
}

export default VenueMobile;