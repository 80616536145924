import React from "react";
import HubspotContactForm from '../../utils/HubspotContactForm';

const ContactUs: React.FC = () => {

  return(
    <main>
      <div className="top-header-spacing">
          <div className="col" style={{marginInline:'2.5rem',marginTop:'4rem'}}>
            <HubspotContactForm 
              region="eu1"
              portalId="25823287"
              formId='539c0b27-9fad-47eb-8845-92ee71c79430'
            />
          </div>
      </div>  
     </main>
  );
};

export default ContactUs;
