import './layout.scss';

import { Switch, Route, useLocation } from 'react-router-dom';
import { Box, Drawer, Hidden } from '@material-ui/core';
import ProfilePage from '../../pages/profile/profile';
import VenuesPage from '../../pages/venues/venues';
import Admin from '../../pages/admin/venue-management';
import TechnologyPage from '../../pages/technology/technology';
import AuthPage from '../../pages/auth/auth';
import LayoutNav from '../layout-nav/layout-nav';
import PrivateRoute from '../privat-route/privat-route';
import Logout from '../logout/logout';
import SnackbarMessage from '../snackbar-message/snackbar-message';
import WellnessPage from '../../pages/wellness/wellness';
import WelcomePage from '../../pages/welcome/welcome';
import ProfileAtmospheres from '../../pages/profile/components/profile-atmospheres/profile-atmospheres';
import ProfileInfographic from '../../pages/profile/components/profile-infographic/profile-infographic';
import ProfileSettings from '../../pages/profile/components/profile-settings/profile-settings';
import ProfileTribe from '../../pages/profile/components/profile-tribe/profile-tribe';
import TechQuiz from '../../pages/technology/components/tech-quiz/tech-quiz';
import TechPreferences from '../../pages/technology/components/tech-preferences/tech-preferences';
import MyTechPreferences from '../../pages/technology/components/my-tech-preferences/my-tech-preferences';
import VenuesForMe from '../../pages/venues/components/venues-for-me/venues-for-me';
import TechnologyForMe from '../../pages/technology/components/technology-for-me/technology-for-me';
import WellnessForMe from '../../pages/wellness/components/wellness-for-me/wellness-for-me';
import WellnessDashboard from '../../pages/wellness/components/wellness-dashboard/wellness-dashboard';
import WellnessQuizConsciousness from '../../pages/wellness/components/wellness-quiz-consciousness/wellness-quiz-consciousness';
import WellnessQuizImportance from '../../pages/wellness/components/wellness-quiz-importance/wellness-quiz-importance';
import CookiePolicy from '../../pages/cookie-policy/cookie-policy';
import PrivacyPolicy from '../../pages/privacy-policy/privacy-policy';
import ContactUs from '../../pages/contact-us/contact-us';

function LayoutContent() {

    return (
        <Box className="content">

            <Switch>
                <PrivateRoute path="/profile/settings" component={ProfileSettings} />
                <PrivateRoute path="/profile/tribe" component={ProfileTribe} />
                <PrivateRoute path="/profile/infographic" component={ProfileInfographic} />
                <PrivateRoute path="/profile/atmospheres" component={ProfileAtmospheres} />
                <PrivateRoute path="/profile/preferences" component={ProfileInfographic} />
                <PrivateRoute path="/profile/tech-quiz" component={TechQuiz} />
                <PrivateRoute path="/profile/tech-preferences" component={TechPreferences} />
                <PrivateRoute path="/profile/my-tech-preferences" component={MyTechPreferences} />
                <PrivateRoute path="/profile/wellness-dashboard" component={WellnessDashboard} />
                <PrivateRoute path="/profile/quiz-sound-consciousness" component={WellnessQuizConsciousness} />
                <PrivateRoute path="/profile/quiz-sound-importance" component={WellnessQuizImportance} />
                <PrivateRoute path="/profile/:userId?" component={ProfilePage} />
                <PrivateRoute path="/cookie-policy" component={CookiePolicy} />
                <PrivateRoute path="/privacy-policy" component={PrivacyPolicy} />
                <PrivateRoute path="/contact-us" component={ContactUs} />
                <PrivateRoute path="/venues" component={VenuesPage} />
                <PrivateRoute path="/venues-management" component={Admin} />
                <PrivateRoute path="/wellness" component={WellnessPage} />
                <PrivateRoute path="/technology" component={TechnologyPage} />
                <PrivateRoute path="/venues-for-me" component={VenuesForMe} />
                <PrivateRoute path="/wellness-for-me" component={WellnessForMe} />
                <PrivateRoute path="/products-for-me" component={TechnologyForMe} />
                <Route path="/auth" component={AuthPage} />
                <Route path="/logout" component={Logout} />
                <Route path="/" component={WelcomePage} />
            </Switch>
        </Box>
    );
}

function XsLayout() {

    let location = useLocation();

    if (location.pathname.includes('/auth/') || location.pathname === '/') {
        return (
            <div className="xs-drawer-wrapper no-drawer">
                <LayoutContent />
            </div>
        );
    }

    return (
        <div className="xs-drawer-wrapper">
            <Drawer
                variant="permanent"
                anchor="top"
                className="xs-nav-sidebar"
            >
                <LayoutNav />
            </Drawer>

            <LayoutContent />
        </div>
    );
}

function LgLayout() {
    let location = useLocation();

    if (location.pathname.includes('/auth/') || location.pathname === '/') {
        return (
            <div className="drawer-wrapper no-drawer">
                <LayoutContent />
            </div>
        );
    }

    return (
        <div className="drawer-wrapper">
            <Drawer
                variant="permanent"
                anchor="left"
                className="nav-sidebar"
            >
                <LayoutNav />
            </Drawer>

            <LayoutContent />
        </div>
    );
}

function Layout() {

    return (
        <Box className="layout-wrapper">
            {/* <Hidden mdDown>
                <LgLayout />
            </Hidden>

            <Hidden lgUp>
                <XsLayout />
            </Hidden> */}

            <XsLayout />

            <SnackbarMessage />
        </Box>
    );
}

export default Layout;
