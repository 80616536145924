import axios from "axios";
import * as CryptoJS from 'crypto-js';
import Logout from '../components/logout/logout';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(
    async config => {
        let auth_token = localStorage.getItem("newAuth");
        const secret: any = process.env.REACT_APP_SECRET_KEY;
        if (auth_token) {
            var auth: any = JSON.parse(CryptoJS.AES.decrypt(auth_token, secret).toString(CryptoJS.enc.Utf8));
        }
        if (!config) {
            config = {};
        }
        if (!config.headers) {
            config.headers = {};
        }
        if (auth) {
            config.headers["Authorization"] = auth;
            config.headers["Access-Control-Allow-Origin"] = "*";
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(function (response) {
    return response;
}, async error => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
        // originalRequest._retry = true;
        localStorage.removeItem("newAuth");
        Logout();
    };
    
    return Promise.reject(error.response);
});

export default api;