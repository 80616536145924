import React, { useState } from "react";
import { PlaceInterface } from "../models/place.model";

const initVenue: PlaceInterface | null = null;

export const VenueContext = React.createContext<any>(initVenue);

const VenueStore = ({children}: any) => {
    const [venueData, setVenueData] = useState(initVenue);
    
    return (
        <VenueContext.Provider value={[venueData, setVenueData]}>
            {children}
        </VenueContext.Provider>
    );
};

export default VenueStore;
