import { Box, Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import './welcome.scss';
import { me } from '../../axios/api';
import { SnackbarContext } from '../../context/snackbar.context';
const useStyles = makeStyles({
    root: {
        minHeight: '100vh',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

});
function WelcomePage(props: any) {
    const classes = useStyles();
    const [, setSnackbarData] = React.useContext(SnackbarContext);

    const updateProfileAsk = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'warning',
            snackbarText: 'Please Update your profile'
        };

        setSnackbarData(snackbar);
    }
    const verifyEmail = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'warning',
            snackbarText: 'Verify your email first'
        };

        setSnackbarData(snackbar);
    }
    const failAuth = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'error',
            snackbarText: 'Session expired'
        };

        setSnackbarData(snackbar);
    }
    //CHECKING IF USER IS LOGGED IN OR NOT
    //when user is logged in they are redirected through welcome page to profile directly

    if (localStorage.getItem("newAuth")) {
        me({
            onSuccess: (res) => {
                if (res.data.isProfileComplete !== true) {
                    updateProfileAsk();
                    props.history.push('/auth/update-profile')
                }
                else if (res.data.isEmailVerified !== true) {
                    verifyEmail();
                    props.history.push('/auth/otp-verify')
                }
                else {
                    props.history.push('/profile')
                }
            },
            onError: (res) => {
                failAuth();
            }
        })
    }
    return (
        <Container maxWidth="xs" className={classes.root}>
            <Box mb={2} fontSize={100}>
                <span className="icon-mumbli-logo-2 d-block"></span>
            </Box>

            <Box mb={4}>
                <Typography color="primary">Find venues where you can actually hear and be heard without stress</Typography>
            </Box>

            <Box mb={2} display="block">
                <Link to="/auth/signup">
                    <Button className="btn-root btn-gradient text-white rounded-pill w-100" size="large" type="button">Sign up</Button>
                </Link>
            </Box>
            <Box display="block">
                <Link to="/auth/login">
                    <Button className="btn-root btn-gradient text-white rounded-pill w-100" size="large" type="button">Login</Button>
                </Link>
            </Box>
        </Container>
    );
}

export default WelcomePage;