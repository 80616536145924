import { Box, Button, Container, Drawer, IconButton, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { SnackbarContext } from '../../../../context/snackbar.context';
import { UserContext } from '../../../../context/user.context';
import TabQuizProfile from '../tab-quiz-profile/tab-quiz-profile';
import { AiOutlineUpload } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { FiSettings } from "react-icons/fi";
import { FaRegUserCircle } from "react-icons/fa";
import './profile-tab-wrapper.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RWebShare } from "react-web-share";
import { browserName } from "react-device-detect";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function ProfilePicture(props: any) {

    const [userData, setUserData] = React.useContext(UserContext);

    return (
        <Box display="flex" justifyContent="center" mb={4}>
            <FaRegUserCircle size="64" color="primary" className="d-block" />
        </Box>
    );
}
declare const window: any;

function ProfileTabWrapper() {

    let chrome: any;
    useEffect(() => {
        chrome = window.chrome;
        checkShareCompatibility();
    }, [])

    const [shareCheck, setShareCheck] = useState(false);

    const [, setSnackbarData] = React.useContext(SnackbarContext);

    const [filterDrawer, setFilterDrawer] = useState<boolean>(false);

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setFilterDrawer(open);
    };

    const [userData,] = React.useContext(UserContext);
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const userId = localStorage.getItem("userId");

    const copyLink = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'App link copied to clipboard'
        };

        setSnackbarData(snackbar);
    }
    const shareProfile = () => {
        const snackbar = {
            isOpen: true,
            snackbarType: 'success',
            snackbarText: 'App link shared'
        };

        setSnackbarData(snackbar);
    }

    const navigatorShare = async () => {
        if (navigator.share) {
            await navigator.share({
                title: "Sharing app",
                text: "Mumbli app link:",
                url: `${process.env.REACT_APP_SHARE_URL}`,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error in sharing', error));
        } else {
            console.log(`system does not support sharing files.`);
        }
    }


    const checkShareCompatibility = () => {
        const { platform } = window.navigator;
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];

        if (macosPlatforms.indexOf(platform) !== -1) {
            const os = 'Mac OS';
            if (browserName === 'Chrome') {
                setShareCheck(true);
            }
        }
    };

    return (
        <Box className="profile-tab-wrapper">
            <Container maxWidth="xs">

                <Drawer className="share-drawer" variant="temporary" anchor="bottom" open={filterDrawer} onClose={toggleDrawer(false)}>
                    <Box position="absolute" right={8} top={8}>
                        <IconButton color="primary" onClick={toggleDrawer(false)}>
                            <MdClose size="24" />
                        </IconButton>
                    </Box>

                    <Container maxWidth="xs">
                        <Box my={3} textAlign="center">
                            <Box className="drawer-header" mb={2}>
                                <Typography variant="h5" component="h2" className="font-weight-700">Share App</Typography>
                            </Box>

                            <Box mb={2} className="share-block">
                                <CopyToClipboard text={`${process.env.REACT_APP_SHARE_URL}`} onCopy={copyLink}>
                                    <Button variant="outlined" color="primary" className="btn-root rounded-pill w-100" size="large" type="button">Copy Link</Button>
                                </CopyToClipboard>
                            </Box>
                            <Box className="share-block">
                                {!shareCheck ? <Button className="btn-root btn-gradient rounded-pill text-white w-100" size="large" type="button" onClick={navigatorShare}>Share</Button> :
                                    <RWebShare
                                        data={{
                                            text: "Mumbli app link:",
                                            url: `${process.env.REACT_APP_SHARE_URL}`,
                                            title: "Sharing app",
                                        }}
                                        onClick={shareProfile}
                                    >
                                        <Button className="btn-root btn-gradient rounded-pill text-white w-100" size="large" type="button">Share</Button>
                                    </RWebShare>
                                }
                            </Box>
                        </Box>
                    </Container>
                </Drawer>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={toggleDrawer(true)}>
                        <AiOutlineUpload size="24" />
                    </IconButton>
                    {/* <Typography variant="h1" color="primary" className="font-weight-700 text-center">{firstName} {lastName}</Typography> */}
                    <NavLink to="/profile/settings">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <FiSettings fontSize="24" />
                        </IconButton>
                    </NavLink>
                </Box>


                <ProfilePicture />

                <TabQuizProfile />
            </Container>

        </Box>
    )
}

export default ProfileTabWrapper;