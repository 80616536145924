import './venue-list.scss';
import { Box, Button, List, ListItem } from '@material-ui/core';
import React, { useState } from 'react';


function VenueImage(props: any) {

    const photo = props.image;

    if ( photo.photo ) {
        return (
            <Box className="image-box">
                <img className="venue-photo" src={photo.photo} />
                <Button className="btn-light">
                    Save
                    <span className="icon-bookmark"></span>
                </Button>
            </Box>
        );
    }

    return (
        <Box className="image-box" />
    );
}


function VenueList(props: any) {

    const venueList = [...props.venueList];

    for ( let i = 1; i < 3; i++ ) {
        
        venueList.push({
            _id: i,
            address: "",
            createdAt: "",
            description: "",
            freeWifi: false,
            location: [],
            materials: { floor: "", ceiling: "", walls: "" },
            name: "Coming soon",
            numberOfPlugs: 0,
            photo: {
                height: 0,
                width: 0,
                photo: ""
            },
            recordings: [],
            state: "active",
            updatedAt: ""
        });  
    }

    const listItems = venueList.map((venue: any) => {
        if ( venue.state !== 'draft' ) {
            return (
                <ListItem className="venue-list-item">
                    <VenueImage image={venue.photo} />
                    <Box className="venue-description">
                        <div>
                            <span>Informed by user experience</span>
                        </div>
                        <h3>{venue.name} | {venue.venueType}</h3>
                        <div>
                            <span>{venue.ambience}</span>
                            <span>{venue.crowdedness}</span>
                        </div>
                        <Button className={"btn-gradient" + (venue.name === "Coming soon" ? " btn-greyed" : "")}>
                            <span className="icon-direction"></span>
                            Directions
                        </Button>
                    </Box>
                </ListItem>
            );
        }

        return <Box />;
    });

    return (
        <List className="venue-list">
            {listItems}
        </List>
    );
}

export default VenueList;