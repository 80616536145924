import { 
    Box, 
    Button,
    Hidden,
    List,
    ListItem
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import {
    CardElement,
    useStripe,
    useElements,
    Elements
} from '@stripe/react-stripe-js';

import './profile-tribe.scss';
import { SnackbarContext } from '../../../../context/snackbar.context';

function PaymentBlock(props: any) {
    const [, setSnackbarData] = React.useContext(SnackbarContext);

    const stripe = useStripe();
    const elements = useElements();

    const subscribeToTribe = async (e: any) => {
        e.preventDefault();
       // console.log(subscriptionType);
        // setPaymentCardForm(!paymentCardForm);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
    
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        if ( cardElement ) {
            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                console.log('[error]', error);

                const snackbar = {
                    isOpen: true,
                    snackbarType: 'error',
                    snackbarText: error.message
                };

                setSnackbarData(snackbar);
            } else {
                console.log('[PaymentMethod]', paymentMethod);
                
                const snackbar = {
                    isOpen: true,
                    snackbarType: 'success',
                    snackbarText: 'You successfully subscribed.'
                };

                setSnackbarData(snackbar);
            }
        }

    }

    if ( props.paymentCardForm ) {
        return (
            <>
                <Box className="payment-block">
                    
                    <CardElement />

                    <Box className="action-block">
                        <Button variant="outlined" type="button" onClick={() => { props.setPaymentCardForm(false); }}>Cancel</Button>
                        <Button className="btn-gradient" type="button" onClick={(e) => { subscribeToTribe(e); }}>Subscribe</Button>
                    </Box>
                </Box>
            </>
        );
    }

    return null;
}

function SupportButtons(props: any) {

    if ( !props.paymentCardForm ) {
        return (
            <>
                <Button className="btn-gradient" type="button" onClick={() => { props.selectSubscription('basic'); }}>
                    Basic Support
                    <span>&#163; 1.99</span>
                </Button>

                <Button variant="outlined" type="button" onClick={() => { props.selectSubscription('super'); }}>
                    Super Support
                    <span>&#163; 4.99</span>
                </Button>
            </>
        );
    }

    return null;
}

function ProfileTribe() {

    const stripe = useStripe();
    const elements = useElements();
    console.log(stripe);

    const [paymentCardForm, setPaymentCardForm] = React.useState<boolean>(false);
    const [subscriptionType, setSubscriptionType] = React.useState<string | null>(null);

    const selectSubscription = (p: any) => {
        setPaymentCardForm(true);
        setSubscriptionType(p);
    }

    useMemo(() => {
        if ( paymentCardForm === false ) {
            setSubscriptionType(null);
        }
    }, [paymentCardForm])
    
    return(
        <Box className="profile-tribe">
            
            <NavLink to="/profile/settings" className="icon-close"></NavLink>

            <h1>Subscribe to <span className="icon-mumbli-logo"></span> Tribe</h1>

            <List className="tribe-list">
                <ListItem className="tribe-list-item">
                    <span className="icon-check"></span>Unlock  the full Mumbli experience
                </ListItem>
                <ListItem className="tribe-list-item">
                    <span className="icon-check"></span>Fully tailored recommendations
                </ListItem>
                <ListItem className="tribe-list-item">
                    <span className="icon-check"></span>First access to new venues, products & services
                </ListItem>
                <ListItem className="tribe-list-item">
                    <span className="icon-check"></span>Exclusive content
                </ListItem>
            </List>

            <SupportButtons paymentCardForm={paymentCardForm} setPaymentCardForm={setPaymentCardForm} selectSubscription={selectSubscription} />

            <PaymentBlock paymentCardForm={paymentCardForm} setPaymentCardForm={setPaymentCardForm} />

            <p>Thanks for your support, cancel anytime</p>

        </Box>
    )
}

export default ProfileTribe;
